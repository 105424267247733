export const handleCheckValue = (value: any) => {
  return typeof value !== "undefined" && value !== null;
};

export const numberWithCommas = (x: number) => {
  if (!x) return "0";
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const screens: any = {
  dashboard: "/dashboard",
  "communication_from_students/heathcheck": "/body-temperature-management",
  message_delivery: "/message-delivery",
  questionaire: "/questions-list",
  heathcheck: "/mental-health-management",
};

export const getDefaultScreen = (screen: any) => {
  if (screen !== undefined) {
    return screens[screen.screen_code];
  }

  return "/message-delivery";
};

export const returnGender = (gender: string) => {
  if (gender === "女性") {
    return "女";
  }
  if (gender === "男性") {
    return "男";
  }
  return gender || "回答なし";
};
