import { IOption } from "components/select/Select";

export const PointCodeOptions: IOption[] = [
  {
    id: 1,
    name: "医科：1",
    value: 1,
  },
  {
    id: 2,
    name: "歯科：2",
    value: 2,
  },
  {
    id: 3,
    name: "調剤：3",
    value: 3,
  },
];

export const PrefectureCodeOptions: IOption[] = [
  { id: 1, value: 1, name: "北海道（1）" },
  { id: 2, value: 2, name: "青森県（2）" },
  { id: 3, value: 3, name: "岩手県（3）" },
  { id: 4, value: 4, name: "宮城県（4）" },
  { id: 5, value: 5, name: "秋田県（5）" },
  { id: 6, value: 6, name: "山形県（6）" },
  { id: 7, value: 7, name: "福島県（7）" },
  { id: 8, value: 8, name: "茨城県（8）" },
  { id: 9, value: 9, name: "栃木県（9）" },
  { id: 10, value: 10, name: "群馬県（10）" },
  { id: 11, value: 11, name: "埼玉県（11）" },
  { id: 12, value: 12, name: "千葉県（12）" },
  { id: 13, value: 13, name: "東京都（13）" },
  { id: 14, value: 14, name: "神奈川県（14）" },
  { id: 15, value: 15, name: "新潟県（15）" },
  { id: 16, value: 16, name: "富山県（16）" },
  { id: 17, value: 17, name: "石川県（17）" },
  { id: 18, value: 18, name: "福井県（18）" },
  { id: 19, value: 19, name: "山梨県（19）" },
  { id: 20, value: 20, name: "長野県（20）" },
  { id: 21, value: 21, name: "岐阜県（21）" },
  { id: 22, value: 22, name: "静岡県（22）" },
  { id: 23, value: 23, name: "愛知県（23）" },
  { id: 24, value: 24, name: "三重県（24）" },
  { id: 25, value: 25, name: "滋賀県（25）" },
  { id: 26, value: 26, name: "京都府（26）" },
  { id: 27, value: 27, name: "大阪府（27）" },
  { id: 28, value: 28, name: "兵庫県（28）" },
  { id: 29, value: 29, name: "奈良県（29）" },
  { id: 30, value: 30, name: "和歌山県（30）" },
  { id: 31, value: 31, name: "鳥取県（31）" },
  { id: 32, value: 32, name: "島根県（32）" },
  { id: 33, value: 33, name: "岡山県（33）" },
  { id: 34, value: 34, name: "広島県（34）" },
  { id: 35, value: 35, name: "山口県（35）" },
  { id: 36, value: 36, name: "徳島県（36）" },
  { id: 37, value: 37, name: "香川県（37）" },
  { id: 38, value: 38, name: "愛媛県（38）" },
  { id: 39, value: 39, name: "高知県（39）" },
  { id: 40, value: 40, name: "福岡県（40）" },
  { id: 41, value: 41, name: "佐賀県（41）" },
  { id: 42, value: 42, name: "長崎県（42）" },
  { id: 43, value: 43, name: "熊本県（43）" },
  { id: 44, value: 44, name: "大分県（44）" },
  { id: 45, value: 45, name: "宮崎県（45）" },
  { id: 46, value: 46, name: "鹿児島県（46）" },
  { id: 47, value: 47, name: "沖縄県（47）" },
];
