import React from "react";
import { Select, SelectProps } from "antd";
import styled from "styled-components";

import { WrapperErrorMessage, ErrorMessage } from "theme/CommonStyle";
import SelectIcon from "icons/SelectIcon";
import { ClockIcon } from "icons";
import { Tooltip } from "components";

const { Option } = Select;

export interface IOption {
  id: number;
  value: string | number;
  name: string | any;
  label?: string;
  key?: number;
  disabled?: boolean;
}

interface ISelect extends SelectProps<any> {
  width?: string;
  height?: string;
  background?: string;
  options?: IOption[];
  label?: React.ReactNode;
  labelSuffix?: React.ReactNode;
  fs?: number; // font-size
  fsLabel?: number;
  fwLabel?: number;
  lhLabel?: string;
  marginForm?: string;
  error?: string;
  disabled?: boolean;
  getPopupContainer?: any;
  top?: string;
  right?: string;
  icon?: React.ReactNode;
  bdr?: string;
  mgBottomLabel?: number;
  bordered?: boolean;
  disabledBackground?: string;
  disabledTextColor?: string;
  boxShadow?: string;
  borderColor?: string;
  showTooltip?: boolean;
}

const SelectStyled = styled(Select)<ISelect>(
  ({
    theme,
    height,
    width,
    background,
    fs,
    mode,
    disabled,
    top,
    right,
    bdr,
    disabledBackground,
    disabledTextColor,
    boxShadow,
    borderColor,
  }) => ({
    width: width ? `${width}!important` : "100%",
    ".ant-select-selector": {
      alignItems: "center",
      width: width ? `${width}!important` : "",
      height: height ? `${height}!important` : "auto!important",
      boxShadow: background
        ? ""
        : `${boxShadow || "inset 0px 2px 4px rgba(0, 0, 0, 0.08)"}`,
      borderRadius: `${bdr || "6px"}!important`,
      border: background
        ? `1px solid ${theme.colors.border}!important`
        : `1px solid ${
            borderColor || theme.colors.textFieldBackground
          }!important`,
      padding: mode ? "5px 30px 5px 12px" : "0 12px!important",
      backgroundColor: background ? `${background}!important` : "",
      background: disabled
        ? `${disabledBackground || theme.colors.background}!important`
        : "",
    },
    ".ant-select-arrow": {
      top: top || "39%",
      right: right || "8px",
      opacity: disabled ? 0.24 : 1,
    },
    ".ant-select-selection-overflow .ant-select-selection-item": {
      border: "none",
      borderRadius: 4,
      background: theme.colors.background,
      fontSize: fs || theme.sizes.xs,
      fontWeight: theme.fontWeight.medium,
      lineHeight: "16px",
      padding: "3px 4px 4px",
      marginRight: 8,
    },
    ".ant-select-selector .ant-select-selection-item": {
      color: disabled
        ? `${disabledTextColor || theme.colors.border}!important`
        : "",
    },
  })
);

const FormControl = styled.div<ISelect>(({ marginForm }) => ({
  position: "relative",
  margin: marginForm,
}));

const Label = styled.div<ISelect>(
  ({
    theme,
    fsLabel,
    fwLabel,
    lhLabel,
    disabled,
    mgBottomLabel,
    disabledTextColor,
  }) => ({
    color: disabled
      ? `${disabledTextColor || theme.colors.border}`
      : theme.colors.text.primary,
    fontSize: fsLabel || theme.sizes.xs,
    fontWeight: fwLabel || theme.fontWeight.regular,
    lineHeight: lhLabel || "12px",
    marginBottom: mgBottomLabel ? mgBottomLabel : 8,
    "& span": {
      color: theme.colors.important,
    },
  })
);

const SelectCustom: React.FC<ISelect> = ({
  options,
  value,
  label,
  error,
  marginForm,
  fsLabel,
  fwLabel,
  lhLabel,
  disabled,
  mgBottomLabel,
  disabledTextColor,
  bordered = false,
  showTooltip = false,
  icon = <SelectIcon />,
  labelSuffix,
  ...rest
}) => {
  const handleMouseEnter = (e: any) => {
    e.target.removeAttribute("title");
  };
  return (
    <FormControl marginForm={marginForm}>
      {label && (
        <Label
          fsLabel={fsLabel}
          fwLabel={fwLabel}
          lhLabel={lhLabel}
          mgBottomLabel={mgBottomLabel}
          disabledTextColor={disabledTextColor}
          disabled={disabled}
        >
          {label}
          {labelSuffix && <span>{labelSuffix}</span>}
        </Label>
      )}
      {showTooltip ? (
        <Tooltip title={value}>
          <SelectStyled
            bordered={bordered}
            value={value}
            suffixIcon={icon}
            disabled={disabled}
            disabledTextColor={disabledTextColor}
            onMouseEnter={handleMouseEnter}
            {...rest}
          >
            {(options || []).map((option: IOption) => (
              <Option
                value={option.value}
                key={option.id}
                label={option.label}
                disabled={option.disabled}
              >
                {option.name}
              </Option>
            ))}
          </SelectStyled>
        </Tooltip>
      ) : (
        <SelectStyled
          bordered={bordered}
          value={value}
          suffixIcon={icon}
          disabled={disabled}
          disabledTextColor={disabledTextColor}
          onMouseEnter={handleMouseEnter}
          {...rest}
        >
          {(options || []).map((option: IOption) => (
            <Option
              value={option.value}
              key={option.id}
              label={option.label}
              disabled={option.disabled}
            >
              {option.name}
            </Option>
          ))}
        </SelectStyled>
      )}
      {error && (
        <WrapperErrorMessage>
          <ErrorMessage>{error}</ErrorMessage>
        </WrapperErrorMessage>
      )}
    </FormControl>
  );
};

export default SelectCustom;
