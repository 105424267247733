import { combineReducers } from "redux";
import { routerReducer, RouterState } from "react-router-redux";
import {
  authenticationReducer,
  AuthenticationReducerType,
} from "../redux/authentication/authenticationReducer";
import {
  doctorsListReducer,
  DoctorsListReducerType,
} from "redux/doctor/doctorsList/doctorsListReducer";
import {
  monthSchedulesReducer,
  MonthSchedulesReducerType,
} from "redux/schedules/monthSchedules/monthSchedulesReducer";
import {
  weekSchedulesReducer,
  WeekSchedulesReducerType,
} from "redux/schedules/weekSchedules/weekSchedulesReducer";
import {
  appointmentsListReducer,
  AppointmentsListReducerType,
} from "redux/appointments/appointmentsList/appointmentsListReducer";
import {
  doctorAppointmentsReducer,
  DoctorAppointmentsReducerType,
} from "redux/appointments/doctorAppointments/doctorAppointmentsReducer";
import {
  appointmentDetailsReducer,
  AppointmentDetailsReducerType,
} from "redux/appointments/appointmentDetails/appointmentDetailsReducer";
import {
  deleteDoctorReducer,
  DeleteDoctorReducerType,
} from "redux/doctor/deleteDoctor/deleteDoctorReducer";
import {
  createDoctorReducer,
  CreateDoctorReducerType,
} from "redux/doctor/createDoctor/createDoctorReducer";
import {
  postDoctorSchedulesReducer,
  PostDoctorSchedulesReducerType,
} from "redux/schedules/postDoctorSchedules/postDoctorSchedulesReducer";
import {
  deleteScheduleReducer,
  DeleteScheduleReducerType,
} from "redux/schedules/deleteSchedule/deleteScheduleReducer";
import {
  createBulkSchedulesReducer,
  CreateBulkSchedulesReducerType,
} from "redux/schedules/createBulkSchedules/createBulkSchedulesReducer";
import {
  monthAppointmentsListReducer,
  MonthAppointmentsListReducerType,
} from "redux/appointments/monthAppointments/monthAppointmentsReducer";
import {
  EditDoctorReducerType,
  editDoctorReducer,
} from "redux/doctor/editDoctor/editDoctorReducer";
import {
  searchMedicineReducer,
  SearchMedicineReducerType,
} from "redux/medicine/search/searchReducer";
import {
  createMedicineReducer,
  CreateMedicineReducerType,
} from "redux/medicine/create/createReducer";
import {
  getMedicineListReducer,
  GetMedicineListReducerType,
} from "redux/medicine/list/listReducer";
import {
  getMedicineListByIDReducer,
  GetMedicineListByIDReducerType,
} from "redux/medicine/listByID/listByIDReducer";
import {
  updateMedicineByIDReducer,
  UpdateMedicineListByIDReducerType,
} from "redux/medicine/updateByID/updateByIDReducer";
import {
  deleteMedicineByIDReducer,
  DeleteMedicineByIDReducerType,
} from "redux/medicine/deleteByID/deleteByIDReducer";
import {
  subSearchMedicineReducer,
  SubSearchMedicineReducerType,
} from "redux/medicine/subSearch/subSearchReducer";
import {
  CreateMedicineListByIDReducerType,
  createMedicineByIDReducer,
} from "redux/medicine/createByID/createByIDReducer";
import {
  medicalRecordReducer,
  MedicalRecordReducerType,
} from "redux/medicalRecords/medicalRecord/medicalRecordReducer";
import {
  createMedicalRecordReducer,
  CreateMedicalRecordReducerType,
} from "redux/medicalRecords/create/createReducer";
import {
  updateMedicalRecordReducer,
  UpdateMedicalRecordReducerType,
} from "redux/medicalRecords/update/updateReducer";
import {
  clinicalDepartmentListReducer,
  ClinicalDepartmentListReducerType,
} from "redux/medicalRecords/clinicalDepartmentList/clinicalDepartmentListReducer";
import {
  VersionHistoryReducerType,
  versionHistoryReducer,
} from "redux/medicalRecords/versionHistory/versionHistoryReducer";
import {
  AppointmentUpdateStatusConsultationReducerType,
  appointmentUpdateStatusConsultationReducer,
} from "redux/appointments/updateStatusConsultation/updateStatusConsultationReducer";
import {
  AppointmentCancelStatusReducerType,
  appointmentCancelStatusReducer,
} from "redux/appointments/cancelStatus/cancelStatusReducer";
import {
  AppointmentUpdateStatusReducerType,
  appointmentUpdateStatusReducer,
} from "redux/appointments/updateStatus/updateStatusReducer";
import {
  MedicalPlanReducerType,
  medicalPlanReducer,
} from "redux/medicalPlans/medicalPlan/medicalPlanReducer";
import {
  NotifyMedicalPlanReducerType,
  notifyMedicalPlanReducer,
} from "redux/medicalPlans/notifyMedicalPlan/notifyMedicalPlanReducer";
import {
  CreateMedicalPlanReducerType,
  createMedicalPlanReducer,
} from "redux/medicalPlans/createMedicalPlan/createMedicalPlanReducer";
import {
  EditMedicalPlanReducerType,
  editMedicalPlanReducer,
} from "redux/medicalPlans/editMedicalPlan/editMedicalPlanReducer";
import {
  CancelAppointmentReducerType,
  cancelAppointmentReducer,
} from "redux/appointments/cancelAppointment/cancelAppointmentReducer";
import {
  HospitalListReducerType,
  hospitalListReducer,
} from "redux/hospitals/hospitalsList/hospitalsListReducer";
import {
  ChangeDoctorReducerType,
  changeDoctorReducer,
} from "redux/appointments/changeDoctor/changeDoctorReducer";
import {
  CreateHospitalReducerType,
  createHospitalReducer,
} from "redux/hospitals/createHospital/createHospitalReducer";
import {
  UpdateHospitalReducerType,
  updateHospitalReducer,
} from "redux/hospitals/updateHospital/updateHospitalReducer";
import {
  AgreeSuperCitiesReducerType,
  agreeSuperCitiesReducer,
} from "redux/hospitals/agreeSuperCities/agreeSuperCitiesReducer";
import {
  AgreeHospitalsReducerType,
  agreeHospitalsReducer,
} from "redux/hospitals/agreeHospitals/agreeHospitalsReducer";
import {
  DeleteHospitalReducerType,
  deleteHospitalReducer,
} from "redux/hospitals/deleteHospital/deleteHospitalReducer";

export interface RootState {
  loginForm: AuthenticationReducerType;
  doctorsList: DoctorsListReducerType;
  monthSchedules: MonthSchedulesReducerType;
  weekSchedules: WeekSchedulesReducerType;
  appointmentsList: AppointmentsListReducerType;
  doctorAppointments: DoctorAppointmentsReducerType;
  appointmentDetails: AppointmentDetailsReducerType;
  deleteDoctor: DeleteDoctorReducerType;
  createDoctor: CreateDoctorReducerType;
  postDoctorSchedules: PostDoctorSchedulesReducerType;
  deleteSchedule: DeleteScheduleReducerType;
  createBulkSchedules: CreateBulkSchedulesReducerType;
  monthAppointmentsList: MonthAppointmentsListReducerType;
  editDoctor: EditDoctorReducerType;
  searchMedicine: SearchMedicineReducerType;
  createMedicine: CreateMedicineReducerType;
  getMedicineList: GetMedicineListReducerType;
  getMedicineListByID: GetMedicineListByIDReducerType;
  updateMedicineByID: UpdateMedicineListByIDReducerType;
  deleteMedicineByID: DeleteMedicineByIDReducerType;
  subSearchMedicine: SubSearchMedicineReducerType;
  createMedicineByID: CreateMedicineListByIDReducerType;
  medicalRecord: MedicalRecordReducerType;
  createMedicalRecord: CreateMedicalRecordReducerType;
  updateMedicalRecord: UpdateMedicalRecordReducerType;
  clinicalDepartmentList: ClinicalDepartmentListReducerType;
  versionHistory: VersionHistoryReducerType;
  updateStatusConsultationAppointment: AppointmentUpdateStatusConsultationReducerType;
  cancelStatusAppointment: AppointmentCancelStatusReducerType;
  updateStatusAppointment: AppointmentUpdateStatusReducerType;
  medicalPlan: MedicalPlanReducerType;
  notifyMedicalPlan: NotifyMedicalPlanReducerType;
  createMedicalPlan: CreateMedicalPlanReducerType;
  editMedicalPlan: EditMedicalPlanReducerType;
  cancelAppointment: CancelAppointmentReducerType;
  hospitalList: HospitalListReducerType;
  changeDoctor: ChangeDoctorReducerType;
  createHospital: CreateHospitalReducerType;
  updateHospital: UpdateHospitalReducerType;
  agreeSuperCities: AgreeSuperCitiesReducerType;
  agreeHospitals: AgreeHospitalsReducerType;
  deleteHospital: DeleteHospitalReducerType;
  routerReducer: RouterState;
}

const appReducer = combineReducers({
  loginForm: authenticationReducer,
  doctorsList: doctorsListReducer,
  monthSchedules: monthSchedulesReducer,
  weekSchedules: weekSchedulesReducer,
  appointmentsList: appointmentsListReducer,
  doctorAppointments: doctorAppointmentsReducer,
  appointmentDetails: appointmentDetailsReducer,
  deleteDoctor: deleteDoctorReducer,
  createDoctor: createDoctorReducer,
  postDoctorSchedules: postDoctorSchedulesReducer,
  deleteSchedule: deleteScheduleReducer,
  createBulkSchedules: createBulkSchedulesReducer,
  monthAppointmentsList: monthAppointmentsListReducer,
  editDoctor: editDoctorReducer,
  searchMedicine: searchMedicineReducer,
  createMedicine: createMedicineReducer,
  getMedicineList: getMedicineListReducer,
  getMedicineListByID: getMedicineListByIDReducer,
  updateMedicineByID: updateMedicineByIDReducer,
  deleteMedicineByID: deleteMedicineByIDReducer,
  subSearchMedicine: subSearchMedicineReducer,
  createMedicineByID: createMedicineByIDReducer,
  medicalRecord: medicalRecordReducer,
  createMedicalRecord: createMedicalRecordReducer,
  updateMedicalRecord: updateMedicalRecordReducer,
  clinicalDepartmentList: clinicalDepartmentListReducer,
  versionHistory: versionHistoryReducer,
  updateStatusConsultationAppointment:
    appointmentUpdateStatusConsultationReducer,
  cancelStatusAppointment: appointmentCancelStatusReducer,
  updateStatusAppointment: appointmentUpdateStatusReducer,
  medicalPlan: medicalPlanReducer,
  notifyMedicalPlan: notifyMedicalPlanReducer,
  createMedicalPlan: createMedicalPlanReducer,
  editMedicalPlan: editMedicalPlanReducer,
  cancelAppointment: cancelAppointmentReducer,
  hospitalList: hospitalListReducer,
  changeDoctor: changeDoctorReducer,
  createHospital: createHospitalReducer,
  updateHospital: updateHospitalReducer,
  agreeSuperCities: agreeSuperCitiesReducer,
  agreeHospitals: agreeHospitalsReducer,
  deleteHospital: deleteHospitalReducer,
  routerReducer,
});

export default (state: any, action: any) => {
  if (action.type === "LOGOUT") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};
