import {
  ICreateHospitalPayload,
  ActionType,
  CreateHospitalResponse,
} from "models/hospitals/createHospital";

export const createHospitalAction = (payload: ICreateHospitalPayload) => {
  return {
    type: ActionType.CREATE_HOSPITAL,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.CREATE_HOSPITAL_ERROR,
    payload,
  };
};

export const handleSuccessAction = (payload: CreateHospitalResponse) => {
  return {
    type: ActionType.CREATE_HOSPITAL_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.CREATE_HOSPITAL_ERROR,
  };
};
