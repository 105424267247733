import React from "react";
import { Tooltip } from "antd";

const TooltipCustom = ({ title, children }: any) => {
  return (
    <Tooltip
      placement="bottomLeft"
      title={title}
      align={{
        offset: [0, -3],
      }}
    >
      {children}
    </Tooltip>
  );
};

export default TooltipCustom;
