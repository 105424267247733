import {
  ActionType,
  AgreeHospitalsPayload,
  AgreeHospitalsListResponse,
} from "models/hospitals/agreeHospitalsList";

export const getAgreeHospitalsAction = (payload?: AgreeHospitalsPayload) => {
  return {
    type: ActionType.AGREE_HOSPITALS_LIST,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.AGREE_HOSPITALS_LIST_ERROR,
    payload,
  };
};

export const handleSuccessAction = (payload: AgreeHospitalsListResponse) => {
  return {
    type: ActionType.AGREE_HOSPITALS_LIST_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.AGREE_HOSPITALS_LIST_RESET,
  };
};
