import { IResponse } from "models";

export enum ActionType {
  CREATE_HOSPITAL = "action/CREATE_HOSPITAL",
  CREATE_HOSPITAL_SUCCESS = "action/CREATE_HOSPITAL_SUCCESS",
  CREATE_HOSPITAL_ERROR = "action/CREATE_HOSPITAL_ERROR",
  CREATE_HOSPITALD_RESET = "action/CREATE_HOSPITALD_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface ICreateHospital {
  agree_repo_hospital_id: number;
  agree_repo_super_city_id: number;
  mynumber_url: string;
  prefecture_code: string;
  point_code: string;
  hospital_code: string;
  director_name: string;
  urgent_hospital_name: string;
  email: string;
  stripe_connect_id: string;
  password?: string;
}

export interface ICreateHospitalPayload {
  hospital: ICreateHospital;
}

export interface CreateHospitalResponse extends IResponse {
  result: any;
}
