import { IResponse } from "models";

export enum ActionType {
  AGREE_SUPER_CITY_LIST = "action/AGREE_SUPER_CITY_LIST",
  AGREE_SUPER_CITY_LIST_SUCCESS = "action/AGREE_SUPER_CITY_LIST_SUCCESS",
  AGREE_SUPER_CITY_LIST_ERROR = "action/AGREE_SUPER_CITY_LIST_ERROR",
  AGREE_SUPER_CITY_LIST_RESET = "action/AGREE_SUPER_CITY_LIST_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface AgreeSuperCitiesPayload {
  name: string;
  page: number;
  per_page: number;
}

export interface IAgreeSuperCity {
  id: number;
  name: string;
}

export interface AgreeSuperCitiesListResponse extends IResponse {
  total_pages: number;
  current_page: number;
  next_page: number;
  prev_page: number;
  result: IAgreeSuperCity[];
}
