import {
  ActionType,
  ChanceDoctorPayload,
  ChanceDoctorResponse,
} from "models/appointments/changeDoctor";

export const changeDoctorAction = (payload: ChanceDoctorPayload) => {
  return {
    type: ActionType.CHANGE_DOCTOR,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.CHANGE_DOCTOR_ERROR,
    payload,
  };
};

export const handleSuccessAction = (payload: ChanceDoctorResponse) => {
  return {
    type: ActionType.CHANGE_DOCTOR_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.CHANGE_DOCTOR_RESET,
  };
};
