import styled from "styled-components";
import { theme } from "theme/theme";
import { Modal, Table } from "components";

export const Title = styled.div`
  font-family: Noto Sans JP;
  font-size: ${theme.sizes.md};
  font-weight: ${theme.fontWeight.bold};
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 16px;
  color: #1a2f62;
`;

export const GroupInput = styled.div`
  display: flex;
  gap: 6px;

  button {
    height: 31px;
    margin-top: 20px;
  }
`;

export const WrapperFirstName = styled.div`
  display: flex;
  gap: 10px;

  button {
    height: 28px;
    font-family: Noto Sans JP;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: center;
  }
`;

export const WrapperBtnFooter = styled.div`
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-top: 24px;

  button {
    width: 128px;
    line-height: 23px;
    padding: 8.5px 15px;
  }
`;

export const WrapperContentModal = styled.div`
  font-family: Noto Sans JP;
  font-size: 22px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
`;

export const ModalStyled = styled(Modal)`
  .ant-modal-body {
    padding: 32px;
  }
`;

export const WrapperPhysicianManagement = styled.div`
  position: relative;
`;

export const WrapperCreatePhysician = styled.div`
  width: 100%;
  display: flex;
  gap: 24px;
  .left-block,
  .right-block {
    width: 50%;
  }

  .left-block {
    > div:first-child {
      margin-bottom: 18px;
    }
    > div:last-child {
      > div {
        width: 50%;
      }
    }
  }

  .right-block {
    .label {
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
      margin-bottom: 4px;
    }
    .select-block {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 8px;
    }
    .area {
      width: 100%;
      border: 1px solid #cccccc;
      box-shadow: 0px 2px 4px 0px #00000017 inset;
      border-radius: 6px;
      padding: 7px 7px 10px;
      height: 61px;
      overflow: hidden;
      .overflow-wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 4px;
        overflow-y: auto;
        height: 42px;
        .badge {
          height: 19px;
          padding: 3px 4px 4px;
          border-radius: 4px;
          width: max-content;
          display: flex;
          gap: 4px;
          align-items: center;
          background-color: #f2f2f2;
          span {
            font-size: 12px;
            font-weight: 400;
            line-height: 12px;
            color: #3b3b3b;
          }
        }
        &::-webkit-scrollbar {
          width: 16px;
        }
        &::-webkit-scrollbar-track {
          margin: 6px 0px 6px;
          border-left: 4px solid ${theme.colors.white};
          border-right: 4px solid ${theme.colors.white};
          background: ${theme.colors.background};
          border-radius: 7px;
        }
        &::-webkit-scrollbar-thumb {
          background: #bdbdbd;
          border-radius: 7px;
          border-left: 4px solid ${theme.colors.white};
          border-right: 4px solid ${theme.colors.white};
        }
      }
    }
  }
`;

export const GroupInputWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  .item {
    width: 50%;
    display: flex;
    gap: 6px;
    > div {
      width: 50%;
    }
  }
`;

export const TableStyled = styled(Table)`
  .ant-table-thead > tr > th {
    padding: 26px 12px 27px;
  }
  .ant-table-thead > tr > th:first-child {
    padding-left: 58px;
  }
  .ant-table-tbody > tr > td {
    vertical-align: top;
  }
  .ant-table-tbody > tr:nth-child(even) td {
    background: linear-gradient(#f3f8fc, #f3f8fc);
    background-position: bottom;
    background-size: 100% 15px;
  }
  .ant-table-tbody > tr:nth-child(odd) td {
    background: #ffffff;
  }
  .ant-table-tbody > tr > td {
    vertical-align: top;
    padding: 9px 12px 9px;
    border-bottom: none;
  }
  .ant-table-tbody > tr > td:first-child {
    padding-left: 4px;
  }
`;

export const HospitalColItem = styled.div`
  width: 100%;
  .select-block {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .flex-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    margin-top: 12px;
    .badge {
      height: 19px;
      padding: 3px 4px 4px;
      border-radius: 4px;
      width: max-content;
      display: flex;
      gap: 4px;
      align-items: center;
      background-color: #f2f2f2;
      span {
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
        color: #3b3b3b;
      }
    }
  }
`;
