import { IResponse } from "models";

export enum ActionType {
  HOSPITALS_LIST = "action/HOSPITALS_LIST",
  HOSPITALS_LIST_SUCCESS = "action/HOSPITALS_LIST_SUCCESS",
  HOSPITALS_LIST_ERROR = "action/HOSPITALS_LIST_ERROR",
  HOSPITALS_LIST_RESET = "action/HOSPITALS_LIST_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface DynamicObject {
  [key: string]: string | number;
}

export interface IHospital extends DynamicObject {
  hospital_id: number;
  name: string;
  mynumber_url: string;
  super_city_name: string;
  prefecture_code: number;
  point_code: number;
  hospital_code: number;
  director_name: string;
  urgent_hospital_name: string;
  stripe_connect_id: string;
  email: string;
  super_city_id: number;
  agree_repo_hospital_id: number;
}

export interface IHospitalListResponse extends IResponse {
  result: IHospital[];
}
