import {
  ActionType,
  AgreeSuperCitiesPayload,
  AgreeSuperCitiesListResponse,
} from "models/hospitals/agreeSuperCitiesList";

export const getAgreeSuperCitiesAction = (
  payload?: AgreeSuperCitiesPayload
) => {
  return {
    type: ActionType.AGREE_SUPER_CITY_LIST,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.AGREE_SUPER_CITY_LIST_ERROR,
    payload,
  };
};

export const handleSuccessAction = (payload: AgreeSuperCitiesListResponse) => {
  return {
    type: ActionType.AGREE_SUPER_CITY_LIST_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.AGREE_SUPER_CITY_LIST_RESET,
  };
};
