import {
  ActionType,
  IHospitalListResponse,
} from "models/hospitals/hospitalsList";

export const getHospitalListAction = () => {
  return {
    type: ActionType.HOSPITALS_LIST,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.HOSPITALS_LIST_ERROR,
    payload,
  };
};

export const handleSuccessAction = (payload: IHospitalListResponse) => {
  return {
    type: ActionType.HOSPITALS_LIST_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.HOSPITALS_LIST_RESET,
  };
};
