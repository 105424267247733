import { IResponse } from "models";

export enum ActionType {
  CHANGE_DOCTOR = "action/CHANGE_DOCTOR",
  CHANGE_DOCTOR_SUCCESS = "action/CHANGE_DOCTOR_SUCCESS",
  CHANGE_DOCTOR_ERROR = "action/CHANGE_DOCTOR_ERROR",
  CHANGE_DOCTOR_RESET = "action/CHANGE_DOCTOR_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface ChanceDoctorPayload {
  appointment_id: number;
  doctor_id: number;
}

export interface ChanceDoctorResponse extends IResponse {
  result: any;
  code: number;
}
