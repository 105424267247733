import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Grid, notification, Spin } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from "moment";

import { Box, Input, Button, Table, Select } from "components";
import { SendCompleteIcon, ConfirmFailIcon } from "icons";
import { EMAIL_REGEX, PASSWORD_REGEX } from "constant";
import { httpStatus } from "configs/httpStatus";
import { IOption } from "components/select/Select";
import httpClient2 from "configs/httpClient2";

import { IHospital } from "models/hospitals/hospitalsList";
import { URI } from "services/Api";
import { ICreateHospitalPayload } from "models/hospitals/createHospital";
import {
  GetHospitalsActionCreators,
  CreateHospitalActionCreators,
  UpdateHospitalActionCreators,
  GetAgreeHospitalsActionCreators,
  GetAgreeSuperCitiesActionCreators,
  DeleteHospitalActionCreators,
} from "redux/rootActions";
import {
  selectIsLoadingHospital,
  selectHospitalErrorMessage,
  hospitalListDataResponse,
} from "redux/hospitals/hospitalsList/hospitalsListStates";
import {
  selectIsLoadingAgreeHospitals,
  agreeHospitalsResponse,
  selectAgreeHospitalsErrorMessage,
} from "redux/hospitals/agreeHospitals/agreeHospitalsStates";
import {
  selectIsLoadingAgreeSuperCities,
  agreeSuperCitiesResponse,
  selectAgreeSuperCitiesErrorMessage,
} from "redux/hospitals/agreeSuperCities/agreeSuperCitiesStates";
import {
  createHospitalErrorMessage,
  createHospitalDataResponse,
} from "redux/hospitals/createHospital/createHospitalStates";
import {
  selectIsUpdatingHospital,
  updateHospitalErrorMessage,
  updateHospitalDataResponse,
} from "redux/hospitals/updateHospital/updateHospitalStates";
import {
  deleteHospitalErrorMessage,
  deleteHospitalDataResponse,
} from "redux/hospitals/deleteHospital/deleteHospitalStates";

import {
  Title,
  WrapperCreateHospital,
  HospitalItemWrapper,
  ButtonWrapper,
  ItemButtonWrapper,
} from "./HospitalManagement.style";
import {
  WrapperBtnFooter,
  WrapperContentModal,
  ModalStyled,
} from "../physicianManagement/PhysicianManagement.style";
import { PointCodeOptions, PrefectureCodeOptions } from "./constant";

export interface DynamicErrorObject {
  [key: string]: any;
}

interface IHospitalItem extends IHospital {
  // agree_repo_hospital_id: number;
  password: string;
}

interface IErrorTemplate extends DynamicErrorObject {
  hospital_code: string;
  point_code: string;
  prefecture_code: string;
  director_name: string;
  urgent_hospital_name: string;
  stripe_connect_id: string;
  email: string;
  mynumber_url: string;
}

const tempErrObject: IErrorTemplate = {
  hospital_code: "",
  point_code: "",
  prefecture_code: "",
  director_name: "",
  urgent_hospital_name: "",
  stripe_connect_id: "",
  email: "",
  mynumber_url: "",
};

const schema = yup.object().shape({
  super_city_name: yup.string().trim().required("選択してください"),
  name: yup.string().trim().required("病院名を検索してください"),
  hospital_code: yup.string().trim().required("病院コードを入力してください"),
  // point_code: yup.string().trim().required("選択してください"),
  director_name: yup.string().trim().required("医院長名を入力してください"),
  // mynumber_url: yup
  //   .string()
  //   .trim()
  //   .required("マイナ在宅受付Web URLを入力してください"),
  stripe_connect_id: yup
    .string()
    .trim()
    .required("Stripe IDを入力してください"),
  urgent_hospital_name: yup
    .string()
    .trim()
    .required("病院名を入力してください"),
  prefecture_code: yup.string().trim().required("選択してください"),
  email: yup
    .string()
    .trim()
    .required("メールアドレスを入力してください")
    .matches(EMAIL_REGEX, "メールアドレスの正しい形式で入力してください"),
  password: yup.string().trim().required("パスワードを入力してください"),
});

const HospitalManagement = () => {
  const dispatch = useDispatch();

  const loadingHospital = useSelector(selectIsLoadingHospital);
  const loadingAgreeHospitals = useSelector(selectIsLoadingAgreeHospitals);
  const loadingAgreeSuperCities = useSelector(selectIsLoadingAgreeSuperCities);
  const updatingHospital = useSelector(selectIsUpdatingHospital);

  const getHospitalsError = useSelector(selectHospitalErrorMessage);
  const createHospitalError = useSelector(createHospitalErrorMessage);
  const getAgreeHospitalError = useSelector(selectAgreeHospitalsErrorMessage);
  const getAgreeSuperCitiesError = useSelector(
    selectAgreeSuperCitiesErrorMessage
  );
  const updateHospitalError = useSelector(updateHospitalErrorMessage);
  const deleteHospitalError = useSelector(deleteHospitalErrorMessage);

  const hospitalsData = useSelector(hospitalListDataResponse);
  const agreeHospitalsData = useSelector(agreeHospitalsResponse);
  const agreeSuperCitiesData = useSelector(agreeSuperCitiesResponse);
  const createHospitalData = useSelector(createHospitalDataResponse);
  const updateHospitalData = useSelector(updateHospitalDataResponse);
  const deleteHospitalData = useSelector(deleteHospitalDataResponse);

  const { handleSubmit, control, setValue, reset } = useForm({
    resolver: yupResolver(schema),
  });

  const [hospitalsTemplates, setHospitalsTemplates] = useState<IHospitalItem[]>(
    []
  );

  const [superCitiesOptions, setSuperCitiesOptions] = useState<IOption[]>([]);
  const [agreeHospitalsOptions, setAgreeHospitalsOptions] = useState<IOption[]>(
    []
  );
  const [pageNumHospital, setPageNumHospital] = useState<number>(1);
  const [pageNumSuperCity, setPageNumSuperCity] = useState<number>(1);

  const [hospitalName, setHospitalName] = useState<string>("");
  const [superCityName, setSuperCityName] = useState<string>("");

  const [hospitalDebouncedValue, setHospitalDebouncedValue] = useState<any>("");
  const [superCityDebouncedValue, setSuperCityDebouncedValue] =
    useState<any>("");

  const [hospitalMaxPage, setHospitalMaxPage] = useState<number>(1);
  const [superCitiesMaxPage, setSuperCitiesMaxPage] = useState<number>(1);

  const [selectedHospitalName, setSelectedHospitalName] = useState<string>("");
  const [selectedSuperCityName, setSelectedSuperCityName] =
    useState<string>("");

  const [hospitalErrors, setHospitalErrors] = useState<IErrorTemplate[]>([]);
  const [editStatus, setEditStatus] = useState<boolean[]>([]);
  const [selectedRecord, setSelectedRecord] = useState<number>(-1);
  const [deleteHospitalName, setDeleteHospitalName] = useState<string>("");
  const [isConfirmModal, setIsConfirmModal] = useState<boolean>(false);

  const resetForm = () => {
    reset();
    setValue("name", undefined);
    setValue("super_city_name", undefined);
    setValue("mynumber_url", "");
    setValue("prefecture_code", undefined);
    setValue("point_code", undefined);
    setValue("hospital_code", "");
    setValue("director_name", "");
    setValue("urgent_hospital_name", "");
    setValue("stripe_connect_id", "");
    setValue("email", "");
    setValue("password", "");
    setSelectedHospitalName("");
    setSelectedSuperCityName("");
  };

  // const resetEditRecord = useCallback(() => {
  //   if (
  //     selectedRecord &&
  //     selectedRecord >= 0 &&
  //     hospitalsData &&
  //     hospitalsData?.result.length >= 0 &&
  //     hospitalsTemplates &&
  //     hospitalsTemplates.length >= 0
  //   ) {
  //     const tempHospital: IHospitalItem[] = [...hospitalsTemplates];
  //     const tempEditRecord = hospitalsData.result.find(
  //       (item: IHospital) => item.hospital_id === selectedRecord
  //     );

  //     const index = tempHospital.findIndex(
  //       (item: IHospitalItem) => item.hospital_id === selectedRecord
  //     );
  //     if (index >= 0 && tempEditRecord) {
  //       tempHospital[index] = {
  //         ...tempEditRecord,
  //         password: "",
  //       };
  //     }

  //     setHospitalsTemplates(tempHospital);
  //     setSelectedRecord(-1);
  //   }
  // }, [hospitalsData]);

  const checkObjectEmpty = (obj: DynamicErrorObject): boolean => {
    return Object.values(obj).every(
      (value) => value === "" || value === null || value === undefined
    );
  };

  const checkResetError = useMemo(() => {
    if (hospitalErrors && hospitalErrors.length > 0) {
      if (
        hospitalErrors.every((item: IErrorTemplate) => checkObjectEmpty(item))
      ) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  }, [hospitalErrors]);

  const resetErrors = useCallback(() => {
    if (hospitalsTemplates?.length > 0) {
      const tempErr: IErrorTemplate[] = [];

      for (let i = 0; i < hospitalsTemplates.length; i++) {
        tempErr.push(tempErrObject);
      }

      setHospitalErrors(tempErr);
    }
  }, [hospitalsTemplates]);

  const handleValidateItem = useCallback(
    (index: number) => {
      if (index >= 0 && hospitalsTemplates?.length > 0) {
        const tempErr: IErrorTemplate[] = [];
        const item = hospitalsTemplates[index];

        for (let i = 0; i < hospitalsTemplates.length; i++) {
          tempErr.push(tempErrObject);
        }

        const errorItem: IErrorTemplate = {
          ...tempErrObject,
        };

        if (!item.hospital_code) {
          errorItem["hospital_code"] = "病院コードを入力してください";
        }
        if (!item.point_code) {
          errorItem["point_code"] = "選択してください";
        }
        if (!item.prefecture_code) {
          errorItem["prefecture_code"] = "選択してください";
        }
        if (!item.director_name) {
          errorItem["director_name"] = "医院長名を入力してください";
        }
        if (!item.urgent_hospital_name) {
          errorItem["urgent_hospital_name"] = "病院名を入力してください";
        }
        if (!item.stripe_connect_id) {
          errorItem["stripe_connect_id"] = "Stripe IDを入力してください";
        }
        if (!item.email) {
          errorItem["email"] = "メールアドレスを入力してください";
        } else {
          if (!EMAIL_REGEX.test(item.email)) {
            errorItem["email"] = "メールアドレスの正しい形式で入力してください";
          }
        }
        // if (!item.mynumber_url) {
        //   errorItem["mynumber_url"] = "マイナ在宅受付Web URLを入力してください";
        // }

        tempErr[index] = errorItem;

        setHospitalErrors(tempErr);
        for (const key in errorItem) {
          if (errorItem[key] !== "") {
            return false;
          }
        }
        return true;
      }
      return false;
    },
    [hospitalsTemplates]
  );

  const returnError = useCallback(
    (index: number, key: string): string => {
      if (index >= 0 && hospitalErrors?.length > 0) {
        const tempErr = hospitalErrors[index];

        return tempErr[key];
      }
      return "";
    },
    [hospitalErrors]
  );

  const resetIndividualError = (index: number, key: string) => {
    if (
      index >= 0 &&
      hospitalsTemplates?.length > 0 &&
      hospitalErrors?.length > 0
    ) {
      if (checkResetError) {
        const tempErr = hospitalErrors[index];

        const tempErrs: IErrorTemplate[] = [];

        for (let i = 0; i < hospitalsTemplates.length; i++) {
          tempErrs.push(tempErrObject);
        }

        tempErr[key] = "";

        tempErrs[index] = tempErr;

        setHospitalErrors(tempErrs);
      }
    }
    return;
  };

  const checkEdit = useCallback(
    (index: number) => {
      if (
        index >= 0 &&
        hospitalsData &&
        hospitalsData.result?.length > 0 &&
        hospitalsTemplates?.length > 0
      ) {
        const keyDiff: string[] = [];

        Object.keys(hospitalsTemplates[index]).filter((key: string) => {
          if (
            hospitalsTemplates[index][key] !== hospitalsData.result[index][key]
          ) {
            if (key !== "password") {
              keyDiff.push(key);
            }
          }
        });

        if (keyDiff?.length > 0) {
          return true;
        }
        return false;
      }
      return false;
    },
    [hospitalsTemplates, hospitalsData]
  );

  const isEditing = (index: number) => {
    if (editStatus && editStatus.length > 0 && index >= 0) {
      return editStatus[index];
    }
    return false;
  };

  const handleChangeInput = (index: number, key: string, val: any) => {
    if (index >= 0 && hospitalsTemplates?.length > 0) {
      const tempArr = [...hospitalsTemplates];

      if (key === "mynumber_url") {
        tempArr[index][key] = val ? val : "";
      } else {
        tempArr[index][key] = val ? val : null;
      }

      resetIndividualError(index, key);
      setHospitalsTemplates(tempArr);
    }
    return;
  };

  const handleChangeSelect = (
    index: number,
    key1: string,
    key2: string,
    val1: any,
    val2: any
  ) => {
    if (index >= 0 && hospitalsTemplates?.length > 0) {
      const tempArr = [...hospitalsTemplates];

      tempArr[index][key1] = val1 ? val1 : null;
      tempArr[index][key2] = val2 ? val2 : null;

      setHospitalsTemplates(tempArr);
    }
    return;
  };

  const handleChangeSelect2 = (index: number, key: string, val: any) => {
    if (index >= 0 && hospitalsTemplates?.length > 0) {
      const tempArr = [...hospitalsTemplates];

      tempArr[index][key] = val ? val : null;

      setHospitalsTemplates(tempArr);
      resetIndividualError(index, key);
    }
    return;
  };

  const updateHospital = useCallback(
    (index: number) => {
      if (
        index >= 0 &&
        hospitalsTemplates?.length > 0 &&
        handleValidateItem(index)
      ) {
        dispatch(
          UpdateHospitalActionCreators.updateHospitalAction({
            hospital_id: hospitalsTemplates[index].hospital_id,
            hospital: {
              agree_repo_hospital_id:
                hospitalsTemplates[index].agree_repo_hospital_id,
              agree_repo_super_city_id: hospitalsTemplates[index].super_city_id,
              mynumber_url: hospitalsTemplates[index].mynumber_url
                ? hospitalsTemplates[index].mynumber_url
                : "",
              prefecture_code:
                hospitalsTemplates[index].prefecture_code &&
                hospitalsTemplates[index].prefecture_code.toString()
                  ? hospitalsTemplates[index].prefecture_code.toString()
                  : "",
              point_code:
                hospitalsTemplates[index].point_code &&
                hospitalsTemplates[index].point_code.toString()
                  ? hospitalsTemplates[index].point_code.toString()
                  : "",
              hospital_code:
                hospitalsTemplates[index].hospital_code &&
                hospitalsTemplates[index].hospital_code.toString()
                  ? hospitalsTemplates[index].hospital_code.toString()
                  : "",
              director_name: hospitalsTemplates[index].director_name
                ? hospitalsTemplates[index].director_name
                : "",
              urgent_hospital_name: hospitalsTemplates[index]
                .urgent_hospital_name
                ? hospitalsTemplates[index].urgent_hospital_name
                : "",
              email: hospitalsTemplates[index].email
                ? hospitalsTemplates[index].email
                : "",
              password: hospitalsTemplates[index].password
                ? hospitalsTemplates[index].password
                : "",
              stripe_connect_id: hospitalsTemplates[index].stripe_connect_id
                ? hospitalsTemplates[index].stripe_connect_id
                : "",
            },
          })
        );
      }
      return;
    },
    [hospitalsTemplates]
  );

  const handleScrollHospitals = async (e: any) => {
    const isEndOfList =
      e.target.scrollTop + e.target.clientHeight === e.target.scrollHeight;
    if (isEndOfList) {
      if (pageNumHospital < hospitalMaxPage) {
        const pageNum = pageNumHospital + 1;
        setPageNumHospital(pageNum);
        const { data } = await httpClient2.get(
          URI.HOSPITALS.GET_AGREE_HOSPITAL,
          {
            params: {
              name: hospitalName,
              page: pageNum,
              per_page: 100,
            },
          }
        );
        let newArr: any = [];
        if (data && data.result && data.result?.length) {
          newArr = data.result.map((item: any) => {
            return { id: item.id, name: item.name, value: item.id };
          });
        }
        if (newArr.length) {
          setAgreeHospitalsOptions((prevState: any) => [
            ...prevState,
            ...newArr,
          ]);
        }
      }
    }
  };

  const handleScrollSuperCities = async (e: any) => {
    const isEndOfList =
      e.target.scrollTop + e.target.clientHeight === e.target.scrollHeight;
    if (isEndOfList) {
      if (pageNumSuperCity < superCitiesMaxPage) {
        const pageNum = pageNumSuperCity + 1;
        setPageNumSuperCity(pageNum);
        const { data } = await httpClient2.get(
          URI.HOSPITALS.GET_AGREE_SUPER_CITIES,
          {
            params: {
              name: superCityName,
              page: pageNum,
              per_page: 100,
            },
          }
        );
        let newArr: any = [];
        if (data && data.result && data.result?.length) {
          newArr = data.result.map((item: any) => {
            return { id: item.id, name: item.name, value: item.id };
          });
        }
        if (newArr.length) {
          setSuperCitiesOptions((prevState: any) => [...prevState, ...newArr]);
        }
      }
    }
  };

  const resetHospitalOptions = () => {
    setHospitalName("");
    setHospitalDebouncedValue("");
    setHospitalMaxPage(1);
    setPageNumHospital(1);
    setAgreeHospitalsOptions([]);
  };

  const resetSuperCitesOptions = () => {
    setSuperCityName("");
    setSuperCityDebouncedValue("");
    setSuperCitiesMaxPage(1);
    setPageNumSuperCity(1);
    // setSuperCitiesOptions([]);
  };

  const handleCreateHospital = (data: { [x: string]: any }) => {
    const {
      name,
      mynumber_url,
      super_city_name,
      prefecture_code,
      point_code,
      hospital_code,
      director_name,
      urgent_hospital_name,
      stripe_connect_id,
      email,
      password,
    } = data;

    const params: ICreateHospitalPayload = {
      hospital: {
        agree_repo_super_city_id: parseInt(super_city_name),
        agree_repo_hospital_id: parseInt(name),
        hospital_code: hospital_code,
        point_code: point_code || "1",
        prefecture_code,
        director_name,
        urgent_hospital_name,
        stripe_connect_id,
        email,
        mynumber_url,
        password: password,
      },
    };

    dispatch(CreateHospitalActionCreators.createHospitalAction(params));
  };

  useEffect(() => {
    if (hospitalsData && hospitalsData.result && !loadingHospital) {
      if (hospitalsData.result.length) {
        const tempArr: IHospitalItem[] = [];
        const tempErr: IErrorTemplate[] = [];
        const tempStatuses: boolean[] = [];

        hospitalsData.result.map((item: IHospital) => {
          tempArr.push({
            ...item,
            password: "",
          });
          tempErr.push(tempErrObject);
          tempStatuses.push(false);
        });

        setHospitalsTemplates(tempArr);
        setHospitalErrors(tempErr);
        setEditStatus(tempStatuses);
      } else {
        setHospitalsTemplates([]);
        setHospitalErrors([]);
        setEditStatus([]);
      }
    }
  }, [hospitalsData, loadingHospital]);

  useEffect(() => {
    if (
      agreeHospitalsData &&
      agreeHospitalsData.result.length &&
      agreeHospitalsData.status === httpStatus.StatusOK
    ) {
      setHospitalMaxPage(agreeHospitalsData.total_pages);
      setAgreeHospitalsOptions(
        agreeHospitalsData.result.map((i) => {
          return {
            id: i.id,
            name: i.name,
            value: i.id,
          };
        })
      );
    } else if (agreeHospitalsData && !agreeHospitalsData.result.length) {
      setAgreeHospitalsOptions([]);
    }
  }, [agreeHospitalsData]);

  useEffect(() => {
    if (
      agreeSuperCitiesData &&
      agreeSuperCitiesData.result.length &&
      agreeSuperCitiesData.status === httpStatus.StatusOK
    ) {
      setSuperCitiesMaxPage(agreeSuperCitiesData.total_pages);
      setSuperCitiesOptions(
        agreeSuperCitiesData.result.map((i: any) => {
          return {
            id: i.id,
            name: i.name,
            value: i.id,
          };
        })
      );
    } else if (agreeSuperCitiesData && !agreeSuperCitiesData.result.length) {
      setSuperCitiesOptions([]);
    }
  }, [agreeSuperCitiesData]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setHospitalDebouncedValue(hospitalName);
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [hospitalName]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setSuperCityDebouncedValue(superCityName);
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [superCityName]);

  useEffect(() => {
    if (hospitalDebouncedValue) {
      dispatch(
        GetAgreeHospitalsActionCreators.getAgreeHospitalsAction({
          name: hospitalDebouncedValue,
          page: 1,
          per_page: 100,
        })
      );
    } else {
      resetHospitalOptions();
      dispatch(GetAgreeHospitalsActionCreators.handleResetAction());
    }
  }, [hospitalDebouncedValue]);

  useEffect(() => {
    if (checkResetError) {
      const handler = setTimeout(() => {
        resetErrors();
      }, 5000);

      return () => {
        clearTimeout(handler);
      };
    }
  }, [hospitalErrors]);

  useEffect(() => {
    dispatch(
      GetAgreeSuperCitiesActionCreators.getAgreeSuperCitiesAction({
        name: superCityDebouncedValue,
        page: 1,
        per_page: 100,
      })
    );
    // if (superCityDebouncedValue) {
    // } else {
    //   resetSuperCitesOptions();
    //   dispatch(GetAgreeSuperCitiesActionCreators.handleResetAction());
    // }
  }, [superCityDebouncedValue]);

  useEffect(() => {
    if (
      createHospitalData &&
      createHospitalData.status === httpStatus.StatusCreated
    ) {
      notification.open({
        message: moment(new Date()).format("YYYY/MM/DD HH:mm"),
        description: createHospitalData.message,
        icon: <SendCompleteIcon />,
        placement: "bottomRight",
      });
      resetForm();
      dispatch(GetHospitalsActionCreators.getHospitalListAction());
      dispatch(CreateHospitalActionCreators.handleResetAction());
    }
  }, [createHospitalData]);

  useEffect(() => {
    if (
      updateHospitalData &&
      updateHospitalData.status === httpStatus.StatusOK
    ) {
      notification.open({
        message: moment(new Date()).format("YYYY/MM/DD HH:mm"),
        description: updateHospitalData.message,
        icon: <SendCompleteIcon />,
        placement: "bottomRight",
      });
      dispatch(GetHospitalsActionCreators.getHospitalListAction());
      // resetEditRecord();
      // setSelectedRecord(-1);
      dispatch(UpdateHospitalActionCreators.handleResetAction());
    }
  }, [updateHospitalData]);

  useEffect(() => {
    if (
      deleteHospitalData &&
      deleteHospitalData.status === httpStatus.StatusOK
    ) {
      notification.open({
        message: moment(new Date()).format("YYYY/MM/DD HH:mm"),
        description: deleteHospitalData.message,
        icon: <SendCompleteIcon />,
        placement: "bottomRight",
      });
      dispatch(GetHospitalsActionCreators.getHospitalListAction());
      // resetEditRecord();
      // setSelectedRecord(-1);
      dispatch(DeleteHospitalActionCreators.handleResetAction());
    }
  }, [deleteHospitalData]);

  useEffect(() => {
    if (getAgreeHospitalError) {
      setAgreeHospitalsOptions([]);
      dispatch(GetAgreeHospitalsActionCreators.handleResetAction());
    }
  }, [getAgreeHospitalError]);

  useEffect(() => {
    if (getAgreeSuperCitiesError) {
      setSuperCitiesOptions([]);
      dispatch(GetAgreeSuperCitiesActionCreators.handleResetAction());
    }
  }, [getAgreeSuperCitiesError]);

  useEffect(() => {
    if (createHospitalError) {
      notification.error({
        placement: "bottomRight",
        message: createHospitalError,
        icon: <ConfirmFailIcon width={53} height={53} />,
      });
      dispatch(CreateHospitalActionCreators.handleResetAction());
    }
  }, [createHospitalError]);

  useEffect(() => {
    if (updateHospitalError) {
      notification.error({
        placement: "bottomRight",
        message: updateHospitalError,
        icon: <ConfirmFailIcon width={53} height={53} />,
      });
      // resetEditRecord();
      dispatch(UpdateHospitalActionCreators.handleResetAction());
    }
  }, [updateHospitalError]);

  useEffect(() => {
    if (deleteHospitalError) {
      notification.error({
        placement: "bottomRight",
        message: deleteHospitalError,
        icon: <ConfirmFailIcon width={53} height={53} />,
      });
      // resetEditRecord();
      dispatch(DeleteHospitalActionCreators.handleResetAction());
    }
  }, [deleteHospitalError]);

  useEffect(() => {
    dispatch(GetHospitalsActionCreators.getHospitalListAction());
    resetForm();
  }, []);

  return (
    <>
      <Box
        title="病院管理"
        padding="16px 16px 16px"
        style={{ boxShadow: "0px 0px 40px 0px #0000000D" }}
      >
        <form
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(handleCreateHospital)}
        >
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              gap: 40,
            }}
          >
            <ButtonWrapper>
              <Title style={{ marginBottom: 0 }}>病院登録</Title>
              <div
                style={{
                  fontWeight: 500,
                  lineHeight: "14px",
                  fontSize: 10,
                  color: "#FB4E4E",
                }}
              >
                ※の項目を入力・選択してください
              </div>
              <Button
                type="submit"
                // onClick={() => {
                //   if (!selectedHospitals.length) {
                //     setSelectHospitalError("所属病院を選択してください");
                //   }
                // }}
                background="#2F8CAE"
                color="#fff"
                name="登録"
                border="none"
                bdr="6px"
                fontSize={16}
                lineHeight="16px"
                fontWeight={700}
                width="192px"
                padding="8px 80px 7px"
                margin="128px 0px 0px 0px"
              />
            </ButtonWrapper>
            <WrapperCreateHospital>
              <div className="line">
                <div className="item-25">
                  {/* <AutoCompleteGroup>
                  <div className="auto-complete-title">スーパーシティ</div>
                  <Controller
                    control={control}
                    name="super_city_name"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <AutoComplete
                          value={value}
                          className="auto-complete"
                          onChange={onChange}
                          // options={medicineOptions}
                          onSelect={onSelect}
                          // onSearch={(text: any) => {
                          //   if (!text) {
                          //     dispatch(
                          //       SubSearchMedicineActionCreators.searchMedicineAction({
                          //         name: medicineName,
                          //       })
                          //     );
                          //   }
                          //   setMedicineName(text);
                          // }}
                          placeholder="選択してください"
                          suffixIcon={<SelectIcon />}
                        />
                        {error && error.message && (
                          <WrapperErrorMessage>
                            <ErrorMessage>{error.message}</ErrorMessage>
                          </WrapperErrorMessage>
                        )}
                      </>
                    )}
                  />
                </AutoCompleteGroup> */}
                  <Controller
                    control={control}
                    name="super_city_name"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Select
                        showSearch
                        value={
                          selectedSuperCityName
                            ? selectedSuperCityName
                            : undefined
                        }
                        filterOption={false}
                        label="スーパーシティ"
                        labelSuffix="*"
                        options={superCitiesOptions}
                        onChange={(e: any, option: any) => {
                          onChange(e);
                          setSelectedSuperCityName(option?.children || "");
                          resetSuperCitesOptions();
                        }}
                        onPopupScroll={handleScrollSuperCities}
                        onSearch={(text: any) => {
                          setSuperCityName(text);
                        }}
                        placeholder="選択してください"
                        height="31px"
                        fsLabel={16}
                        lhLabel="18px"
                        fwLabel={400}
                        mgBottomLabel={6}
                        boxShadow="0px 2px 4px 0px #00000017 inset"
                        borderColor="#B7B7B7"
                        bordered
                        error={error?.message}
                        dropdownRender={(menu) =>
                          loadingAgreeSuperCities ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                padding: "10px",
                              }}
                            >
                              <Spin size="small" />
                            </div>
                          ) : (
                            menu
                          )
                        }
                      />
                    )}
                  />
                </div>
                <div className="item-25-3">
                  {/* <AutoCompleteGroup>
                  <div className="auto-complete-title">病院名</div>
                  <Controller
                    control={control}
                    name="name"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <AutoComplete
                          value={value}
                          className="auto-complete"
                          onChange={onChange}
                          onPopupScroll={handleScrollHospitals}
                          options={agreeHospitalsOptions}
                          onSearch={(text: any) => {
                            if (!text) {
                              dispatch(
                                GetAgreeHospitalsActionCreators.getAgreeHospitalsAction(
                                  {
                                    name: hospitalName,
                                    page: 1,
                                    per_page: 50,
                                  }
                                )
                              );
                            }
                            setHospitalName(text);
                          }}
                          placeholder="病院名を選択してください"
                        />
                        {error && error.message && (
                          <WrapperErrorMessage>
                            <ErrorMessage>{error.message}</ErrorMessage>
                          </WrapperErrorMessage>
                        )}
                      </>
                    )}
                  />
                </AutoCompleteGroup> */}
                  <Controller
                    control={control}
                    name="name"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Select
                        showSearch
                        value={
                          selectedHospitalName
                            ? selectedHospitalName
                            : undefined
                        }
                        filterOption={false}
                        label="病院名"
                        labelSuffix="*"
                        boxShadow="0px 2px 4px 0px #00000017 inset"
                        borderColor="#B7B7B7"
                        options={agreeHospitalsOptions}
                        onChange={(e: any, option: any) => {
                          onChange(e);
                          setSelectedHospitalName(option?.children || "");
                          resetHospitalOptions();
                        }}
                        onPopupScroll={handleScrollHospitals}
                        onSearch={(text: any) => {
                          setHospitalName(text);
                        }}
                        placeholder="病院名を検索してください"
                        height="31px"
                        fsLabel={16}
                        lhLabel="18px"
                        fwLabel={400}
                        mgBottomLabel={6}
                        bordered
                        error={error?.message}
                        dropdownRender={(menu) =>
                          loadingAgreeHospitals ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                padding: "10px",
                              }}
                            >
                              <Spin size="small" />
                            </div>
                          ) : (
                            menu
                          )
                        }
                      />
                    )}
                  />
                </div>
                <div className="item-12-5">
                  <Controller
                    control={control}
                    name="hospital_code"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        type="number"
                        value={value}
                        onChange={onChange}
                        label="病院コード"
                        labelSuffix="*"
                        fsLabel={16}
                        lhLabel="18px"
                        height={31}
                        mgBottomLabel={6}
                        fw={400}
                        placeholder="病院コード"
                        boxShadow="0px 2px 4px 0px #00000017 inset"
                        borderColor="#B7B7B7"
                        error={error?.message}
                      />
                    )}
                  />
                </div>
                <div className="item-12-5-2">
                  <Controller
                    control={control}
                    name="point_code"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      // <Input
                      //   type="number"
                      //   value={value}
                      //   onChange={onChange}
                      //   label="点数区分コード"
                      //   fsLabel={16}
                      //   lhLabel="18px"
                      //   height={31}
                      //   mgBottomLabel={6}
                      //   fw={400}
                      //   placeholder="点数区分コード"
                      //   error={error?.message}
                      // />
                      <Select
                        label="点数区分コード"
                        labelSuffix="*"
                        value={value}
                        defaultValue={1}
                        options={PointCodeOptions}
                        placeholder="選択してください"
                        height="31px"
                        fsLabel={16}
                        lhLabel="18px"
                        fwLabel={400}
                        mgBottomLabel={6}
                        bordered
                        onChange={onChange}
                        boxShadow="0px 2px 4px 0px #00000017 inset"
                        borderColor="#B7B7B7"
                        error={error?.message}
                      />
                    )}
                  />
                </div>
                <div className="item-25">
                  <Controller
                    control={control}
                    name="prefecture_code"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      // <Input
                      //   type="number"
                      //   value={value}
                      //   onChange={onChange}
                      //   label="都道府県コード"
                      //   fsLabel={16}
                      //   lhLabel="18px"
                      //   height={31}
                      //   mgBottomLabel={6}
                      //   fw={400}
                      //   placeholder="都道府県コード"
                      //   error={error?.message}
                      // />
                      <Select
                        label="都道府県コード"
                        labelSuffix="*"
                        value={value}
                        options={PrefectureCodeOptions}
                        placeholder="選択してください"
                        boxShadow="0px 2px 4px 0px #00000017 inset"
                        borderColor="#B7B7B7"
                        height="31px"
                        fsLabel={16}
                        lhLabel="18px"
                        fwLabel={400}
                        mgBottomLabel={6}
                        bordered
                        onChange={onChange}
                        error={error?.message}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="item-25-2">
                <Controller
                  control={control}
                  name="director_name"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      value={value}
                      onChange={onChange}
                      label="医院長名"
                      labelSuffix="*"
                      boxShadow="0px 2px 4px 0px #00000017 inset"
                      borderColor="#B7B7B7"
                      fsLabel={16}
                      lhLabel="18px"
                      height={31}
                      mgBottomLabel={6}
                      fw={400}
                      placeholder="医院長名を入力してください"
                      error={error?.message}
                    />
                  )}
                />
              </div>
              <div className="item-25-2">
                <Controller
                  control={control}
                  name="urgent_hospital_name"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      value={value}
                      onChange={onChange}
                      label="緊急時病院"
                      labelSuffix="*"
                      fsLabel={16}
                      boxShadow="0px 2px 4px 0px #00000017 inset"
                      borderColor="#B7B7B7"
                      lhLabel="18px"
                      height={31}
                      mgBottomLabel={6}
                      fw={400}
                      placeholder="病院名を入力してください"
                      error={error?.message}
                    />
                  )}
                />
              </div>
              <div className="item-25-2">
                <Controller
                  control={control}
                  name="stripe_connect_id"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      value={value}
                      onChange={onChange}
                      label="Stripe ID"
                      labelSuffix="*"
                      boxShadow="0px 2px 4px 0px #00000017 inset"
                      borderColor="#B7B7B7"
                      fsLabel={16}
                      lhLabel="18px"
                      height={31}
                      mgBottomLabel={6}
                      fw={400}
                      placeholder="Stripe IDを入力してください"
                      error={error?.message}
                    />
                  )}
                />
              </div>
              <div className="item-25-2">
                <Controller
                  control={control}
                  name="email"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      value={value}
                      onChange={onChange}
                      boxShadow="0px 2px 4px 0px #00000017 inset"
                      borderColor="#B7B7B7"
                      label="メールアドレス"
                      labelSuffix="*"
                      fsLabel={16}
                      lhLabel="18px"
                      height={31}
                      mgBottomLabel={6}
                      fw={400}
                      placeholder="メールアドレスを入力してください"
                      error={error?.message}
                    />
                  )}
                />
              </div>
              <div className="item-75">
                <Controller
                  control={control}
                  name="mynumber_url"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      autoComplete="off"
                      name="mynumber_url"
                      value={value}
                      onChange={onChange}
                      label="マイナ在宅受付Web URL"
                      boxShadow="0px 2px 4px 0px #00000017 inset"
                      borderColor="#B7B7B7"
                      fsLabel={16}
                      lhLabel="18px"
                      height={31}
                      mgBottomLabel={6}
                      fw={400}
                      error={error?.message}
                    />
                  )}
                />
              </div>
              <div className="item-25-4">
                <Controller
                  control={control}
                  name="password"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      type="password"
                      autoComplete="new-password"
                      boxShadow="0px 2px 4px 0px #00000017 inset"
                      borderColor="#B7B7B7"
                      name="new-password"
                      defaultValue={undefined}
                      value={value}
                      onChange={onChange}
                      label="ログインパスワード"
                      labelSuffix="*"
                      fsLabel={16}
                      lhLabel="18px"
                      height={31}
                      mgBottomLabel={6}
                      fw={400}
                      error={error?.message}
                      placeholder="パスワードを入力してください"
                    />
                  )}
                />
              </div>
            </WrapperCreateHospital>
          </div>
        </form>
      </Box>
      {hospitalsTemplates &&
        hospitalsTemplates?.length > 0 &&
        hospitalsTemplates.map((item: IHospital, index: number) => (
          <HospitalItemWrapper
            key={item.hospital_id}
            style={{ marginTop: index === 0 ? 24 : 16 }}
          >
            <WrapperCreateHospital>
              <div className="line">
                <div className="item-25">
                  <Select
                    disabled={!isEditing(index)}
                    borderColor={
                      !isEditing(index)
                        ? "rgba(183, 183, 183, 0.24)"
                        : "rgba(183, 183, 183, 1)"
                    }
                    boxShadow={
                      !isEditing(index)
                        ? "0px 2px 4px 0px rgba(0, 0, 0, 0.02) inset"
                        : "0px 2px 4px 0px rgba(0, 0, 0, 0.08) inset"
                    }
                    disabledBackground="#ffffff"
                    disabledTextColor="#3B3B3B"
                    showSearch
                    value={item.super_city_name ? item.super_city_name : ""}
                    filterOption={false}
                    label="スーパーシティ"
                    options={superCitiesOptions}
                    onChange={(e: any, option: any) => {
                      // onChange(e);
                      handleChangeSelect(
                        index,
                        "super_city_id",
                        "super_city_name",
                        e,
                        option?.children || ""
                      );
                      resetSuperCitesOptions();
                    }}
                    onPopupScroll={handleScrollSuperCities}
                    onSearch={(text: any) => {
                      setSuperCityName(text);
                    }}
                    placeholder="選択してください"
                    height="31px"
                    fsLabel={16}
                    lhLabel="18px"
                    fwLabel={400}
                    mgBottomLabel={6}
                    bordered
                    dropdownRender={(menu) =>
                      loadingAgreeSuperCities ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            padding: "10px",
                          }}
                        >
                          <Spin size="small" />
                        </div>
                      ) : (
                        menu
                      )
                    }
                    // error={error?.message}
                  />
                </div>
                <div className="item-25-3">
                  <Select
                    disabled
                    showTooltip
                    borderColor={
                      !isEditing(index)
                        ? "rgba(183, 183, 183, 0.24)"
                        : "rgba(183, 183, 183, 1)"
                    }
                    boxShadow={
                      !isEditing(index)
                        ? "0px 2px 4px 0px rgba(0, 0, 0, 0.02) inset"
                        : "0px 2px 4px 0px rgba(0, 0, 0, 0.08) inset"
                    }
                    disabledBackground="#ffffff"
                    disabledTextColor="#3B3B3B"
                    showSearch
                    className="hospital-item"
                    value={item.name ? item.name : ""}
                    filterOption={false}
                    label="病院名"
                    options={agreeHospitalsOptions}
                    onChange={(e: any, option: any) => {
                      // onChange(e);
                      handleChangeSelect(
                        index,
                        "agree_repo_hospital_id",
                        "name",
                        e,
                        option?.children || ""
                      );
                      resetHospitalOptions();
                    }}
                    onPopupScroll={handleScrollHospitals}
                    onSearch={(text: any) => {
                      setHospitalName(text);
                    }}
                    placeholder="病院名を検索してください"
                    height="31px"
                    fsLabel={16}
                    lhLabel="18px"
                    fwLabel={400}
                    mgBottomLabel={6}
                    bordered
                    dropdownRender={(menu) =>
                      loadingAgreeHospitals ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            padding: "10px",
                          }}
                        >
                          <Spin size="small" />
                        </div>
                      ) : (
                        menu
                      )
                    }
                    // error={error?.message}
                  />
                </div>
                <div className="item-12-5">
                  <Input
                    disabled={!isEditing(index)}
                    borderColor={
                      !isEditing(index)
                        ? "rgba(183, 183, 183, 0.24)"
                        : "rgba(183, 183, 183, 1)"
                    }
                    boxShadow={
                      !isEditing(index)
                        ? "0px 2px 4px 0px rgba(0, 0, 0, 0.02) inset"
                        : "0px 2px 4px 0px rgba(0, 0, 0, 0.08) inset"
                    }
                    disabledBackground="#ffffff"
                    type="number"
                    value={item.hospital_code ? item.hospital_code : undefined}
                    onChange={(e: any) =>
                      handleChangeInput(
                        index,
                        "hospital_code",
                        parseInt(e.target.value)
                      )
                    }
                    label="病院コード"
                    fsLabel={16}
                    lhLabel="18px"
                    height={31}
                    mgBottomLabel={6}
                    placeholder="病院コード"
                    fw={400}
                    error={
                      returnError(index, "hospital_code")
                        ? returnError(index, "hospital_code")
                        : ""
                    }
                  />
                </div>
                <div className="item-12-5-2">
                  {/* <Input
                    type="number"
                    value={item.point_code ? item.point_code : undefined}
                    onChange={(e: any) =>
                      handleChangeInput(
                        index,
                        "point_code",
                        parseInt(e.target.value)
                      )
                    }
                    label="点数区分コード"
                    fsLabel={16}
                    lhLabel="18px"
                    height={31}
                    mgBottomLabel={6}
                    fw={400}
                    placeholder="点数区分コード"
                    error={
                      returnError(index, "point_code")
                        ? returnError(index, "point_code")
                        : ""
                    }
                  /> */}
                  <Select
                    disabled={!isEditing(index)}
                    borderColor={
                      !isEditing(index)
                        ? "rgba(183, 183, 183, 0.24)"
                        : "rgba(183, 183, 183, 1)"
                    }
                    boxShadow={
                      !isEditing(index)
                        ? "0px 2px 4px 0px rgba(0, 0, 0, 0.02) inset"
                        : "0px 2px 4px 0px rgba(0, 0, 0, 0.08) inset"
                    }
                    disabledBackground="#ffffff"
                    disabledTextColor="#3B3B3B"
                    label="点数区分コード"
                    value={item.point_code ? item.point_code : undefined}
                    // defaultValue={1}
                    options={PointCodeOptions}
                    placeholder="選択してください"
                    height="31px"
                    fsLabel={16}
                    lhLabel="18px"
                    fwLabel={400}
                    mgBottomLabel={6}
                    bordered
                    onChange={(e: any) =>
                      handleChangeSelect2(index, "point_code", parseInt(e))
                    }
                    error={
                      returnError(index, "point_code")
                        ? returnError(index, "point_code")
                        : ""
                    }
                  />
                </div>
                <div className="item-25">
                  <Select
                    disabled={!isEditing(index)}
                    borderColor={
                      !isEditing(index)
                        ? "rgba(183, 183, 183, 0.24)"
                        : "rgba(183, 183, 183, 1)"
                    }
                    boxShadow={
                      !isEditing(index)
                        ? "0px 2px 4px 0px rgba(0, 0, 0, 0.02) inset"
                        : "0px 2px 4px 0px rgba(0, 0, 0, 0.08) inset"
                    }
                    disabledBackground="#ffffff"
                    disabledTextColor="#3B3B3B"
                    label="都道府県コード"
                    value={
                      item.prefecture_code ? item.prefecture_code : undefined
                    }
                    options={PrefectureCodeOptions}
                    placeholder="選択してください"
                    height="31px"
                    fsLabel={16}
                    lhLabel="18px"
                    fwLabel={400}
                    mgBottomLabel={6}
                    bordered
                    onChange={(e: any) =>
                      handleChangeSelect2(index, "prefecture_code", parseInt(e))
                    }
                    error={
                      returnError(index, "prefecture_code")
                        ? returnError(index, "prefecture_code")
                        : ""
                    }
                  />
                  {/* <Input
                    type="number"
                    value={
                      item.prefecture_code ? item.prefecture_code : undefined
                    }
                    onChange={(e: any) =>
                      handleChangeInput(
                        index,
                        "prefecture_code",
                        parseInt(e.target.value)
                      )
                    }
                    label="都道府県コード"
                    fsLabel={16}
                    lhLabel="18px"
                    height={31}
                    mgBottomLabel={6}
                    fw={400}
                    placeholder="都道府県コード"
                    error={
                      returnError(index, "prefecture_code")
                        ? returnError(index, "prefecture_code")
                        : ""
                    }
                  /> */}
                </div>
              </div>
              <div className="item-25-2">
                <Input
                  disabled={!isEditing(index)}
                  disabledBackground="#ffffff"
                  borderColor={
                    !isEditing(index)
                      ? "rgba(183, 183, 183, 0.24)"
                      : "rgba(183, 183, 183, 1)"
                  }
                  boxShadow={
                    !isEditing(index)
                      ? "0px 2px 4px 0px rgba(0, 0, 0, 0.02) inset"
                      : "0px 2px 4px 0px rgba(0, 0, 0, 0.08) inset"
                  }
                  value={item.director_name ? item.director_name : ""}
                  onChange={(e: any) =>
                    handleChangeInput(index, "director_name", e.target.value)
                  }
                  label="医院長名"
                  fsLabel={16}
                  lhLabel="18px"
                  height={31}
                  mgBottomLabel={6}
                  fw={400}
                  placeholder="医院長名を入力してください"
                  error={
                    returnError(index, "director_name")
                      ? returnError(index, "director_name")
                      : ""
                  }
                />
              </div>
              <div className="item-25-2">
                <Input
                  disabledBackground="#ffffff"
                  disabled={!isEditing(index)}
                  borderColor={
                    !isEditing(index)
                      ? "rgba(183, 183, 183, 0.24)"
                      : "rgba(183, 183, 183, 1)"
                  }
                  boxShadow={
                    !isEditing(index)
                      ? "0px 2px 4px 0px rgba(0, 0, 0, 0.02) inset"
                      : "0px 2px 4px 0px rgba(0, 0, 0, 0.08) inset"
                  }
                  value={
                    item.urgent_hospital_name ? item.urgent_hospital_name : ""
                  }
                  onChange={(e: any) =>
                    handleChangeInput(
                      index,
                      "urgent_hospital_name",
                      e.target.value
                    )
                  }
                  label="緊急時病院"
                  fsLabel={16}
                  lhLabel="18px"
                  height={31}
                  mgBottomLabel={6}
                  placeholder="病院名を入力してください"
                  fw={400}
                  error={
                    returnError(index, "urgent_hospital_name")
                      ? returnError(index, "urgent_hospital_name")
                      : ""
                  }
                />
              </div>
              <div className="item-25-2">
                <Input
                  disabled={!isEditing(index)}
                  borderColor={
                    !isEditing(index)
                      ? "rgba(183, 183, 183, 0.24)"
                      : "rgba(183, 183, 183, 1)"
                  }
                  boxShadow={
                    !isEditing(index)
                      ? "0px 2px 4px 0px rgba(0, 0, 0, 0.02) inset"
                      : "0px 2px 4px 0px rgba(0, 0, 0, 0.08) inset"
                  }
                  disabledBackground="#ffffff"
                  value={item.stripe_connect_id ? item.stripe_connect_id : ""}
                  onChange={(e: any) =>
                    handleChangeInput(
                      index,
                      "stripe_connect_id",
                      e.target.value
                    )
                  }
                  label="Stripe ID"
                  fsLabel={16}
                  lhLabel="18px"
                  height={31}
                  mgBottomLabel={6}
                  placeholder="Stripe IDを入力してください"
                  fw={400}
                  error={
                    returnError(index, "stripe_connect_id")
                      ? returnError(index, "stripe_connect_id")
                      : ""
                  }
                />
              </div>
              <div className="item-25-2">
                <Input
                  disabled={!isEditing(index)}
                  borderColor={
                    !isEditing(index)
                      ? "rgba(183, 183, 183, 0.24)"
                      : "rgba(183, 183, 183, 1)"
                  }
                  boxShadow={
                    !isEditing(index)
                      ? "0px 2px 4px 0px rgba(0, 0, 0, 0.02) inset"
                      : "0px 2px 4px 0px rgba(0, 0, 0, 0.08) inset"
                  }
                  disabledBackground="#ffffff"
                  value={item.email ? item.email : ""}
                  onChange={(e: any) =>
                    handleChangeInput(index, "email", e.target.value)
                  }
                  label="メールアドレス"
                  fsLabel={16}
                  lhLabel="18px"
                  fw={400}
                  height={31}
                  mgBottomLabel={6}
                  placeholder="メールアドレスを入力してください"
                  error={
                    returnError(index, "email")
                      ? returnError(index, "email")
                      : ""
                  }
                />
              </div>
              <div className="item-75">
                <Input
                  disabled={!isEditing(index)}
                  borderColor={
                    !isEditing(index)
                      ? "rgba(183, 183, 183, 0.24)"
                      : "rgba(183, 183, 183, 1)"
                  }
                  boxShadow={
                    !isEditing(index)
                      ? "0px 2px 4px 0px rgba(0, 0, 0, 0.02) inset"
                      : "0px 2px 4px 0px rgba(0, 0, 0, 0.08) inset"
                  }
                  disabledBackground="#ffffff"
                  value={item.mynumber_url ? item.mynumber_url : ""}
                  onChange={(e: any) =>
                    handleChangeInput(index, "mynumber_url", e.target.value)
                  }
                  label="マイナ在宅受付Web URL"
                  fsLabel={16}
                  lhLabel="18px"
                  fw={400}
                  height={31}
                  mgBottomLabel={6}
                  error={
                    returnError(index, "mynumber_url")
                      ? returnError(index, "mynumber_url")
                      : ""
                  }
                />
              </div>
              <div className="item-25-4">
                {/* <Input
                  value={item.mynumber_url ? item.mynumber_url : ""}
                  onChange={(e: any) =>
                    handleChangeInput(index, "mynumber_url", e.target.value)
                  }
                  label="マイナ在宅受付Web URL"
                  fsLabel={16}
                  lhLabel="18px"
                  fw={400}
                  height={31}
                  mgBottomLabel={6}
                  error={
                    returnError(index, "mynumber_url")
                      ? returnError(index, "mynumber_url")
                      : ""
                  }
                /> */}
                <Input
                  disabled={!isEditing(index)}
                  borderColor={
                    !isEditing(index)
                      ? "rgba(183, 183, 183, 0.24)"
                      : "rgba(183, 183, 183, 1)"
                  }
                  boxShadow={
                    !isEditing(index)
                      ? "0px 2px 4px 0px rgba(0, 0, 0, 0.02) inset"
                      : "0px 2px 4px 0px rgba(0, 0, 0, 0.08) inset"
                  }
                  disabledBackground="#ffffff"
                  type="password"
                  autoComplete="new-password"
                  name="new-password"
                  defaultValue={undefined}
                  value={item.password ? item.password : ""}
                  onChange={(e: any) =>
                    handleChangeInput(index, "password", e.target.value)
                  }
                  label="ログインパスワード"
                  fsLabel={16}
                  lhLabel="18px"
                  height={31}
                  mgBottomLabel={6}
                  fw={400}
                  error={
                    returnError(index, "password")
                      ? returnError(index, "password")
                      : ""
                  }
                  placeholder="パスワードを入力してください"
                />
              </div>
            </WrapperCreateHospital>
            <ItemButtonWrapper>
              <Button
                type="submit"
                onClick={() => {
                  if (editStatus && editStatus.length > 0) {
                    if (editStatus[index]) {
                      // setSelectedRecord(item.hospital_id);
                      updateHospital(index);
                    }

                    const tempStatus = [...editStatus];
                    if (tempStatus[index]) {
                      if (handleValidateItem(index)) {
                        tempStatus[index] = !tempStatus[index];
                        setEditStatus(tempStatus);
                      }
                    } else {
                      tempStatus[index] = !tempStatus[index];
                      setEditStatus(tempStatus);
                    }
                  }
                }}
                background={isEditing(index) ? "#2F8CAE" : "#2AC769"}
                // background="#2AC769"
                color="#fff"
                name={isEditing(index) ? "保存" : "編集"}
                // name="編集"
                border="none"
                bdr="6px"
                fontSize={16}
                lineHeight="16px"
                fontWeight={700}
                width="192px"
                // disabled={isCreatingDoctor}
                padding="8px 80px 7px"
                margin="24px 0px 0px 0px"
              />
              <Button
                type="submit"
                onClick={() => {
                  setSelectedRecord(item.hospital_id);
                  setDeleteHospitalName(item.name);
                  setIsConfirmModal(true);
                }}
                background="#ffffff"
                color="#FB4E4E"
                name="削除"
                border="1px solid #FB4E4E"
                bdr="6px"
                fontSize={16}
                lineHeight="16px"
                fontWeight={700}
                width="192px"
                // disabled={isCreatingDoctor}
                padding="7px 79px 6px"
                margin="111px 0px 0px 0px"
              />
            </ItemButtonWrapper>
          </HospitalItemWrapper>
        ))}
      {isConfirmModal && (
        <ModalStyled visible={isConfirmModal} type="confirm" footer={null}>
          <WrapperContentModal>
            <div>{deleteHospitalName}病院を削除します。</div>
            <div>よろしいですか？</div>
            <WrapperBtnFooter>
              <Button
                onClick={() => {
                  setIsConfirmModal(false);
                  setDeleteHospitalName("");
                  setSelectedRecord(-1);
                }}
                background="#E0E0E0"
                color="#fff"
                name="キャンセル"
                border="none"
                bdr="6px"
                fontSize={16}
                fontWeight={700}
              />

              <Button
                onClick={() => {
                  if (selectedRecord >= 0) {
                    dispatch(
                      DeleteHospitalActionCreators.deleteHospitalAction({
                        hospital_id: selectedRecord,
                      })
                    );
                  }
                  setIsConfirmModal(false);
                  setDeleteHospitalName("");
                }}
                background="#FB4E4E"
                color="#fff"
                name="はい"
                border="none"
                bdr="6px"
                fontSize={16}
                fontWeight={700}
              />
            </WrapperBtnFooter>
          </WrapperContentModal>
        </ModalStyled>
      )}
    </>
  );
};

export default HospitalManagement;
