import React from "react";

import { RouteProps } from "react-router-dom";

import {
  Login,
  ForgotPassword,
  ScheduleManagement,
  ReservationManagement,
  ChangePassword,
  PhysicianManagement,
  ScheduleManagementAdmin,
  ReservationDetails,
  HospitalManagement,
} from "screens";
import { MainLayout, DashboardLayout } from "layouts";

export interface IRoute extends RouteProps {
  restricted?: boolean;
  layout?: React.ComponentType;
}

interface IConfigRoutes {
  appRoutes: IRoute[];
  isPrivate?: boolean;
  layout?: React.ComponentType;
}

const privateRoutes: IRoute[] = [
  {
    children: <ScheduleManagementAdmin />,
    path: "/schedules",
    layout: DashboardLayout,
  },
  {
    children: <ReservationManagement />,
    // path: ["/reservations/:reservationId?", "/reservations"],
    path: "/reservations",
    layout: DashboardLayout,
  },
  {
    children: <PhysicianManagement />,
    path: "/profile",
    layout: DashboardLayout,
  },
  {
    children: <ReservationDetails />,
    path: "/reservation-details/:reservationId",
    layout: DashboardLayout,
  },
  {
    children: <HospitalManagement />,
    path: "/hospital-management",
    layout: DashboardLayout,
  },
  // {
  //   children: <ChangePassword />,
  //   path: "/change-password",
  //   layout: DashboardLayout,
  // },
];

const publicRoutes: IRoute[] = [
  {
    children: <Login />,
    path: "/login",
    layout: MainLayout,
    restricted: true,
  },
  {
    children: <ForgotPassword />,
    path: "/forgot-password",
    layout: MainLayout,
    restricted: true,
  },
];

export const configRoutes: IConfigRoutes[] = [
  {
    appRoutes: privateRoutes,
    isPrivate: true,
    layout: MainLayout,
  },
  {
    appRoutes: publicRoutes,
    layout: MainLayout,
  },
];
