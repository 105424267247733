import {
  Action,
  ActionType,
  AgreeSuperCitiesListResponse,
} from "models/hospitals/agreeSuperCitiesList";
import createReducer from "../../createReducer";

export interface AgreeSuperCitiesReducerType {
  loading: boolean;
  error?: string;
  agreeSuperCitiesResponse?: AgreeSuperCitiesListResponse;
}

const defaultState: AgreeSuperCitiesReducerType = {
  loading: false,
  error: undefined,
  agreeSuperCitiesResponse: undefined,
};

export const agreeSuperCitiesReducer =
  createReducer<AgreeSuperCitiesReducerType>(defaultState, {
    [ActionType.AGREE_SUPER_CITY_LIST](state: AgreeSuperCitiesReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.AGREE_SUPER_CITY_LIST_ERROR](
      state: AgreeSuperCitiesReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        agreeSuperCitiesResponse: undefined,
      };
    },

    [ActionType.AGREE_SUPER_CITY_LIST_SUCCESS](
      state: AgreeSuperCitiesReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        agreeSuperCitiesResponse: action.payload,
      };
    },

    [ActionType.AGREE_SUPER_CITY_LIST_RESET](
      state: AgreeSuperCitiesReducerType
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        agreeSuperCitiesResponse: undefined,
      };
    },
  });
