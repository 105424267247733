import {
  ActionType,
  Action,
  CreateHospitalResponse,
} from "models/hospitals/createHospital";
import createReducer from "../../createReducer";

export interface CreateHospitalReducerType {
  loading: boolean;
  error?: string;
  createHospitalResponse?: CreateHospitalResponse;
}

const defaultState: CreateHospitalReducerType = {
  loading: false,
  error: undefined,
  createHospitalResponse: undefined,
};

export const createHospitalReducer = createReducer<CreateHospitalReducerType>(
  defaultState,
  {
    [ActionType.CREATE_HOSPITAL](state: CreateHospitalReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.CREATE_HOSPITAL_ERROR](
      state: CreateHospitalReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        createHospitalResponse: undefined,
      };
    },

    [ActionType.CREATE_HOSPITAL_SUCCESS](
      state: CreateHospitalReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        createHospitalResponse: action.payload,
      };
    },

    [ActionType.CREATE_HOSPITALD_RESET](state: CreateHospitalReducerType) {
      return {
        ...state,
        loading: false,
        error: null,
        createHospitalResponse: undefined,
      };
    },
  }
);
