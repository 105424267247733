import styled from "styled-components";
import { theme } from "theme/theme";
import { Modal, Table } from "components";

export const Title = styled.div`
  font-family: Noto Sans JP;
  font-size: ${theme.sizes.md};
  font-weight: ${theme.fontWeight.bold};
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 16px;
  color: #1a2f62;
`;

export const WrapperCreateHospital = styled.div`
  display: flex;
  flex: 1;
  gap: 16px;
  flex-wrap: wrap;
  .line {
    width: 100%;
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
  }
  .item-12-5 {
    width: calc(20% - 12.8px);
  }
  .item-12-5-2 {
    width: calc(25% - 12.8px);
  }
  .item-15 {
    width: calc(15% - 12.8px);
  }
  .item-20 {
    width: calc(20% - 12.8px);
  }
  .item-25 {
    width: calc(25% - 12.8px);
  }
  .item-25-2 {
    width: calc(25% - 12px);
  }
  .item-25-3 {
    width: calc(40% - 12.8px);
    .hospital-item .ant-select-selector .ant-select-selection-item {
      font-weight: 700;
      font-size: 16px;
    }
  }
  .item-25-4 {
    width: calc(25% - 12px);
  }
  .item-75 {
    width: calc(75% - 4px);
  }
  .item-100 {
    width: 100%;
  }

  ${theme.media.laptop} {
    .item-12-5 {
      width: calc(15% - 12.8px);
    }
    .item-12-5-2 {
      width: calc(20% - 12.8px);
    }
    .item-25-3 {
      width: calc(30% - 12.8px);
    }
  }
  ${theme.media.desktop} {
    .item-12-5 {
      width: calc(12.5% - 12.8px);
    }
    .item-12-5-2 {
      width: calc(15% - 12.8px);
    }
    .item-25-3 {
      width: calc(25% - 12.8px);
    }
  }
  ${theme.media.desktopL} {
    .item-12-5-2 {
      width: calc(12.5% - 12.8px);
    }
  }
`;

export const HospitalItemWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 32px;
  padding: 16px 24px 16px 16px;
  background-color: #ffffff;
  box-shadow: 0px 0px 40px 0px #0000000d;
  border-radius: 8px;
  margin-top: 16px;
`;

export const AutoCompleteGroup = styled.div`
  width: 100%;
  position: relative;

  .auto-complete-title {
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 6px;
  }
  .auto-complete {
    width: 100%;
    height: 31px;
    border-radius: 6px;
  }
  .ant-select-selector {
    height: 31px !important;
    border-radius: 6px !important;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.09);
    border: 1px solid #cccccc;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    border-color: #83bce2;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.09);
  }
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #cccccc;
  }
  ${theme.media.tablet} {
    .auto-complete {
      /* width: 293px; */
    }
  }
  ${theme.media.laptop} {
  }
`;

export const ButtonWrapper = styled.div`
  button {
    margin-top: 199px;
  }
  ${theme.media.desktopL} {
    button {
      margin-top: 128px;
    }
  }
`;

export const ItemButtonWrapper = styled.div`
  width: 192px;
  button:last-child {
    margin-top: 182px;
  }
  ${theme.media.desktopL} {
    button:last-child {
      margin-top: 111px;
    }
  }
`;
