import {
  IDeleteHospitalPayload,
  ActionType,
  DeleteHospitalResponse,
} from "models/hospitals/deleteHospital";

export const deleteHospitalAction = (payload: IDeleteHospitalPayload) => {
  return {
    type: ActionType.DELETE_HOSPITAL,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.DELETE_HOSPITAL_ERROR,
    payload,
  };
};

export const handleSuccessAction = (payload: DeleteHospitalResponse) => {
  return {
    type: ActionType.DELETE_HOSPITAL_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.DELETE_HOSPITAL_ERROR,
  };
};
