import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  IUpdateHospitalPayload,
  ActionType,
  UpdateHospitalResponse,
} from "models/hospitals/updateHospital";
import { httpStatus } from "configs/httpStatus";
import { updateHospital } from "services/hospitals";
import {
  handleSuccessAction,
  handleErrorAction,
} from "./updateHospitalActions";

function* updateHospitalSaga({ payload }: { payload: IUpdateHospitalPayload }) {
  try {
    const response: UpdateHospitalResponse = yield call(
      updateHospital,
      payload
    );

    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccessAction(response));
    } else {
      yield put(handleErrorAction(response.message || response.error || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}

function* onUpdateHospitalWatcher() {
  yield takeLatest(ActionType.UPDATE_HOSPITAL as any, updateHospitalSaga);
}

export default [fork(onUpdateHospitalWatcher)];
