import { IResponse } from "models";

export enum ActionType {
  CANCEL_APPOINTMENT = "action/CANCEL_APPOINTMENT",
  CANCEL_APPOINTMENT_SUCCESS = "action/CANCEL_APPOINTMENT_SUCCESS",
  CANCEL_APPOINTMENT_ERROR = "action/CANCEL_APPOINTMENT_ERROR",
  CANCEL_APPOINTMENT_RESET = "action/CANCEL_APPOINTMENT_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface CancelAppointmentPayload {
  appointment_id: number;
}

export interface CancelAppointmentResponse extends IResponse {
  result: any;
}
