import {
  ActionType,
  Action,
  UpdateHospitalResponse,
} from "models/hospitals/updateHospital";
import createReducer from "../../createReducer";

export interface UpdateHospitalReducerType {
  loading: boolean;
  error?: string;
  updateHospitalResponse?: UpdateHospitalResponse;
}

const defaultState: UpdateHospitalReducerType = {
  loading: false,
  error: undefined,
  updateHospitalResponse: undefined,
};

export const updateHospitalReducer = createReducer<UpdateHospitalReducerType>(
  defaultState,
  {
    [ActionType.UPDATE_HOSPITAL](state: UpdateHospitalReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.UPDATE_HOSPITAL_ERROR](
      state: UpdateHospitalReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        updateHospitalResponse: undefined,
      };
    },

    [ActionType.UPDATE_HOSPITAL_SUCCESS](
      state: UpdateHospitalReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        updateHospitalResponse: action.payload,
      };
    },

    [ActionType.UPDATE_HOSPITALD_RESET](state: UpdateHospitalReducerType) {
      return {
        ...state,
        loading: false,
        error: null,
        updateHospitalResponse: undefined,
      };
    },
  }
);
