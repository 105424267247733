import { createApiCall, MethodType, URI } from "../Api";

import { IAppointmentsListPayload } from "models/appointments/appointmentsList";
import { IDoctorAppointmentsPayload } from "models/appointments/doctorAppointmentsList";
import { IAppointmenDetailstPayload } from "models/appointments/appointmentDetails";
import { CancelAppointmentPayload } from "models/appointments/cancelAppointment";
import { ChanceDoctorPayload } from "models/appointments/changeDoctor";

export const getAppointmentsList = (payload: IAppointmentsListPayload) => {
  return createApiCall(
    MethodType.GET,
    URI.APPOINTMENTS.APPOINTMENTS_LIST,
    payload
  );
};

export const getDoctorAppointments = (payload: IDoctorAppointmentsPayload) => {
  return createApiCall(
    MethodType.GET,
    URI.APPOINTMENTS.DOCTOR_APPOINTMENTS(payload.doctor_id),
    payload.payload
  );
};

export const getAppointmentDetails = (payload: IAppointmenDetailstPayload) => {
  return createApiCall(
    MethodType.GET,
    URI.APPOINTMENTS.APPOINTMENT_DETAILS(payload.appointment_id)
  );
};

export const updateStatus = (payload: any) => {
  return createApiCall(
    MethodType.PATCH,
    URI.APPOINTMENTS.APPOINTMENT_UPDATE_STATUS(payload.appointment_id)
  );
};

export const updateStatusConsultation = (payload: any) => {
  return createApiCall(
    MethodType.PATCH,
    URI.APPOINTMENTS.APPOINTMENT_UPDATE_STATUS_CONSULTATION(
      payload.appointment_id
    )
  );
};

export const cancelStatus = (payload: any) => {
  return createApiCall(
    MethodType.PATCH,
    URI.APPOINTMENTS.APPOINTMENT_CANCEL_STATUS(payload.appointment_id)
  );
};

export const cancelAppointment = (payload: CancelAppointmentPayload) => {
  return createApiCall(
    MethodType.DELETE,
    URI.APPOINTMENTS.CANCEL_APPOINTMENT(payload.appointment_id)
  );
};

export const changeDoctor = (payload: ChanceDoctorPayload) => {
  return createApiCall(
    MethodType.PATCH,
    URI.APPOINTMENTS.CHANGE_DOCTOR(payload.appointment_id),
    {
      doctor_id: payload.doctor_id,
    }
  );
};
