import * as React from "react";

function HospitalIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ fill: "transparent" }}
      {...props}
    >
      <g clipPath="url(#clip0_27041_6827)">
        <path
          d="M27.652 23.9121C27.424 23.6801 27.124 23.558 26.8 23.558H25.6V14.7057C25.6 14.3761 25.468 14.0708 25.252 13.8388C25.036 13.6068 24.724 13.4847 24.4 13.4847H22.756V7.221C22.756 6.89133 22.624 6.58608 22.408 6.35409C22.192 6.1221 21.88 6 21.556 6H10.756C10.432 6 10.132 6.13431 9.904 6.35409C9.676 6.57387 9.556 6.89133 9.556 7.221V13.4725H7.6C7.276 13.4725 6.976 13.6068 6.748 13.8266C6.52 14.0586 6.4 14.3639 6.4 14.6935V23.5458H5.2C4.876 23.5458 4.576 23.6801 4.348 23.8999C4.12 24.1319 4 24.4371 4 24.7668C4 25.0965 4.132 25.4017 4.348 25.6337C4.576 25.8657 4.876 25.9878 5.2 25.9878H26.8C27.124 25.9878 27.424 25.8535 27.652 25.6337C27.88 25.4139 28 25.0965 28 24.7668C28 24.4371 27.868 24.1319 27.652 23.8999V23.9121ZM20.356 15.9145H22.756H23.2V23.5458H19.6V19.1746C19.6 18.8449 19.468 18.5397 19.252 18.3077C19.036 18.0757 18.724 17.9536 18.4 17.9536H13.6C13.276 17.9536 12.976 18.0879 12.748 18.3077C12.52 18.5275 12.4 18.8449 12.4 19.1746V23.5458H8.8V15.9145H9.556H11.956V8.442H20.356V13.4725M17.2 23.558H14.8V20.4078H17.2V23.558Z"
          fill={props.fill || "#3B3B3B"}
        />
        <path
          d="M19.3 11.7508H16.984V9.40649H15.316V11.7508H13V13.4602H15.316V15.8045H16.984V13.4602H19.3V11.7508Z"
          fill={props.fill || "#3B3B3B"}
        />
      </g>
      <defs>
        <clipPath id="clip0_27041_6827">
          <rect
            width="24"
            height="20"
            fill="white"
            transform="translate(4 6)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default HospitalIcon;
