import styled from "styled-components";
import { Checkbox } from "antd";

import { Table, Button, Collapse, Modal } from "components";
import { CloseSidebarIcon } from "icons";
import { theme } from "theme/theme";

export const WapperFilter = styled.div`
  background: #ffffff;
  margin-bottom: 24px;
  .btn-previous,
  .btn-next {
    position: absolute;
    width: 60px;
    height: 30px;
    border-radius: 4px;
    border: 1px solid #2ac769;
    padding: 3px 18px;
    background-color: #ffffff;
    cursor: pointer;
    .react-datepicker__navigation-icon::before {
      top: 5px;
      border-width: 2px 2px 0 0;
      height: 10px;
      width: 10px;
      border-color: #2ac769;
    }
    .react-datepicker__navigation-icon::before {
      top: 5px;
      border-width: 2px 2px 0 0;
      height: 10px;
      width: 10px;
      border-color: #2ac769;
    }
  }
  .btn-previous {
    left: 5px;
  }
  .btn-next {
    left: auto;
    right: 5px;
  }
  .btn-previous span {
    top: -10px;
    left: -1px;
  }
  .btn-next span {
    top: -10px;
    right: -1px;
    left: auto;
  }

  .title-wrapper {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    .pTittle {
      text-align: center;
      font-weight: bold;
      font-size: 24px;
      color: #3b3b3b;
      font-weight: 500;
      line-height: 24px;
    }
    .datepicker-wrapper {
      height: 24px;
      width: 22px;
      svg {
        cursor: pointer;
      }
      .react-datepicker-wrapper {
        opacity: 0;
        position: relative;
        top: -34px;
        left: -120px;
        z-index: -1;
        /* display: none !important; */
      }
      .react-datepicker__tab-loop {
        /* position: absolute; */
      }
    }
    .today {
      padding: 9px 8px 6px 8px;
      background: #2ac769;
      color: #ffffff;
      font-size: 18px;
      font-weight: 400;
      line-height: 15px;
      margin-left: -8px;
      border-radius: 4px;
    }
  }
`;

export const TodayButton = styled.div<{ isToday: boolean }>`
  padding: 8px 8px 5px 8px;
  background: ${(props) => (props.isToday ? "#2ac769" : "#ffffff")};
  border: 1px solid #2ac769;
  color: ${(props) => (props.isToday ? "#ffffff" : "#2ac769")};
  font-size: 18px;
  font-weight: 400;
  line-height: 15px;
  margin-left: -8px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background: ${(props) => (props.isToday ? "#2ac769" : "#99e4b7")};
    color: #ffffff !important;
  }
`;

export const ContentWrapper = styled.div`
  /* margin-bottom: 24px;
  ${theme.media.laptopL} {
    margin-bottom: 32px;
  } */
`;

export const ScheduleTableStyled = styled(Table)`
  .ant-table-content {
    border-radius: 4px;
    border: 1px solid #cccccc;
  }
  .ant-table-thead > tr > th {
    padding: 8px 15px;
    color: #666666;
    font-size: 16px;
    font-weight: 700;
    line-height: 16px; /* 100% */
    background: #f2f2f2 !important;
    border-right: 1px solid #cccccc;
    border-color: #cccccc !important;
    :last-child {
      border-right: none;
    }
  }
  /* .ant-table-thead > tr > th:nth-child(5) {
    padding: 17px 23px 17px 12px;
  } */
  .ant-table-tbody > tr > td {
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    padding: 8px 16px 12px;
    background: #ffffff !important;
    border-right: 1px solid #cccccc;
    border-color: #cccccc !important;
    :last-child {
      border-right: none;
    }
    :first-child {
      border-width: 1px;
    }
  }
  .ant-table-tbody > tr > td:nth-child(2) {
    color: #2f8cae;
    text-decoration-line: underline;
    cursor: pointer;
  }
  .ant-table-tbody > tr > td:nth-child(6) {
    text-align: center;
  }
  /* .ant-table-tbody > tr > td:first-child,
  .ant-table-thead > tr > th:first-child {
    padding-left: 14px;
    padding-right: 14px;
  } */
  .ant-table-tbody
    > tr.ant-table-row:hover
    > td.ant-table-cell:last-child
    path {
    fill: ${theme.colors.textLink};
    transition: fill 0.3s ease;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #ffffff !important;
  }
  button {
    width: max-content;
  }
  .ant-table-tbody > tr > td {
    vertical-align: top;
  }
`;

export const WrapperRedirect = styled.div<{
  mobile: string;
  tablet: string;
  desktop: string;
}>`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => props.mobile};
  .bor-backicon:hover {
    cursor: pointer;
  }
  p {
    font-weight: ${theme.fontWeight.bold};
    font-size: ${theme.sizes.md};
    line-height: 23px;
    margin-right: 16px;
  }
  .bor-backicon {
    background: ${theme.colors.white};
    padding: 8px;
    border-radius: 50%;
    height: 28px;
    width: 28px;
    margin-right: 8px;
  }
  ${theme.media.tablet} {
    margin-bottom: ${(props) => props.tablet};
  }
  ${theme.media.laptop} {
    margin-bottom: ${(props) => props.desktop};
  }
`;

export const DetailsContainer = styled.div`
  border-radius: 8px;
  padding: 8px 0px 8px;
  background: #ffffff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
`;

export const TimeTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  padding: 8px 24px;
  .time {
    font-size: 16px;
    color: #3b3b3b;
    font-weight: 500;
    line-height: 24px;
  }
  .actions {
    display: flex;
    gap: 16px;
    align-items: center;
    .desc {
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
      color: #666666;
    }
    .button-block {
      display: flex;
      gap: 16px;
      align-items: center;
    }
  }
  ${theme.media.tablet} {
    .time {
      font-size: 20px;
    }
  }
  ${theme.media.desktop} {
    .time {
      font-size: 24px;
    }
  }
`;

export const CollapseStyled = styled(Collapse)`
  position: relative;
  .ant-collapse-header {
    width: 100%;
  }
  .ant-collapse-header svg {
    top: 38% !important;
    right: 10px !important;
  }
`;

export const PatientInfoBlock = styled.div`
  padding: 16px 0px 16px 16px;
  background: #f2f2f2;
  margin-top: 8px;
  .title {
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    color: #3b3b3b;
    margin-bottom: 16px;
  }
  ${theme.media.tablet} {
    padding: 16px 12px 16px 16px;
  }
  ${theme.media.laptop} {
    padding: 16px;
  }
`;

export const CollapseBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  padding: 4px 8px;
  border-radius: 4px;
  background: #ffffff;
  width: 100%;
  line-height: 22px;
  .info {
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    .item {
      color: #3b3b3b;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      span {
        font-weight: 700;
      }
    }
  }
  .show-detail {
    color: #2f8cae;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    cursor: pointer;
    margin-right: 24px;
  }
`;

export const DetailsBlock = styled.div`
  background: #ffffff;
  padding: 24px 0px 24px 24px;
  margin-right: 16px;
  display: flex;
  gap: 32px;
  min-height: 1174px;
  overflow: auto;
  ${theme.media.laptop} {
    padding: 24px;
    margin-right: 0px;
  }
`;

export const LeftContentGroup = styled.div`
  width: max-content;
  flex-direction: column;
  display: flex;
  gap: 24px;
`;

export const LeftDetailsBlock = styled.div<{ isCollapsed: boolean }>`
  width: ${(props) => (props.isCollapsed ? "0px" : "380px")};
  opacity: ${(props) => (props.isCollapsed ? 0 : 1)};
  z-index: ${(props) => (props.isCollapsed ? -1 : 0)};
  transition: all 0.2s;
`;

export const RightDetailsBlock = styled.div`
  flex: 1;
  min-width: 472px;
  ${theme.media.tablet} {
    min-width: 706px;
  }
  ${theme.media.laptop} {
  }
`;

export const LineMenu = styled.div`
  > div:not(:first-child) {
    margin-top: 24px;
  }
  /* > div:not(:first-child) {
    border-top: 1px solid #e0e0e0;
  }
  > div:last-child {
    border-bottom: 1px solid #e0e0e0;
  } */
`;

export const Line = styled.div<{ active: boolean }>`
  position: relative;
  width: 100%;
  font-size: 16px;
  line-height: 16px;
  padding: 14px 8px 13px;
  border-radius: 8px;
  background: #f2f2f2;
  font-weight: ${(props) => (props.active ? 700 : 400)};
  cursor: pointer;
  .link {
    margin-top: 16px;
    a {
      color: #2f8cae;
      font-weight: 400;
      display: block;
    }
  }
  &::after {
    content: "";
    position: absolute;
    right: 8px;
    top: calc(50% - 5px);
    border-width: 2px 2px 0 0;
    border-style: solid;
    height: 10px;
    width: 10px;
    border-color: ${(props) => (props.active ? "#3b3b3b" : "#cccccc")};
    transform: rotate(45deg);
  }
`;

export const Line2 = styled.div<{ active: boolean }>`
  position: relative;
  width: 100%;
  font-size: 16px;
  line-height: 16px;
  padding: 14px 8px 13px;
  color: #ffffff;
  border-radius: 8px;
  background: ${(props) => (props.active ? "#B7B7B7" : "#2AC769")};
  font-weight: 700;
  cursor: pointer;
  .link {
    margin-top: 16px;
    a {
      color: #2f8cae;
      font-weight: 400;
      display: block;
    }
  }
  &::after {
    content: "";
    position: absolute;
    right: 8px;
    top: calc(50% - 5px);
    border-width: 2px 2px 0 0;
    border-style: solid;
    height: 10px;
    width: 10px;
    border-color: #ffffff;
    transform: rotate(45deg);
  }
`;

export const OtherReservation = styled.div`
  width: 100%;
  margin-top: 24px;
  .title {
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    color: #666666;
    margin-bottom: 8px;
  }

  .table {
    width: 100%;
    border-radius: 4px;
    border: 1px solid ${theme.colors.border};
    background: ${theme.colors.white};
    .thead {
      border-radius: 4px 4px 0px 0px;
      display: flex;
      width: 100%;
      background-color: #f2f2f2;
      border-bottom: 1px solid ${theme.colors.border};
      div {
        padding: 6px 12px;
        font-size: 12px;
        font-weight: 700;
        line-height: 20px;
        color: #666666;
      }
      > div:first-child {
        width: calc(29% - 1px);
      }
      > div:nth-child(2) {
        width: calc(35% - 1px);
      }
      > div {
        border-right: 1px solid ${theme.colors.border};
      }
      > div:last-child {
        flex: 1;
        width: calc(35% - 1px);
        border-right: none;
      }
    }
    .tbody {
      width: 100%;
      border-radius: 0px 0px 4px 4px;
      background-color: #ffffff;
      .line {
        display: flex;
        border-bottom: 1px solid ${theme.colors.border};
        div {
          padding: 6px 12px 8px;
          font-size: 12px;
          font-weight: 500;
          line-height: 20px;
          color: #3b3b3b;
        }
        > div:first-child {
          width: calc(29% - 1px);
        }
        > div:nth-child(2) {
          width: calc(35% - 1px);
          text-decoration-line: underline;
          color: #2f8cae;
          a {
            color: #2f8cae;
          }
        }
        > div {
          border-right: 1px solid ${theme.colors.border};
        }
        > div:last-child {
          flex: 1;
          width: calc(35% - 1px);
          border-right: none;
        }
      }
      .line:last-child {
        border-bottom: none;
      }
    }
  }
`;

export const EditHistory = styled.div`
  width: 100%;
  margin-top: 24px;
  .title {
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    color: #666666;
    margin-bottom: 8px;
  }

  .table {
    width: 100%;
    border-radius: 4px;
    border: 1px solid ${theme.colors.border};
    background: ${theme.colors.white};
    .tbody {
      width: 100%;
      border-radius: 4px;
      background-color: #ffffff;
      .line {
        display: flex;
        border-bottom: 1px solid ${theme.colors.border};
        padding: 8px 7px 7px;
        font-size: 10px;
        font-weight: 400;
        line-height: 14px;
        color: #3b3b3b;
      }
      .line:last-child {
        border-bottom: none;
        padding-bottom: 8px;
      }
    }
  }
`;

export const RightContentWrapper = styled.div`
  width: 100%;
  .video-input {
    margin-bottom: 40px;
    display: flex;
    gap: 8px;
    input {
      color: #2f8cae;
    }
    div:last-child {
      flex: 1;
    }
  }
  .sent-block {
    margin-top: 16px;
    .sent-label {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: ${theme.colors.text.primary};
    }
    a {
      font-size: 16px;
      font-weight: 400;
      line-height: 30px;
      text-decoration-line: underline;
      color: #2f8cae;
      display: block;
    }
  }
  .text-area {
    > div:first-child {
      color: #3b3b3b !important;
    }
    textarea {
      box-shadow: none;
      border: none;
      background: #ffffff;
      resize: none;
      &:disabled {
        background: #ffffff;
      }
    }
  }
  .doctor-consult {
    color: #3b3b3b;
    font-size: 16px;
    line-height: 16px;

    .consult-label {
      font-weight: 700;
      margin-bottom: 16px;
    }
    .consult-content {
      line-height: 24px;
      color: #4f4f4f;
      font-weight: 400;
    }
  }
  input {
    box-shadow: none;
    border-radius: 6px;
    background: #f2f2f2;
    border: none;
    &:disabled {
      background: #f2f2f2;
    }
  }
`;

export const CollapseDetails = styled.div`
  margin-top: -12px;
  display: flex;
  gap: 8px;
  width: 100%;
  .block2 {
    width: calc(100% / 3 - 4px);
    display: flex;
    flex-direction: column;
    gap: 8px;
    .item {
      padding: 8px;
      background: #ffffff;
      border-radius: 4px;
      min-height: 126px;

      .title {
        font-size: 14px;
        line-height: 22px;
        font-weight: 700;
        margin-bottom: 8px;
        color: ${theme.colors.text.primary};
      }
      .overflow-wrapper {
        overflow: auto;
        &::-webkit-scrollbar {
          width: 16px;
        }
        &::-webkit-scrollbar-track {
          /* margin: 16px 0px 13px; */
          border-left: 4px solid ${theme.colors.white};
          border-right: 4px solid ${theme.colors.white};
          background: ${theme.colors.background};
          border-radius: 7px;
        }
        &::-webkit-scrollbar-thumb {
          background: #bdbdbd;
          border-radius: 7px;
          border-left: 4px solid ${theme.colors.white};
          border-right: 4px solid ${theme.colors.white};
        }
      }
      .line {
        display: flex;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        align-items: center;
        > div {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          > svg:first-child {
            margin-left: 2px;
          }
          > svg:not(:first-child) {
            margin-left: 4px;
          }
          > a {
            height: 16px;
          }
        }
      }
      .line-height {
        line-height: 22px;
      }
    }
    .item:last-child {
      height: 148px;
    }
  }
  .block2:nth-child(3) {
    > .item:first-child {
      height: 260px;
      .line {
        font-size: 14px;
        line-height: 28px;
        cursor: pointer;
        span {
          color: #2f8cae;
          text-decoration: underline;
        }
      }
    }
  }
  .block {
    width: calc(50% - 4px);
    display: flex;
    flex-direction: column;
    gap: 8px;
    .item {
      padding: 8px;
      background: #ffffff;
      border-radius: 4px;
      .title {
        font-size: 14px;
        line-height: 22px;
        font-weight: 700;
        margin-bottom: 8px;
        color: ${theme.colors.text.primary};
      }
      .line {
        display: flex;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        align-items: center;
        > div {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          > svg:first-child {
            margin-left: 2px;
          }
          > svg:not(:first-child) {
            margin-left: 4px;
          }
          > a {
            height: 16px;
          }
        }
      }
      .line-height {
        line-height: 22px;
      }
      .line2 {
        &:not(:last-child) {
          margin-bottom: 4px !important;
        }
      }
    }
  }
  ${theme.media.tablet} {
    .block {
    }
  }
  ${theme.media.laptop} {
    gap: 8px;
    .block {
      width: calc(50% - 8px);
      .item {
        .line2 {
          &:not(:last-child) {
            margin-bottom: 8px !important;
          }
        }
      }
    }
  }
`;

export const CreateMedRecordWrapper = styled.div`
  width: 100%;
  position: relative;
  .title,
  .record-type {
    font-size: 16px;
    color: ${theme.colors.text.primary};
    margin-bottom: 16px;
  }
  .title {
    font-weight: 700;
    line-height: 16px;
  }
  .record-type {
    font-weight: 400;
    line-height: 24px;
  }
  .no-prescription-selected {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #b7b7b7;
    margin: 8px 0px;
  }
  .treatment-time {
    display: flex;
    gap: 4px;
    align-items: center;
    color: #3b3b3b;
    font-size: 16px;
    font-weight: 400;
    margin: 4px 0px 20px;
    .label {
      line-height: 24px;
    }
    .content {
      line-height: 16px;
    }
  }
`;

export const SelectTimeWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.colors.text.primary};
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 16px;
  .select-title {
    font-weight: 400;
    line-height: 24px;
    margin-right: 2px;
  }
  .date-picker {
    margin-right: 8px;
    position: relative;
    > div:first-child {
      width: 144px;
      margin-bottom: 0px !important;
    }
    > svg {
      position: absolute;
      top: 5px;
      right: 12px;
      cursor: pointer;
    }
  }
  .limiter {
    margin: 0px 2px;
  }
`;

export const SelectClinicDoctorWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  .block {
    width: calc(50% - 8px);
  }
  ${theme.media.tablet} {
    .block {
      width: calc(100% / 3 - 32px / 3);
    }
  }
  ${theme.media.laptop} {
  }
`;

export const StatisticInputContainer = styled.div`
  margin-top: 16px;
  width: 100%;
  border-radius: 8px;
  background: #f2f2f2;
  color: ${theme.colors.text.primary};
  padding: 8px 0px 2px;
  .static-title {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding: 0px 8px;
  }
  .divider {
    margin: 4px 0px 8px;
    width: 100%;
    height: 2px;
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #ffffff;
  }
  .content {
    width: 100%;
    padding: 8px 8px 0px;
    position: relative;
    &:after {
      content: "";
      height: 1px;
      width: 16px;
      position: absolute;
      right: 9px;
      bottom: 23px;
      background: #ffffff;
      z-index: 2;
    }
    &:before {
      content: "";
      height: 1px;
      width: 15px;
      transform: rotate(90deg);
      position: absolute;
      right: 17px;
      bottom: 15px;
      background: #ffffff;
      z-index: 2;
    }
    textarea {
      min-height: 31px;
    }
    .input-group {
      display: flex;
      gap: 16px;
      align-items: center;
      .item {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        width: max-content;
        .title {
          margin-bottom: 4px;
          font-weight: 400;
        }
        .input-block {
          display: flex;
          align-items: center;
          > input {
            text-align: right;
          }
          .hyphen {
            margin: 0px 4px;
          }
        }
      }
    }
    .cloud-upload {
      margin-bottom: 6px;
      margin-top: 2px;
      border-radius: 6px;
      border: 1px dashed ${theme.colors.border};
      background: ${theme.colors.white};
      width: max-content;
      padding: 21px 15px;
      display: flex;
      align-items: center;
      flex-direction: column;
      .upload-title {
        font-size: 12px;
        font-weight: 700;
        line-height: 18px; /* 150% */
        letter-spacing: 1px;
        color: ${theme.colors.icon};
        min-width: 131px;
      }
    }
  }
`;

export const AddPrescriptionGroup = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 16px;
  gap: 16px;

  .prescription-title {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: ${theme.colors.text.primary};
  }
  .auto-complete {
    width: 170px;
    height: 30px;
    border-radius: 6px;
  }
  .search-group {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  ${theme.media.mobileXL} {
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    gap: 0px;
  }
  ${theme.media.tablet} {
    .auto-complete {
      width: 293px;
    }
  }
  ${theme.media.laptop} {
  }
`;

export const WrapperReservations = styled.div`
  position: relative;
`;

export const Link = styled.span`
  text-decoration: underline;
  color: ${theme.colors.textLink};
`;

export const VideoBanner = styled.div`
  background-color: #f2f2f2;
  border-radius: 8px;
  font-size: 16px;
  line-height: 16px;
  padding: 13px 8px;
  margin-top: 24px;
  cursor: pointer;
  font-weight: 400;
  .link {
    margin-top: 16px;
    a {
      color: #2f8cae;
      font-weight: 400;
      display: block;
      text-decoration: underline;
    }
  }
`;

export const PrescriptionTable = styled.div`
  border-radius: 6px;
  border: 1px solid #e0e0e0;
  background: #ffffff;
  margin-top: 8px;
  .line-pre:not(:last-child) {
    padding: 7px 12px 6px 8px;
    border-bottom: 1px dotted #e0e0e0;
    stroke-width: 1px;
  }

  button {
    height: 18px;
    min-width: max-content;
  }

  .line-pre {
    padding: 7px 12px 7px 8px;
    border-bottom: none;
    color: #3b3b3b;
    line-height: normal;
    font-weight: 400;
    font-size: 12px;
    display: flex;
    align-items: flex-start;

    .title-pre {
      font-weight: 500;
      font-size: 14px;
    }
    > div:nth-child(3) {
      text-align: left;
    }
    > div:last-child {
      text-align: right;
    }

    > div:nth-child(2) {
      margin-right: 8px;
      margin-left: 8px;
      min-width: max-content;
    }
    > div:nth-child(3) {
      margin-right: 8px;
      width: 120px;
    }
    > div:nth-child(4) {
      margin-right: 8px;
    }
    > div:nth-child(5) {
      margin-right: 8px;
    }

    > div:last-child {
      flex: 1;
      height: 25px;
      width: max-content;
    }
  }
  ${theme.media.mobileXL} {
    .line-pre {
      > div:nth-child(3) {
        width: 163px;
      }
    }
  }
  ${theme.media.tablet} {
    .line-pre {
      > div:nth-child(3) {
        width: 300px;
      }
    }
  }
  ${theme.media.laptop} {
    .line-pre {
      align-items: center;

      > div:nth-child(2) {
        margin-right: 16px;
        margin-left: 8px;
      }
      > div:nth-child(3) {
        margin-right: 20px;
        text-align: right;
        width: unset;
      }
      > div:nth-child(4) {
        margin-right: 20px;
      }
      > div:nth-child(5) {
        margin-right: 60px;
      }
    }
  }
`;

export const StatusFour = styled.div`
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    171.83deg,
    #a6a6a6 9.85%,
    #8e8e8e 36.13%,
    #707070 95.37%
  );
  background-clip: text;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
`;

export const WrapperBox = styled.div`
  margin-bottom: 23px;
  .label {
    font-family: Noto Sans JP;
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 8px;
  }

  .content {
    width: 100%;
    height: 224px;
    border-radius: 8px;
    border: 1px solid rgba(242, 242, 242, 1);
    padding: 0 8px;
    overflow: auto;
    resize: vertical;
    position: relative;
    &:after {
      content: "";
      height: 1px;
      width: 10px;
      position: absolute;
      right: 0px;
      bottom: 0px;
      background: #ffffff;
      z-index: 2;
    }
    &::-webkit-scrollbar {
      width: 16px;
    }
    &::-webkit-scrollbar-track {
      margin: 16px 0px 13px;
      border-left: 4px solid ${theme.colors.white};
      border-right: 4px solid ${theme.colors.white};
      background: ${theme.colors.background};
      border-radius: 7px;
    }
    &::-webkit-scrollbar-thumb {
      background: #bdbdbd;
      border-radius: 7px;
      border-left: 4px solid ${theme.colors.white};
      border-right: 4px solid ${theme.colors.white};
    }

    > div {
      border-top: 1px solid rgba(242, 242, 242, 1);
      padding: 8px 0;
    }

    > div:first-child {
      border: none;
    }
  }

  .ans {
    font-family: Noto Sans JP;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }

  .ques {
    font-family: Noto Sans JP;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(189, 189, 189, 1);
  }
`;

export const WrapperBox2 = styled.div`
  margin-bottom: 23px;
  .label {
    font-family: Noto Sans JP;
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 8px;
  }

  .content {
    width: 100%;
    height: auto;
    > div {
      padding: 8px 0px;
      background-image: linear-gradient(
        to right,
        #cccccc 40%,
        rgba(255, 255, 255, 0) 20%
      );
      background-position: top;
      background-size: 8px 1px;
      background-repeat: repeat-x;
    }

    > div:first-child {
      border: none;
      background-image: none;
    }
    > div:last-child {
      padding-bottom: 0px;
    }
  }

  .ans {
    font-family: Noto Sans JP;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #4f4f4f;
  }

  .ques {
    font-family: Noto Sans JP;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #666666;
    margin-bottom: 2px;
  }
`;

export const ModalStyled = styled(Modal)`
  .ant-modal-body {
    text-align: center;
    padding: 24px 16px;
  }
`;

export const ReservationTable = styled(Table)`
  .ant-table-content {
    border-radius: 4px;
    border: 1px solid #cccccc;
    border-bottom: none;
  }
  .ant-table-thead > tr > th {
    padding: 8px;
    color: #666666;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px; /* 100% */
    background: #f2f2f2 !important;
    border-right: 1px solid #cccccc;
    border-color: #cccccc !important;
    :last-child {
      border-right: none;
    }
  }
  /* .ant-table-thead > tr > th:nth-child(5) {
    padding: 17px 23px 17px 12px;
  } */
  .ant-table-tbody > tr > td {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    padding: 8px;
    background: #ffffff !important;
    border-right: 1px solid #cccccc;
    border-color: #cccccc !important;
    :last-child {
      border-right: none;
    }
    :first-child {
      border-width: 1px;
    }
  }
  .ant-table-tbody > tr > td:nth-child(3) {
    color: #2f8cae;
    text-decoration-line: underline;
    cursor: pointer;
  }
  /* .ant-table-tbody > tr > td:nth-child(3) {
    text-align: center;
  } */
  .ant-table-tbody > tr > td:nth-child(6) {
    text-align: left;
  }
  /* .ant-table-tbody > tr > td:first-child,
  .ant-table-thead > tr > th:first-child {
    padding-left: 14px;
    padding-right: 14px;
  } */
  .ant-table-tbody
    > tr.ant-table-row:hover
    > td.ant-table-cell:last-child
    path {
    fill: ${theme.colors.textLink};
    transition: fill 0.3s ease;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #ffffff !important;
  }
  button {
    width: max-content;
  }
  .ant-table-tbody > tr > td {
    vertical-align: top;
  }
  .ant-table-tbody > tr > td:nth-child(6) {
    vertical-align: top;
  }
  .ant-table-tbody > tr:last-child > td {
    &:first-child {
      border-bottom-left-radius: 2px;
    }
    &:last-child {
      border-bottom-right-radius: 2px;
    }
  }
  /* .ant-table-tbody > tr > td:nth-child(1) {
    width: 16.8%;
  }
  .ant-table-tbody > tr > td:nth-child(2) {
    width: calc(23.2% - 2px);
  }
  .ant-table-tbody > tr > td:nth-child(3) {
    width: 5.6%;
    min-width: 64px;
    max-width: 64px;
  }
  .ant-table-tbody > tr > td:nth-child(4) {
    width: 25.2%;
  }
  .ant-table-tbody > tr > td:nth-child(5) {
    width: 19.5%;
  }
  .ant-table-tbody > tr > td:nth-child(6) {
    width: 9.7%;
  }
  .ant-table-thead > tr > th:nth-child(1) {
    width: 16.8%;
  }
  .ant-table-thead > tr > th:nth-child(2) {
    width: calc(23.2% - 2px);
  } */
  /* .ant-table-thead > tr > th:nth-child(3) {
    width: 5.6%;
    min-width: 64px;
    max-width: 64px;
    text-align: center;
  } */
  /* .ant-table-thead > tr > th:nth-child(4) {
    width: 25.2%;
  }
  .ant-table-thead > tr > th:nth-child(5) {
    width: 19.5%;
  }
  .ant-table-thead > tr > th:nth-child(6) {
    width: 9.7%;
  } */
  .ant-table-content table {
    width: max-content !important;
  }
  .ant-table-tbody > tr > td:nth-child(9) {
    vertical-align: middle;
  }
  ${theme.media.laptop} {
    .ant-table-content table {
      width: unset !important;
    }
  }
  ${theme.media.desktop} {
    .ant-table-content table {
      width: max-content !important;
    }
  }
`;

export const OpenSidebarIcon = styled(CloseSidebarIcon)`
  cursor: pointer;
  transform: rotate(-180deg);
`;

export const PastRecordContent = styled.div`
  .title {
    font-size: 16px;
    font-weight: 700;
    line-height: 23px;
    margin-bottom: 4px;
  }
  .area {
    margin-bottom: 16px;
    width: 100%;
    display: table;

    textarea {
      display: table-row;
      border: 1px solid #f2f2f2;
      background: #fbfbfb;
      padding: 15px;
      font-weight: 400;
      border-radius: 8px;
      box-shadow: none;
    }
  }

  .drug-table {
    width: 100%;
    border-radius: 8px;
    background: #fbfbfb;
    border: 1px solid #f2f2f2;
    color: #3b3b3b;
    .drugs {
      .drug {
        padding: 8px 15px 7px 7px;
        border-bottom: 1px dotted #cccccc;
        font-weight: 400;
        .line {
          display: flex;
          gap: 24px;
          font-size: 16px;
          line-height: 23px;
          .type {
            width: 67px;
            font-weight: 500;
          }
          .name {
            width: 230px;
            div:not(:last-child) {
              margin-bottom: 6px;
            }
          }
          .amount {
            width: 74px;
            div {
              text-align: right;
            }
            div:not(:last-child) {
              margin-bottom: 6px;
            }
          }
          .frequency {
            width: 407px;
          }
          .duration {
            flex: 1;
            text-align: right;
          }
        }
        .comment {
          padding-top: 8px;
          padding-left: 91px;
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
        }
      }
      .drug:first-child {
        /* padding-top: 3px; */
      }
      .drug:last-child {
        border-bottom: none;
      }
    }
  }
`;

export const MedicalPlanContent = styled.div`
  .title-group {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 24px;
    .title {
      font-size: 16px;
      font-weight: 700;
      line-height: 16px;
    }
  }
  .area-group {
    margin-bottom: 24px;
    padding: 4px 8px 8px;
    border-radius: 8px;
    background-color: #f2f2f2;
    .label {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 4px;
    }
    .area {
      width: 100%;
      display: table;

      textarea,
      input {
        display: table-row;
        border: 1px solid #e0e0e0;
        background: #ffffff;
        padding: 7px;
        font-weight: 400;
        border-radius: 8px;
        box-shadow: none;
        min-height: 40px;
      }
    }
  }
`;

export const CancelModalStyled = styled(Modal)`
  .ant-modal-body {
    padding: 32px 21px;
  }
`;
export const WrapperContentModal = styled.div`
  font-family: Noto Sans JP;
  font-size: 22px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
`;
export const CancelModalWrapperFooter = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
  gap: 40px;
  button {
    height: 40px;
    width: 128px;
    line-height: 13px;
    padding-top: 11px;
    padding-bottom: 13px;
  }
`;
