import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AutoComplete, notification } from "antd";

import { Input, TextArea, Button, Select } from "components";
import {
  PlusIcon,
  SquareDelete,
  EditIcon,
  SendCompleteIcon,
  ConfirmFailIcon,
} from "icons";
import { localStorageHelper } from "utils";

import {
  UpdateMedicineByIDActionCreators,
  DeleteMedicineByIDActionCreators,
  SubSearchMedicineActionCreators,
  CreateMedicineByIDActionCreators,
  CreateMedicineActionCreators,
  GetMedicineListByIDActionCreators,
  ChangeDoctorActionCreators,
} from "redux/rootActions";
import { createMedicineDataResponse } from "redux/medicine/create/createStates";
import {
  changeDoctorDataResponse,
  selectChangeDoctorErrorMessage,
} from "redux/appointments/changeDoctor/changeDoctorStates";
import {
  CreateMedRecordWrapper,
  SelectClinicDoctorWrapper,
  StatisticInputContainer,
  AddPrescriptionGroup,
  PrescriptionTable,
} from "../../Reservations.style";
import CreateModal from "../createModal";
import { httpStatus } from "configs/httpStatus";

const CreateMedicalRecord = ({
  medicalRecord,
  setMedicalRecord,
  clinicalDepartmentOptions,
  medicineOptions,
  setMedicineOptions,
  medicineList,
  setMedicineList,
  medicalRecordID,
  edit,
  setEdit,
  updateMedicalRecord,
  returnTitle,
  doctorName,
  doctorOptions,
  appointmentId,
  appointmentDetails,
  setAppointmentDetails,
}: any) => {
  const dispatch = useDispatch();

  const changeDoctorError = useSelector(selectChangeDoctorErrorMessage);

  const createMedicineData = useSelector(createMedicineDataResponse);
  const changeDoctorData = useSelector(changeDoctorDataResponse);

  const [visibleCreateModal, setVisibleCreateModal] = useState<boolean>(false);
  const [medicineName, setMedicineName] = useState("");
  const [debouncedValue, setDebouncedValue] = useState<any>("");
  const [firstRender, setFirstRender] = useState("1");

  const handleNotification = (type: string, message: string | any) => {
    if (type === "success") {
      notification.success({
        placement: "bottomRight",
        message: message,
        icon: <SendCompleteIcon />,
      });
    } else {
      notification.error({
        placement: "bottomRight",
        message: message,
        icon: <ConfirmFailIcon width={53} height={53} />,
      });
    }
  };

  const handleGetMedicineList = () => {
    dispatch(
      GetMedicineListByIDActionCreators.getMedicineListByIDAction({
        id: medicalRecordID,
      })
    );
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(medicineName);
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [medicineName]);

  useEffect(() => {
    if (firstRender !== "1") {
      dispatch(
        SubSearchMedicineActionCreators.searchMedicineAction({
          name: medicineName,
        })
      );
    } else {
      setFirstRender("2");
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (
      createMedicineData &&
      createMedicineData.status === httpStatus.StatusCreated
    ) {
      handleNotification("success", createMedicineData.message);
      dispatch(CreateMedicineActionCreators.handleResetAction());
      handleGetMedicineList();
    }
  }, [createMedicineData]);

  useEffect(() => {
    if (changeDoctorData) {
      handleNotification("success", `${changeDoctorData.message}。`);
      dispatch(ChangeDoctorActionCreators.handleResetAction());
    }
  }, [changeDoctorData]);

  useEffect(() => {
    if (changeDoctorError) {
      handleNotification("error", "データが見つかりません。");
      dispatch(ChangeDoctorActionCreators.handleResetAction());
    }
  }, [changeDoctorError]);

  const onSelect = (data: any, options: any) => {
    setMedicineOptions([]);
    dispatch(
      CreateMedicineActionCreators.createMedicineAction({
        id: medicalRecordID,
        params: {
          coded_prescription_id: options.id,
        },
      })
    );
  };

  return (
    <React.Fragment>
      <CreateMedRecordWrapper>
        <div className="title">カルテ作成</div>
        <Button
          onClick={updateMedicalRecord}
          background="#2AC769"
          color="#fff"
          name="保存"
          border="none"
          bdr="6px"
          fontSize={16}
          lineHeight="16px"
          padding="13px 32px 11px"
          fontWeight={700}
          style={{
            position: "absolute",
            top: 0,
            right: 0,
          }}
        />
        <div className="record-type">
          カルテタイプ：<span>オンライン診療</span>
        </div>
        <div className="treatment-time">
          <div className="label">診療日時：</div>
          <div className="content">{returnTitle()}</div>
        </div>
        {/* <SelectTimeWrapper>
              <div className="select-title">診療日時：</div>
              <div className="date-picker">
                <DatePicker
                  locale="ja"
                  selected={startDateFilter}
                  placeholderText={"unspecified"}
                  onChange={() => console.log("change")}
                />
                <CalendarIcon onClick={(e: any) => e.stopPropagation()} />
              </div>
              <Select width="144px" top="32%" right="20px" icon={<ClockIcon />} />
              <div className="limiter">〜</div>
              <Select width="144px" top="32%" right="20px" icon={<ClockIcon />} />
            </SelectTimeWrapper> */}
        <SelectClinicDoctorWrapper>
          <div className="block">
            <Select
              fsLabel={16}
              fwLabel={400}
              lhLabel="24px"
              fs={16}
              label="診療科"
              width="100%"
              value={
                medicalRecord?.clinical_department_id
                  ? medicalRecord.clinical_department_id
                  : ""
              }
              options={clinicalDepartmentOptions}
              onChange={(value: any) => {
                setMedicalRecord((prevState: any) => ({
                  ...prevState,
                  clinical_department_id: value,
                }));
              }}
            />
          </div>
          <div className="block">
            {/* <Input
              fsLabel={16}
              fwLabel={400}
              lhLabel="24px"
              fs={14}
              fw={400}
              lh="24px"
              label="医師"
              value={doctorName}
              width={"100%"}
              padding="4px 10px 3px"
              placeholder="入力してください"
              height={31}
              disabled
            /> */}
            <Select
              fsLabel={16}
              fwLabel={400}
              lhLabel="24px"
              fs={16}
              label="医師"
              width="100%"
              value={doctorName}
              options={doctorOptions}
              onChange={(value: any) => {
                setAppointmentDetails({
                  ...appointmentDetails,
                  doctor_name: value,
                });
                if (appointmentId) {
                  dispatch(
                    ChangeDoctorActionCreators.changeDoctorAction({
                      appointment_id: appointmentId,
                      doctor_id: value,
                    })
                  );
                }
              }}
            />
          </div>
          {/* <div className="block">
                <Input
                  fsLabel={16}
                  fwLabel={400}
                  lhLabel="24px"
                  fs={14}
                  lh="24px"
                  label="看護師"
                  value=""
                  width={"100%"}
                  padding="4px 10px 3px"
                  placeholder="入力してください"
                  height={31}
                />
              </div> */}
        </SelectClinicDoctorWrapper>
        <StatisticInputContainer>
          <div className="static-title">S</div>
          <div className="content">
            <TextArea
              fs={16}
              value={medicalRecord?.subjective ? medicalRecord.subjective : ""}
              width={"100%"}
              fw={400}
              height="250px"
              padding="8px"
              bdr="8px"
              onChange={(e: any) =>
                setMedicalRecord((prevState: any) => ({
                  ...prevState,
                  subjective: e.target.value,
                }))
              }
            />
          </div>
        </StatisticInputContainer>
        <StatisticInputContainer>
          <div className="static-title">O</div>
          <div className="divider"></div>
          <div className="content" style={{ height: "max-content" }}>
            <div className="input-group">
              <div className="item">
                <div className="title">体温（℃）</div>
                <div className="input-block">
                  <Input
                    fs={16}
                    value={
                      medicalRecord?.objective_temperature
                        ? medicalRecord.objective_temperature
                        : ""
                    }
                    width={"80px"}
                    padding="4px 8px 3px"
                    height={31}
                    fw={400}
                    onChange={(e: any) =>
                      setMedicalRecord((prevState: any) => ({
                        ...prevState,
                        objective_temperature: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
              <div className="item">
                <div className="title">血圧 (mmHg)</div>
                <div className="input-block">
                  <Input
                    fs={16}
                    value={
                      medicalRecord?.objective_sbp
                        ? medicalRecord.objective_sbp
                        : ""
                    }
                    width={"69px"}
                    padding="4px 8px 3px"
                    height={31}
                    fw={400}
                    onChange={(e: any) =>
                      setMedicalRecord((prevState: any) => ({
                        ...prevState,
                        objective_sbp: e.target.value,
                      }))
                    }
                  />
                  <div className="hyphen">-</div>
                  <Input
                    fs={16}
                    value={
                      medicalRecord?.objective_dbp
                        ? medicalRecord.objective_dbp
                        : ""
                    }
                    width={"69px"}
                    padding="4px 8px 3px"
                    height={31}
                    fw={400}
                    onChange={(e: any) =>
                      setMedicalRecord((prevState: any) => ({
                        ...prevState,
                        objective_dbp: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
              <div className="item">
                <div className="title">脈拍 (bpm)</div>
                <div className="input-block">
                  <Input
                    fs={16}
                    value={
                      medicalRecord?.objective_bpm
                        ? medicalRecord.objective_bpm
                        : ""
                    }
                    width={"82px"}
                    padding="4px 8px 3px"
                    fw={400}
                    height={31}
                    onChange={(e: any) =>
                      setMedicalRecord((prevState: any) => ({
                        ...prevState,
                        objective_bpm: e.target.value,
                        // objective_bpm: parseFloat(e.target.value),
                      }))
                    }
                  />
                </div>
              </div>
              <div className="item">
                <div className="title">SpO2 (%)</div>
                <div className="input-block">
                  <Input
                    fs={16}
                    value={
                      medicalRecord?.objective_spo2
                        ? medicalRecord.objective_spo2
                        : ""
                    }
                    width={"69px"}
                    padding="4px 8px 3px"
                    fw={400}
                    height={31}
                    onChange={(e: any) =>
                      setMedicalRecord((prevState: any) => ({
                        ...prevState,
                        objective_spo2: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
            </div>
            <TextArea
              label="内容"
              fsLabel={16}
              fwLabel={400}
              lhLabel="24px"
              fs={16}
              fw={400}
              value={medicalRecord?.objective ? medicalRecord.objective : ""}
              width={"100%"}
              marginForm="8px 0px 0px"
              height="250px"
              padding="8px"
              onChange={(e: any) =>
                setMedicalRecord((prevState: any) => ({
                  ...prevState,
                  objective: e.target.value,
                }))
              }
            />
            {/* <div className="cloud-upload">
              <UploadCloud />
              <div className="upload-title" style={{ textAlign: "center" }}>
                画像アップロード
              </div>
            </div> */}
          </div>
        </StatisticInputContainer>
        <StatisticInputContainer>
          <div className="static-title">A</div>
          <div className="divider"></div>
          <div className="content">
            <TextArea
              fs={16}
              value={medicalRecord?.assessment ? medicalRecord.assessment : ""}
              width={"100%"}
              height="250px"
              fw={400}
              padding="8px"
              bdr="8px"
              onChange={(e: any) =>
                setMedicalRecord((prevState: any) => ({
                  ...prevState,
                  assessment: e.target.value,
                }))
              }
            />
          </div>
        </StatisticInputContainer>
        <StatisticInputContainer>
          <div className="static-title">P</div>
          <div className="divider"></div>
          <div className="content">
            <TextArea
              fs={16}
              value={medicalRecord?.plan ? medicalRecord.plan : ""}
              width={"100%"}
              height="250px"
              padding="8px"
              fw={400}
              bdr="8px"
              onChange={(e: any) =>
                setMedicalRecord((prevState: any) => ({
                  ...prevState,
                  plan: e.target.value,
                }))
              }
            />
          </div>
        </StatisticInputContainer>
        {/* <StatisticInputContainer>
          <div className="static-title">事務連絡</div>
          <div className="divider"></div>
          <div className="content">
            <TextArea
              fs={16}
              value={medicalRecord?.comment ? medicalRecord.comment : ""}
              width={"100%"}
              height="250px"
              padding="8px"
              fw={400}
              bdr="8px"
              onChange={(e: any) =>
                setMedicalRecord((prevState: any) => ({
                  ...prevState,
                  comment: e.target.value,
                }))
              }
            />
          </div>
        </StatisticInputContainer> */}

        <div
          style={{
            padding: 8,
            background: "rgba(242, 242, 242, 1)",
            borderRadius: 8,
            marginTop: 16,
          }}
        >
          <AddPrescriptionGroup>
            <div className="search-group">
              <div style={{ fontWeight: 500, lineHeight: "21px" }}>
                約束処方等：
              </div>
              <AutoComplete
                className="auto-complete"
                options={medicineOptions}
                onSelect={onSelect}
                onSearch={(text: any) => {
                  if (!text) {
                    dispatch(
                      SubSearchMedicineActionCreators.searchMedicineAction({
                        name: medicineName,
                      })
                    );
                  }
                  setMedicineName(text);
                }}
              />
              <Button
                // onClick={() => setVisibleCreateModal(true)}
                background="#2AC769"
                color="#fff"
                name="追加"
                border="1px solid #1AB759"
                bdr="4px"
                fontSize={16}
                lineHeight="16px"
                padding="6px 11px"
                fontWeight={500}
              />
            </div>
            <Button
              onClick={() => setVisibleCreateModal(true)}
              background="#FFFFFF"
              color="#1AB759"
              name={"処方個別選択"}
              border="1px solid #1AB759"
              bdr="4px"
              fontSize={14}
              lineHeight="22px"
              padding="4px 6px 2px 4px"
              fontWeight={500}
              icon={<PlusIcon fill="#1AB759" />}
            />
          </AddPrescriptionGroup>

          <div
            className="prescription-title"
            style={{ fontSize: 14, fontWeight: 500, lineHeight: "22px" }}
          >
            選択した処方
          </div>
          {medicineList.length > 0 ? (
            <PrescriptionTable>
              {medicineList.map((i: any, idx: any) => {
                return (
                  <div className="line-pre" key={idx}>
                    {edit[i.id] ? (
                      <Button
                        onClick={() => {
                          let prescription = {};
                          const banKey = ["usage", "subID"];
                          for (const property in i) {
                            if (!banKey.includes(property)) {
                              if (property === "detail") {
                                for (const property2 in i.detail) {
                                  if (property2 === "dispensing_instructions") {
                                    prescription = {
                                      ...prescription,
                                      dispensing_instruction:
                                        i.detail[property2].code,
                                    };
                                  } else {
                                    prescription = {
                                      ...prescription,
                                      [property2]: i.detail[property2].code,
                                    };
                                  }
                                }
                              } else if (property === "id") {
                                if (typeof i[property] === "string") {
                                  prescription = {
                                    ...prescription,
                                    medicine_id: i["subID"],
                                  };
                                } else {
                                  prescription = {
                                    ...prescription,
                                    medicine_id: i[property],
                                  };
                                }
                              } else if (property === "supply" && i[property]) {
                                const strLength = i[property].length;
                                const splValue = i[property].slice(
                                  0,
                                  strLength - 2
                                );
                                const splType = i[property].slice(-2);
                                const splTypeObj: any = {
                                  日分: 1,
                                  回分: 2,
                                  総量: 3,
                                };

                                prescription = {
                                  ...prescription,
                                  supply_type_id: splTypeObj[splType],
                                  supply_value: Number(splValue),
                                };
                              } else {
                                prescription = {
                                  ...prescription,
                                  [property]: i[property],
                                };
                              }
                            }
                          }

                          if (typeof i?.id === "string") {
                            dispatch(
                              CreateMedicineByIDActionCreators.createMedicineByIDAction(
                                {
                                  id: medicalRecordID,
                                  params: { prescription },
                                }
                              )
                            );
                          } else {
                            const strLength = i["supply"].length;
                            const splValue = i["supply"].slice(
                              0,
                              strLength - 2
                            );

                            dispatch(
                              UpdateMedicineByIDActionCreators.updateMedicineByIDAction(
                                {
                                  id: i.id,
                                  params: {
                                    prescription: {
                                      amount: i.amount,
                                      supply_value: Number(splValue),
                                      amount_type_id: i.amount_type_id,
                                    },
                                  },
                                }
                              )
                            );
                          }
                        }}
                        background="#2AC769"
                        color="#fff"
                        name="保存"
                        border="none"
                        bdr="6px"
                        fontSize={11}
                        lineHeight="16px"
                        padding="2px 3px"
                        fontWeight={700}
                      />
                    ) : (
                      <div>
                        <EditIcon
                          fill="#3B3B3B"
                          onClick={() =>
                            setEdit((prevState: any) => ({
                              ...prevState,
                              [i.id]: true,
                            }))
                          }
                        />
                      </div>
                    )}
                    <div className="title-pre">{i.basic_dosage}</div>
                    <div>{i.medicine_name}</div>
                    <div>
                      {edit[i.id] ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 3,
                          }}
                        >
                          <Input
                            style={{ textAlign: "center" }}
                            fs={12}
                            height={23}
                            width={40}
                            padding="0"
                            type="number"
                            value={i.amount}
                            onChange={(e: any) =>
                              setMedicineList(
                                medicineList.map((item: any) => {
                                  if (i.id === item.id) {
                                    return {
                                      ...item,
                                      amount: e.target.value,
                                    };
                                  }
                                  return item;
                                })
                              )
                            }
                          />
                          {i.medicine_unit}
                          {i.amount_type && `/${i.amount_type}`}
                        </div>
                      ) : (
                        <div>
                          {i.amount}
                          {i.medicine_unit}
                          {i.amount_type && `/${i.amount_type}`}
                        </div>
                      )}
                    </div>
                    <div>{i.usage}</div>
                    <div>
                      {edit[i.id] ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 3,
                          }}
                        >
                          <Input
                            fs={12}
                            height={23}
                            width={40}
                            value={i.supply.slice(0, -2)}
                            padding="2px 12px 1px"
                            type="number"
                            onChange={(e: any) =>
                              setMedicineList(
                                medicineList.map((item: any) => {
                                  if (i.id === item.id) {
                                    const splType = item.supply.slice(-2);
                                    return {
                                      ...item,
                                      supply: e.target.value + splType,
                                    };
                                  }
                                  return item;
                                })
                              )
                            }
                          />
                          {i.supply.slice(-2)}
                        </div>
                      ) : (
                        <div>{i.supply}</div>
                      )}
                    </div>
                    <div>
                      <SquareDelete
                        onClick={() => {
                          if (typeof i?.id === "string") {
                            setMedicineList(
                              medicineList.filter(
                                (item: any) => item.id !== i.id
                              )
                            );
                          } else {
                            dispatch(
                              DeleteMedicineByIDActionCreators.deleteMedicineByIDAction(
                                { id: i.id }
                              )
                            );
                          }
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </PrescriptionTable>
          ) : (
            <div className="no-prescription-selected">
              処方箋が選択されていません。
            </div>
          )}
        </div>
      </CreateMedRecordWrapper>
      {visibleCreateModal && (
        <CreateModal
          visible={visibleCreateModal}
          handleCancel={() => setVisibleCreateModal(false)}
          handleSubmitOK={() => console.log("submmited")}
          medicalRecord={medicalRecord}
        />
      )}
    </React.Fragment>
  );
};

export default CreateMedicalRecord;
