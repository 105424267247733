import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  IDeleteHospitalPayload,
  ActionType,
  DeleteHospitalResponse,
} from "models/hospitals/deleteHospital";
import { httpStatus } from "configs/httpStatus";
import { deleteHospital } from "services/hospitals";
import {
  handleSuccessAction,
  handleErrorAction,
} from "./deleteHospitalActions";

function* deleteHospitalSaga({ payload }: { payload: IDeleteHospitalPayload }) {
  try {
    const response: DeleteHospitalResponse = yield call(
      deleteHospital,
      payload
    );

    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccessAction(response));
    } else {
      yield put(handleErrorAction(response.message || response.error || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}

function* onDeleteHospitalWatcher() {
  yield takeLatest(ActionType.DELETE_HOSPITAL as any, deleteHospitalSaga);
}

export default [fork(onDeleteHospitalWatcher)];
