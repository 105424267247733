import React from "react";
import styled from "styled-components";

interface IButton {
  name?: string | React.ReactNode;
  icon?: React.ReactNode;
  onClick?: (e?: any) => void;
  background?: string;
  color?: string;
  fontSize?: number;
  fontWeight?: number;
  lineHeight?: string;
  padding?: string;
  width?: string;
  bdr?: string; // border radius
  border?: string;
  margin?: string;
  type?: "button" | "submit" | "reset" | undefined;
  disabled?: boolean;
  className?: any;
  style?: React.CSSProperties;
}

const ButtonStyled = styled.button<IButton>(
  ({
    theme,
    background,
    color,
    fontSize,
    fontWeight,
    lineHeight,
    padding,
    width,
    bdr,
    border,
    margin,
  }) => ({
    position: "relative",
    color: color,
    background: background,
    fontSize: fontSize || theme.sizes.xs,
    fontWeight: fontWeight || 500,
    lineHeight: lineHeight,
    padding: padding,
    borderRadius: bdr || 6,
    border: border,
    width: width,
    margin: margin,
    textTransform: "capitalize",
    "&:hover": {
      cursor: "pointer",
    },
    "&:disabled": {
      background: theme.colors.border,
      cursor: "no-drop",
    },
    "&:focus": {
      border: border || "none",
    },
  })
);

const Button: React.FC<IButton> = ({ name, icon, ...rest }) => {
  return (
    <ButtonStyled {...rest}>
      {icon && <span style={{ marginRight: 5 }}>{icon}</span>}
      {name}
    </ButtonStyled>
  );
};

export default Button;
