import { put, takeLatest, fork, call } from "redux-saga/effects";

import {
  ActionType,
  ChanceDoctorPayload,
  ChanceDoctorResponse,
} from "models/appointments/changeDoctor";
import { httpStatus } from "configs/httpStatus";
import { changeDoctor } from "services/appointments";

import { handleSuccessAction, handleErrorAction } from "./changeDoctorActions";

function* changeDoctorSaga({ payload }: { payload: ChanceDoctorPayload }) {
  try {
    const response: ChanceDoctorResponse = yield call(changeDoctor, payload);
    if (response.code === httpStatus.StatusNoContent) {
      yield put(handleSuccessAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onChangeDoctorSagaWatcher() {
  yield takeLatest(ActionType.CHANGE_DOCTOR as any, changeDoctorSaga);
}

export default [fork(onChangeDoctorSagaWatcher)];
