import { all } from "redux-saga/effects";
import authenticationSagas from "redux/authentication/authenticationSaga";
import doctorsListSaga from "redux/doctor/doctorsList/doctorsListSaga";
import monthSchedulesSaga from "redux/schedules/monthSchedules/monthSchedulesSaga";
import weekSchedulesSaga from "redux/schedules/weekSchedules/weekSchedulesSaga";
import appointmentsListSaga from "redux/appointments/appointmentsList/appointmentsListSaga";
import doctorAppointmentsSaga from "redux/appointments/doctorAppointments/doctorAppointmentsSaga";
import appointmentDetailsSaga from "redux/appointments/appointmentDetails/appointmentDetailsSaga";
import deleteDoctorSaga from "redux/doctor/deleteDoctor/deleteDoctorSaga";
import createDoctorSaga from "redux/doctor/createDoctor/createDoctorSaga";
import postDoctorSchedulesSaga from "redux/schedules/postDoctorSchedules/postDoctorSchedulesSaga";
import deleteScheduleSaga from "redux/schedules/deleteSchedule/deleteScheduleSaga";
import createBulkSchedulesSaga from "redux/schedules/createBulkSchedules/createBulkSchedulesSaga";
import monthAppointmentsSaga from "redux/appointments/monthAppointments/monthAppointmentsSaga";
import editDoctorSaga from "redux/doctor/editDoctor/editDoctorSaga";
import searchMedicineSaga from "redux/medicine/search/searchSaga";
import createMedicineSaga from "redux/medicine/create/createSaga";
import getMedicineListSaga from "redux/medicine/list/listSaga";
import getMedicineListByIDSaga from "redux/medicine/listByID/listByIDSaga";
import updateMedicineByIDSaga from "redux/medicine/updateByID/updateByIDSaga";
import deleteMedicineByIDSaga from "redux/medicine/deleteByID/deleteByIDSaga";
import subSearchMedicineSaga from "redux/medicine/subSearch/subSearchSaga";
import createMedicineByIDSaga from "redux/medicine/createByID/createByIDSaga";
import medicalRecordSaga from "redux/medicalRecords/medicalRecord/medicalRecordSaga";
import createMedicalRecordSaga from "redux/medicalRecords/create/createRecordSaga";
import updateMedicalRecordSaga from "redux/medicalRecords/update/updateRecordSaga";
import clinicalDepartmentListSaga from "redux/medicalRecords/clinicalDepartmentList/clinicalDepartmentListSaga";
import versionHistorySaga from "redux/medicalRecords/versionHistory/versionHistorySaga";
import updateStatusConsultationAppointmentSaga from "redux/appointments/updateStatusConsultation/updateStatusConsultationSaga";
import cancelStatusAppointmentSaga from "redux/appointments/cancelStatus/cancelStatusSaga";
import updateStatusAppointmentSaga from "redux/appointments/updateStatus/updateStatusSaga";
import medicalPlanSaga from "redux/medicalPlans/medicalPlan/medicalPlanSaga";
import notifyMedicalPlanSaga from "redux/medicalPlans/notifyMedicalPlan/notifyMedicalPlanSaga";
import createMedicalPlanSaga from "redux/medicalPlans/createMedicalPlan/createMedicalPlanSaga";
import editMedicalPlanSaga from "redux/medicalPlans/editMedicalPlan/editMedicalPlanSaga";
import cancelAppointmentSaga from "redux/appointments/cancelAppointment/cancelAppointmentSaga";
import hospitalListSaga from "redux/hospitals/hospitalsList/hospitalsListSaga";
import changeDoctorSaga from "redux/appointments/changeDoctor/changeDoctorSaga";
import createHospitalSaga from "redux/hospitals/createHospital/createHospitalSaga";
import updateHospitalSaga from "redux/hospitals/updateHospital/updateHospitalSaga";
import agreeSuperCitiesSaga from "redux/hospitals/agreeSuperCities/agreeSuperCitiesSaga";
import agreeHospitalsSaga from "redux/hospitals/agreeHospitals/agreeHospitalsSaga";
import deleteHospitalSaga from "redux/hospitals/deleteHospital/deleteHospitalSaga";

export default function* startForman() {
  yield all([
    ...authenticationSagas,
    ...doctorsListSaga,
    ...monthSchedulesSaga,
    ...weekSchedulesSaga,
    ...appointmentsListSaga,
    ...doctorAppointmentsSaga,
    ...appointmentDetailsSaga,
    ...deleteDoctorSaga,
    ...createDoctorSaga,
    ...postDoctorSchedulesSaga,
    ...deleteScheduleSaga,
    ...createBulkSchedulesSaga,
    ...monthAppointmentsSaga,
    ...editDoctorSaga,
    ...searchMedicineSaga,
    ...createMedicineSaga,
    ...getMedicineListSaga,
    ...getMedicineListByIDSaga,
    ...updateMedicineByIDSaga,
    ...deleteMedicineByIDSaga,
    ...subSearchMedicineSaga,
    ...createMedicineByIDSaga,
    ...medicalRecordSaga,
    ...createMedicalRecordSaga,
    ...updateMedicalRecordSaga,
    ...clinicalDepartmentListSaga,
    ...versionHistorySaga,
    ...updateStatusConsultationAppointmentSaga,
    ...cancelStatusAppointmentSaga,
    ...updateStatusAppointmentSaga,
    ...medicalPlanSaga,
    ...notifyMedicalPlanSaga,
    ...createMedicalPlanSaga,
    ...editMedicalPlanSaga,
    ...cancelAppointmentSaga,
    ...hospitalListSaga,
    ...changeDoctorSaga,
    ...createHospitalSaga,
    ...updateHospitalSaga,
    ...agreeSuperCitiesSaga,
    ...agreeHospitalsSaga,
    ...deleteHospitalSaga,
  ]);
}
