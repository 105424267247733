import {
  Action,
  ActionType,
  CancelAppointmentResponse,
} from "models/appointments/cancelAppointment";
import createReducer from "../../createReducer";

export interface CancelAppointmentReducerType {
  loading: boolean;
  error?: string;
  cancelAppointmentResponse?: CancelAppointmentResponse;
}

const defaultState: CancelAppointmentReducerType = {
  loading: false,
  error: undefined,
  cancelAppointmentResponse: undefined,
};

export const cancelAppointmentReducer =
  createReducer<CancelAppointmentReducerType>(defaultState, {
    [ActionType.CANCEL_APPOINTMENT](state: CancelAppointmentReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.CANCEL_APPOINTMENT_ERROR](
      state: CancelAppointmentReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        cancelAppointmentResponse: undefined,
      };
    },

    [ActionType.CANCEL_APPOINTMENT_SUCCESS](
      state: CancelAppointmentReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        cancelAppointmentResponse: action.payload,
      };
    },

    [ActionType.CANCEL_APPOINTMENT_RESET](state: CancelAppointmentReducerType) {
      return {
        ...state,
        loading: false,
        error: null,
        cancelAppointmentResponse: undefined,
      };
    },
  });
