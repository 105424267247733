import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  ICreateHospitalPayload,
  ActionType,
  CreateHospitalResponse,
} from "models/hospitals/createHospital";
import { httpStatus } from "configs/httpStatus";
import { createHospital } from "services/hospitals";
import {
  handleSuccessAction,
  handleErrorAction,
} from "./createHospitalActions";

function* createHospitalSaga({ payload }: { payload: ICreateHospitalPayload }) {
  try {
    const response: CreateHospitalResponse = yield call(
      createHospital,
      payload
    );

    if (response.status === httpStatus.StatusCreated) {
      yield put(handleSuccessAction(response));
    } else {
      yield put(handleErrorAction(response.message || response.error || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}

function* onCreateHospitalWatcher() {
  yield takeLatest(ActionType.CREATE_HOSPITAL as any, createHospitalSaga);
}

export default [fork(onCreateHospitalWatcher)];
