import React, { useCallback, useEffect, useLayoutEffect } from "react";
import { Route, useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { notification } from "antd";

import { getDefaultScreen, isLoggedIn, localStorageHelper } from "utils";
import { IRoute } from "routes/configs";

export interface IRestrictedRouteProps extends IRoute {
  isPrivate?: boolean;
}

const RestrictedRoute: React.FC<IRestrictedRouteProps> = ({
  children,
  restricted = false,
  isPrivate,
  layout: Layout,
  ...props
}) => {
  const history = useHistory();
  const location = useLocation();
  const accountRole = localStorageHelper.getItem("role");

  useEffect(() => {
    notification.destroy();
  }, [location]);

  const redirect = useCallback(() => {
    const convertPathname = "/" + window.location.href.split("/")[3];
    const currentPathName = window.location.pathname;
    const publicURL = ["/login", "/forgot-password"];
    const privateRoutes = [
      "/schedules",
      "/reservations",
      "/profile",
      "/hospital-management",
      "/reservation-details",
    ];

    if (isPrivate && !isLoggedIn()) {
      if (publicURL.includes(currentPathName)) {
        history.replace(convertPathname);
      } else {
        history.replace("/login");
      }
    }

    if (restricted && isLoggedIn()) {
      if (
        currentPathName === "/" ||
        publicURL.includes(currentPathName) ||
        (!privateRoutes.includes(currentPathName) &&
          !currentPathName.startsWith("/reservation-details"))
      ) {
        history.replace("/schedules");
      }
      // else {
      //   history.replace(currentPathName);
      // }
    }
  }, [isPrivate, history, restricted]);

  useLayoutEffect(() => {
    redirect();
  }, [redirect]);

  const Component = Layout ? <Layout>{children}</Layout> : children;

  return <Route {...props}>{Component}</Route>;
};

export default RestrictedRoute;
