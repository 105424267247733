import { IResponse } from "models";

export enum ActionType {
  UPDATE_HOSPITAL = "action/UPDATE_HOSPITAL",
  UPDATE_HOSPITAL_SUCCESS = "action/UPDATE_HOSPITAL_SUCCESS",
  UPDATE_HOSPITAL_ERROR = "action/UPDATE_HOSPITAL_ERROR",
  UPDATE_HOSPITALD_RESET = "action/UPDATE_HOSPITALD_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IUpdateHospital {
  agree_repo_hospital_id: number;
  agree_repo_super_city_id: number;
  mynumber_url: string;
  prefecture_code: string;
  point_code: string;
  hospital_code: string;
  director_name: string;
  urgent_hospital_name: string;
  email: string;
  password?: string;
  stripe_connect_id: string;
}

export interface IUpdateHospitalPayload {
  hospital_id: number;
  hospital: IUpdateHospital;
}

export interface UpdateHospitalResponse extends IResponse {
  result: any;
}
