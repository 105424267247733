import {
  Action,
  ActionType,
  AgreeHospitalsListResponse,
} from "models/hospitals/agreeHospitalsList";
import createReducer from "../../createReducer";

export interface AgreeHospitalsReducerType {
  loading: boolean;
  error?: string;
  agreeHospitalsResponse?: AgreeHospitalsListResponse;
}

const defaultState: AgreeHospitalsReducerType = {
  loading: false,
  error: undefined,
  agreeHospitalsResponse: undefined,
};

export const agreeHospitalsReducer = createReducer<AgreeHospitalsReducerType>(
  defaultState,
  {
    [ActionType.AGREE_HOSPITALS_LIST](state: AgreeHospitalsReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.AGREE_HOSPITALS_LIST_ERROR](
      state: AgreeHospitalsReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        agreeHospitalsResponse: undefined,
      };
    },

    [ActionType.AGREE_HOSPITALS_LIST_SUCCESS](
      state: AgreeHospitalsReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        agreeHospitalsResponse: action.payload,
      };
    },

    [ActionType.AGREE_HOSPITALS_LIST_RESET](state: AgreeHospitalsReducerType) {
      return {
        ...state,
        loading: false,
        error: null,
        agreeHospitalsResponse: undefined,
      };
    },
  }
);
