import React, { Fragment, useEffect, useState } from "react";
import { Radio, RadioChangeEvent, AutoComplete, notification } from "antd";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  LeftArrow,
  SearchIcon,
  ReloadIcon,
  DeleteIcon2,
  PlusIcon,
  MinusIcon,
  SendCompleteIcon,
  ConfirmFailIcon,
} from "icons";
import { Button, Checkbox } from "components";
import { uuid } from "utils";
import { httpStatus } from "configs/httpStatus";
import {
  CreateMedicineByIDActionCreators,
  SearchMedicineActionCreators,
  UpdateMedicineByIDActionCreators,
  GetMedicineListActionCreators,
} from "redux/rootActions";
import { searchMedicineDataResponse } from "redux/medicine/search/searchStates";
import { medicineListDataResponse } from "redux/medicine/list/listStates";

import {
  ModalStyled,
  ContentWrapper,
  RightBlockContent,
  LeftBlockContent,
  SearchBlock,
  CheckboxStyled,
  SelectedDrugBlock,
  InputBlock,
  AudioWrapper,
  TakeMedWrapper,
  ConfirmButtonBlock,
  Label,
  Text,
  Flex,
  WrapperSelect,
  WrapperSelectWithTextCenter,
  WrapperDosage,
  GroupButtons,
  SelectedBodyPartsWrapper,
  NotClickSection,
  WrapperErrorMessage,
  ErrorMessage,
  WrapperError,
  AudioWrapperIndividual,
  WrapperSelectStyled,
  TextRadio,
} from "./CreateModal.style";
import {
  commonTimeOptions,
  dailyDosageFrequencyOptions,
  detailDosage,
  eightEventNameOptions,
  eleventEventNameOptions,
  eventCategoryOptions,
  firstEventNameOptions,
  fiveEventNameOptions,
  fourEventNameOptions,
  nightEventNameOptions,
  secondEventNameOptions,
  sevenEventNameOptions,
  sixEventNameOptions,
  tenEventNameOptions,
  thirdEventNameOptions,
  timingMealsOptions,
  twelveEventNameOptions,
  associatedBodyPartsOptions,
  detailDosage2,
  mealTiming,
  mealTimingJa2,
  mealTiming2,
  mealTimingJa,
  dispensingInstructionOptions,
  supplyTypeOptions,
  additionalInfoOptions,
  additionalInfo2Options,
  basicDosageOptions,
  extraOptions,
} from "./const";
import Input from "../input";
import Select from "../select2";

export interface IModal {
  visible?: boolean;
  handleCancel: () => void;
  handleSubmitOK?: () => void;
  medicalRecord: any;
}

interface IMedicine {
  value: string;
  id?: number;
  unitName?: string;
  unitID?: number;
}

const examObj: any = {
  A: 10,
  B: 11,
  C: 12,
};

const CreateModal = ({
  visible,
  handleCancel,
  handleSubmitOK,
  medicalRecord,
}: IModal) => {
  const dispatch = useDispatch();
  const searchMedicineData = useSelector(searchMedicineDataResponse);
  const medicineListData = useSelector(medicineListDataResponse);

  const [medicineName, setMedicineName] = useState("");
  const [debouncedValue, setDebouncedValue] = useState<any>("");
  const [firstRender, setFirstRender] = useState("1");
  const [medicineOptions, setMedicineOptions] = useState<IMedicine[]>([]);
  const [attachPartInformation, setAttachPartInformation] = useState<any>([]);
  const [medicineList, setMedicineList] = useState([]);
  const [dateList, setDateList] = useState([]);
  const [medicineInfo, setMedicineInfo] = useState<any>({});
  const [customValueOptions, setCustomValueOptions] = useState<any>(
    dailyDosageFrequencyOptions.map((i: any) => {
      if (i.id <= 2) {
        return {
          ...i,
          disabled: true,
        };
      }
      return i;
    })
  );
  const [bigNumberOptions, setBigNumberOptions] = useState<any>(
    dailyDosageFrequencyOptions.map((i: any) => {
      if (i.id <= 3) {
        return {
          ...i,
          disabled: true,
        };
      }
      return i;
    })
  );

  const [timeMealsRadioList, setTimeMealsRadioList] =
    useState(timingMealsOptions);
  const [dispensingInstructionRadioList, setDispensingInstructionRadioList] =
    useState(dispensingInstructionOptions);
  const [detailDosageRadioList, setDetailDosageRadioList] =
    useState(detailDosage);
  const [supplyTypeRadioList, setSupplyTypeRadioList] =
    useState(supplyTypeOptions);
  const [additionalInfoRadioList, setAdditionalInfoRadioList] = useState(
    additionalInfoOptions
  );
  const [additionalInfo2RadioList, setAdditionalInfo2RadioList] = useState(
    additionalInfo2Options
  );
  const [basicDosageRadioList, setBasicDosageRadioList] =
    useState(basicDosageOptions);
  const [extraRadioList, setExtraRadioList] = useState(extraOptions);
  const [unitOptions, setUnitOptions] = useState<any>([]);

  useEffect(() => {
    if (medicineListData && medicineListData.status === httpStatus.StatusOK) {
      const newDateList: any = [];
      medicineListData.result.forEach((i: any) => {
        if (!newDateList.includes(i.created_at)) {
          newDateList.push(i.created_at);
        }
      });

      const newArr = newDateList.map((d: any) => {
        return medicineListData.result.filter((e: any) => e.created_at === d);
      });

      setDateList(newDateList);
      setMedicineList(newArr);
    }
  }, [medicineListData]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(medicineName);
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [medicineName]);

  useEffect(() => {
    if (firstRender !== "1") {
      dispatch(
        SearchMedicineActionCreators.searchMedicineAction({
          name: medicineName,
        })
      );
    } else {
      setFirstRender("2");
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (
      searchMedicineData &&
      searchMedicineData.status === httpStatus.StatusOK
    ) {
      setMedicineOptions(
        searchMedicineData.result.map((i) => {
          return {
            value: i.name,
            unitID: i.medicine_unit_id,
            unitName: i.medicine_unit_name,
            id: i.id,
          };
        })
      );
    } else {
      setMedicineOptions([]);
    }
  }, [searchMedicineData]);

  useEffect(() => {
    dispatch(GetMedicineListActionCreators.getMedicineListAction());
  }, []);

  const handleOnChangeAmount = (e: any) => {
    setMedicineInfo((prevState: any) => ({
      ...prevState,
      amount: e.target.value,
    }));
  };

  const handleOnchangeDosageInterval = (value: string | number) => {
    const objFound = commonTimeOptions.find((i: any) => i.value === value);
    if (objFound?.id === medicineInfo?.customValue) {
      setMedicineInfo((prevState: any) => ({
        ...prevState,
        customValue: Number(objFound?.id) + 1,
      }));
    }
    setMedicineInfo((prevState: any) => ({
      ...prevState,
      dosageInterval: value,
    }));
  };

  const handleOnchangeDosageInterval2 = (value: string | number) => {
    const objFound: any = commonTimeOptions.find((i: any) => i.value === value);
    //id === 2
    const newOptions = dailyDosageFrequencyOptions.map((i: any) => {
      if (i.id <= objFound?.id) {
        return {
          ...i,
          disabled: true,
        };
      }
      return i;
    });

    setCustomValueOptions(newOptions);
    setMedicineInfo((prevState: any) => ({
      ...prevState,
      dosageInterval2: value,
    }));

    if (objFound.id === 12) {
      setMedicineInfo((prevState: any) => ({
        ...prevState,
        customValue: 12,
      }));
    } else {
      setMedicineInfo((prevState: any) => ({
        ...prevState,
        customValue: objFound.id + 1,
      }));
    }
  };

  const handleOnChangeTimingSpecification = (e: RadioChangeEvent) => {
    setMedicineInfo((prevState: any) => ({
      ...prevState,
      timingSpecification: e.target.value,
      twoCaseValue: 0,
    }));
    setExtraRadioList(
      extraOptions.map((i: any) => {
        return {
          ...i,
          disabled: true,
        };
      })
    );
  };

  const handleOnChangeDosageTimeOne = (value: string | number) => {
    const dosageTimeClone = [...medicineInfo?.dosageTime];
    dosageTimeClone.splice(0, 1, value);

    setMedicineInfo((prevState: any) => ({
      ...prevState,
      dosageTime: dosageTimeClone,
    }));
  };

  const handleOnChangeDosageTimeTwo = (value: string | number) => {
    const dosageTimeClone = [...medicineInfo?.dosageTime];
    dosageTimeClone.splice(1, 1, value);
    setMedicineInfo((prevState: any) => ({
      ...prevState,
      dosageTime: dosageTimeClone,
    }));
  };

  const handleOnChangeDosageTimeThree = (value: string | number) => {
    const dosageTimeClone = [...medicineInfo?.dosageTime];
    dosageTimeClone.splice(2, 1, value);
    setMedicineInfo((prevState: any) => ({
      ...prevState,
      dosageTime: dosageTimeClone,
    }));
  };

  const handleAdditionalInfoOnChange = (e: any) => {
    setMedicineInfo((prevState: any) => ({
      ...prevState,
      additionalInfo: e.target.value,
    }));
    setAdditionalInfoRadioList(
      additionalInfoOptions.map((i: any) => {
        if (i.value !== e.target.value) {
          return {
            ...i,
            disabled: true,
          };
        }
        return i;
      })
    );
  };

  const handleAddRow = () => {
    setAttachPartInformation((prevState: any) => [
      ...prevState,
      {
        id: uuid(),
        selectedValue: "",
        checkboxList: [],
      },
    ]);
  };

  const handleSubtractRow = () => {
    setAttachPartInformation(
      attachPartInformation.splice(0, attachPartInformation.length - 1)
    );
  };

  const handleOnChangeSupplyValue = (e: any) => {
    setMedicineInfo((prevState: any) => ({
      ...prevState,
      supplyValue: e.target.value,
    }));
  };

  const handleOnChangeSupplyType = (e: RadioChangeEvent) => {
    setMedicineInfo((prevState: any) => ({
      ...prevState,
      supplyType: e.target.value,
    }));
    setSupplyTypeRadioList(
      supplyTypeOptions.map((i: any) => {
        if (i.value !== e.target.value) {
          return {
            ...i,
            disabled: true,
          };
        }
        return {
          ...i,
          disabled: false,
        };
      })
    );
  };

  const [error, setError] = useState<any>({});
  const [extraError, setExtraError] = useState<any>({});

  const handleValidation = () => {
    let err: any = {};
    let attachPartErr: any = {};
    let defaultKeys = ["supplyValue", "amount"];
    const case5Keys = ["eventCategory", "eventName"];

    if (medicineInfo?.basicDosage === 1) {
      defaultKeys = [...defaultKeys, "dispensingInstruction"];
    }

    const obj: any = {
      1: ["timingMeals", "timeMealCheckbox", ...defaultKeys],
      5: [...case5Keys, ...defaultKeys],
      7: ["additionalInfo", ...defaultKeys],
      6: ["lifeRhythm", ...defaultKeys],
      8: ["additionalInfo2", ...defaultKeys],
    };

    for (const property in medicineInfo) {
      if (medicineInfo?.timingSpecification === 4) return;
      if (obj[medicineInfo?.timingSpecification].includes(property)) {
        if (property === "timeMealCheckbox" && !medicineInfo[property].length) {
          err = {
            ...err,
            [property]: "選択してください",
          };
        } else if (
          property === "lifeRhythm" &&
          !medicineInfo[property].length
        ) {
          err = {
            ...err,
            [property]: "選択してください",
          };
        } else if (!medicineInfo[property]) {
          err = {
            ...err,
            [property]:
              property === "supplyValue" || property === "amount"
                ? "入力してください"
                : "選択してください",
          };
        } else {
          delete err[property];
        }
      }

      if (
        medicineInfo?.timingSpecification === 5 &&
        medicineInfo.basicDosage === 2
      ) {
        attachPartInformation.forEach((i: any) => {
          if (typeof i.selectedValue === "string") {
            attachPartErr = {
              ...attachPartErr,
              [i.id]: {
                ...attachPartErr[i.id],
                selectedValue: "選択してください",
              },
            };
          } else {
            if (attachPartErr[i.id]) {
              delete attachPartErr[i.id].selectedValue;
            }
          }
          if (!i.checkboxList.length) {
            attachPartErr = {
              ...attachPartErr,
              [i.id]: {
                ...attachPartErr[i.id],
                checkboxList: "選択してください",
              },
            };
          } else {
            if (attachPartErr[i.id]) {
              delete attachPartErr[i.id].checkboxList;
            }
          }
        });
      }
    }

    setError(err);
    setExtraError(attachPartErr);
    return Object.keys(err).length > 0 || Object.keys(attachPartErr).length > 0;
  };

  const handleCreateMedicine = () => {
    if (handleValidation()) return;

    let params: any = {
      prescription: {
        medicine_id: medicineInfo?.medicineID,
        amount: Number(medicineInfo?.amount),
        basic_dosage: medicineInfo?.basicDosage,
        timing_specification: medicineInfo?.timingSpecification,
        daily_dosage_frequency: medicineInfo?.dailyDosageFrequency,
        supply_type_id: medicineInfo?.supplyType,
        supply_value: Number(medicineInfo?.supplyValue),
        detailed_dosage: medicineInfo?.detailDosage,
        comment: medicineInfo?.comment,
        amount_type_id: medicineInfo?.amount_type_id,
      },
    };

    if (medicineInfo?.basicDosage === 1) {
      params = {
        prescription: {
          ...params.prescription,
          dispensing_instruction: medicineInfo?.dispensingInstruction,
        },
      };
    }

    if (medicineInfo?.timingSpecification === 1) {
      let extraParams = {
        dinner: 0,
        lunch: 0,
        breakfast: 0,
        upon_waking: 0,
        before_bedtime: 0,
        additional_time_specification: 0,
        dispensing_instruction: medicineInfo?.dispensingInstruction,
      };
      if (medicineInfo?.timeMealCheckbox.includes("朝")) {
        extraParams = {
          ...extraParams,
          breakfast: medicineInfo?.timingMeals,
        };
      }
      if (medicineInfo?.timeMealCheckbox.includes("昼")) {
        extraParams = {
          ...extraParams,
          lunch: medicineInfo?.timingMeals,
        };
      }
      if (medicineInfo?.timeMealCheckbox.includes("夕")) {
        extraParams = {
          ...extraParams,
          dinner: medicineInfo?.timingMeals,
        };
      }
      if (medicineInfo?.timeMealCheckbox.includes("起床時")) {
        extraParams = {
          ...extraParams,
          upon_waking: 9,
        };
      }
      if (medicineInfo?.timeMealCheckbox.includes("就寝前")) {
        extraParams = {
          ...extraParams,
          before_bedtime: 1,
        };
      }

      if (medicineInfo?.timingMeals === 6) {
        extraParams = {
          ...extraParams,
          additional_time_specification: medicineInfo?.time,
        };
      }

      params = {
        prescription: {
          ...params.prescription,
          ...extraParams,
        },
      };
    }

    if (medicineInfo?.timingSpecification === 2) {
      params = {
        prescription: {
          ...params.prescription,
          dosage_interval: medicineInfo?.dosageInterval,
          dispensing_instruction: medicineInfo?.dispensingInstruction,
        },
      };
    }

    if (medicineInfo?.timingSpecification === 3) {
      params = {
        prescription: {
          ...params.prescription,
          dosage_times: medicineInfo?.dosageTime,
          dispensing_instruction: medicineInfo?.dispensingInstruction,
        },
      };
    }

    if (medicineInfo?.twoCaseValue === 1) {
      params = {
        prescription: {
          ...params.prescription,
          event_category: 4,
          event_name: 8,
          auxiliary_condition: 0,
          dispensing_instruction: medicineInfo?.dispensingInstruction,
        },
      };
    }

    if (medicineInfo?.twoCaseValue === 2) {
      params = {
        prescription: {
          ...params.prescription,
          event_category: "B",
          event_name: 7,
          auxiliary_condition: 0,
          dispensing_instruction: medicineInfo?.dispensingInstruction,
        },
      };
    }

    if (medicineInfo?.timingSpecification === 5) {
      params = {
        prescription: {
          ...params.prescription,
          event_category: medicineInfo?.eventCategory,
          event_name: medicineInfo?.eventName,
          auxiliary_condition: 0,
          maximum_dosage_frequency: medicineInfo?.dailyDosageFrequency,
          minimum_time_interval: medicineInfo?.maximumDosageFrequency
            ? medicineInfo?.maximumDosageFrequencyValue
            : 0,
          dispensing_instruction: medicineInfo?.dispensingInstruction,
          daily_dosage_frequency: 0,
        },
      };
    }

    if (medicineInfo?.timingSpecification === 6) {
      let extraParams = {
        morning: 0,
        noon: 0,
        evening: 0,
        upon_waking: 0,
        before_bedtime: 0,
        additional_time_specification: 0,
        associated_body_parts: [],
      };
      if (medicineInfo?.lifeRhythm.includes("朝")) {
        extraParams = {
          ...extraParams,
          morning: 9,
        };
      }
      if (medicineInfo?.lifeRhythm.includes("昼")) {
        extraParams = {
          ...extraParams,
          noon: 9,
        };
      }
      if (medicineInfo?.lifeRhythm.includes("夜")) {
        extraParams = {
          ...extraParams,
          evening: 9,
        };
      }
      if (medicineInfo?.lifeRhythm.includes("起床時")) {
        extraParams = {
          ...extraParams,
          upon_waking: 9,
        };
      }
      if (medicineInfo?.lifeRhythm.includes("就寝前")) {
        extraParams = {
          ...extraParams,
          before_bedtime: 1,
        };
      }
      if (medicineInfo?.lifeRhythm.includes("custom")) {
        extraParams = {
          ...extraParams,
          additional_time_specification: medicineInfo?.time,
        };
      }

      params = {
        prescription: {
          ...params.prescription,
          ...extraParams,
        },
      };
    }

    if (medicineInfo?.timingSpecification === 7) {
      params = {
        prescription: {
          ...params.prescription,
          associated_body_parts: [],
          daily_dosage_frequency_additional_info:
            medicineInfo?.additionalInfo === "C"
              ? medicineInfo?.bigNumberValue
              : medicineInfo?.additionalInfo,
        },
      };
    }

    if (medicineInfo?.timingSpecification === 8) {
      params = {
        prescription: {
          ...params.prescription,
          dosage_interval: medicineInfo?.dosageInterval2,
          dosage_interval_additional_info:
            medicineInfo?.additionalInfo2 === "C"
              ? medicineInfo?.customValue
              : medicineInfo?.additionalInfo2,
          associated_body_parts: [],
        },
      };
    }

    if (
      medicineInfo?.timingSpecification === 5 &&
      medicineInfo?.basicDosage === 2
    ) {
      params = {
        prescription: {
          ...params.prescription,
          timing_specification: 5,
          event_category: medicineInfo?.eventCategory,
          event_name: medicineInfo?.eventName,
          minimum_time_interval: medicineInfo?.minimumTimeInterval
            ? medicineInfo?.minimumTimeIntervalValue
            : 0,
          maximum_dosage_frequency: medicineInfo?.maximumDosageFrequency
            ? medicineInfo?.maximumDosageFrequencyValue
            : 0,
          auxiliary_condition: medicineInfo?.selectPartsChecked ? 7 : 0,
          associated_body_parts: [
            attachPartInformation
              .map((i: any) => {
                return i.checkboxList;
              })
              .join(","),
          ],
        },
      };
    }

    dispatch(
      CreateMedicineByIDActionCreators.createMedicineByIDAction({
        id: medicalRecord.id,
        params,
      })
    );
  };

  const onSelect = (data: any, options: any) => {
    setUnitOptions([
      {
        id: 1,
        value: 1,
        name: `${options.unitName}/1日`,
      },
      {
        id: 2,
        value: 2,
        name: `${options.unitName}/1回`,
      },
    ]);

    setMedicineInfo({
      medicineID: options.id,
      name: options.value,
      unit: options.unitName,
      basicDosage: 1,
      dosageTime: ["I", "M", "S"],
      comment: "",
      detailDosage: 0,
      timingSpecification: 1,
      time: "P",
      dailyDosageFrequency: 3,
      maximumDosageFrequencyValue: 6,
      dosageInterval: "I",
      dosageInterval2: "C",
      supplyType: 1,
      supplyValue: 3,
      amount: null,
      amount_type_id: 1,
      timeMealCheckbox: [],
      timingMeals: null,
      dispensingInstruction: null,
      eventCategory: 7,
      eventName: null,
      maximumDosageFrequency: false,
      additionalInfo: null,
      additionalInfo2: null,
      lifeRhythm: [],
      minimumTimeInterval: null,
      selectPartsChecked: false,
      twoCaseValue: null,
    });
  };

  const obj2: any = {
    1: firstEventNameOptions,
    2: secondEventNameOptions,
    3: thirdEventNameOptions,
    4: fourEventNameOptions,
    5: fiveEventNameOptions,
    6: sixEventNameOptions,
    7: sevenEventNameOptions,
    8: eightEventNameOptions,
    9: nightEventNameOptions,
    A: tenEventNameOptions,
    B: eleventEventNameOptions,
    W: twelveEventNameOptions,
  };

  const objName: any = {
    0: "指定なし",
    1: "左",
    2: "右",
    3: "両",
  };

  const renderSelectBodyParts = () => {
    return (
      <Fragment>
        {attachPartInformation.map((i: any) => {
          return (
            <div key={i.id} className="item">
              <Select
                fs={14}
                width="156px"
                height="31px"
                value={i.selectedValue}
                options={associatedBodyPartsOptions}
                onChange={(e: any) => {
                  setError((prevState: any) => ({
                    ...prevState,
                    [i.id]: {
                      ...prevState[i.id],
                      selectedValue: "選択してください",
                    },
                  }));
                  setAttachPartInformation(
                    attachPartInformation.map((ci: any) => {
                      if (ci.id === i.id) {
                        return {
                          ...ci,
                          selectedValue: e,
                          checkboxList: [],
                        };
                      }

                      return ci;
                    })
                  );
                }}
                error={extraError[i.id]?.selectedValue}
              />
              <WrapperError error={extraError[i.id]?.checkboxList}>
                <Checkbox
                  row
                  gap="8px"
                  value={i.checkboxList}
                  onChange={(e: any) => {
                    setAttachPartInformation(
                      attachPartInformation.map((ci: any) => {
                        if (ci.id === i.id) {
                          return {
                            ...ci,
                            checkboxList: e,
                          };
                        }

                        return ci;
                      })
                    );
                  }}
                  list={
                    associatedBodyPartsOptions
                      .find((e) => e.value === i.selectedValue)
                      ?.subValues.map((sv, idx) => {
                        return { key: idx, value: sv, name: objName[idx] };
                      }) ||
                    associatedBodyPartsOptions[0].subValues.map((sv1, idx1) => {
                      return { key: idx1, value: sv1, name: objName[idx1] };
                    })
                  }
                />
                {extraError[i.id]?.checkboxList && (
                  <WrapperErrorMessage>
                    <ErrorMessage>
                      {extraError[i.id]?.checkboxList}
                    </ErrorMessage>
                  </WrapperErrorMessage>
                )}
              </WrapperError>
            </div>
          );
        })}
      </Fragment>
    );
  };

  const handleTimeMealCheckboxOnChange = (value: string[]) => {
    setMedicineInfo((prevState: any) => ({
      ...prevState,
      timeMealCheckbox: value,
    }));
  };

  const handleOnChangeTime = (value: any) => {
    setMedicineInfo((prevState: any) => ({
      ...prevState,
      time: value,
    }));
  };

  const handleOnChangeDailyDosageFrequency = (value: any) => {
    setMedicineInfo((prevState: any) => ({
      ...prevState,
      dailyDosageFrequency: value,
    }));
  };

  const identification: any = {
    additional_time_specification: "additionalTimeSpecification",
    basic_dosage: "basicDosage",
    before_bedtime: "beforeBedtime",
    breakfast: "breakfast",
    daily_dosage_frequency: "dailyDosageFrequency",
    detailed_dosage: "detailDosage",
    dinner: "dinner",
    dispensing_instructions: "dispensingInstruction",
    lunch: "lunch",
    timing_specification: "timingSpecification",
    upon_waking: "upon_waking",
    evening: "evening",
    morning: "morning",
    noon: "noon",
    dosage_times: "dosageTime",
    dosage_interval: "dosageInterval",
    dosage_interval_additional_info: "additionalInfo2",
    event_name: "eventName",
    event_category: "eventCategory",
    minimum_time_interval: "minimumTimeIntervalValue",
    maximum_dosage_frequency: "maximumDosageFrequencyValue",
  };

  const OralMedicine = () => {
    return (
      <React.Fragment>
        <div style={{ flex: 1 }}>
          <Flex style={{ width: "100%" }}>
            <div
              className="right-1"
              style={{
                borderRight: "1px solid #e0e0e0",
              }}
            >
              <NotClickSection
                disabled={
                  medicineInfo?.timingSpecification === 5 &&
                  medicineInfo?.basicDosage === 2
                }
              >
                <div style={{ marginBottom: 8 }}>1日回数</div>
                <Select
                  fs={14}
                  width="60px"
                  height="31px"
                  value={medicineInfo?.dailyDosageFrequency}
                  options={dailyDosageFrequencyOptions}
                  onChange={handleOnChangeDailyDosageFrequency}
                />
              </NotClickSection>
            </div>
            <div className="right-1" style={{ width: "100%" }}>
              <WrapperDosage>
                <Text style={{ marginBottom: 8 }}>用法詳細区分</Text>
                <TakeMedWrapper>
                  <Radio.Group
                    onChange={(e: RadioChangeEvent) => {
                      setMedicineInfo((prevState: any) => ({
                        ...prevState,
                        detailDosage: e.target.value,
                      }));
                      setDetailDosageRadioList(
                        detailDosage.map((i: any) => {
                          if (i.value !== e.target.value) {
                            return {
                              ...i,
                              disabled: true,
                            };
                          }
                          return {
                            ...i,
                            disabled: false,
                          };
                        })
                      );
                    }}
                    value={medicineInfo?.detailDosage}
                  >
                    <AudioWrapper>
                      {detailDosageRadioList.map((i: any) => {
                        return (
                          <Radio key={i.value} value={i.value}>
                            <TextRadio disabled={i.disabled}>
                              {i.name}
                            </TextRadio>
                          </Radio>
                        );
                      })}
                    </AudioWrapper>
                  </Radio.Group>
                </TakeMedWrapper>
              </WrapperDosage>

              <div className="6" style={{ marginBottom: 16 }}>
                <Text style={{ marginBottom: 8 }}>服用パターン選択</Text>
                <Label>
                  <div>食事等のタイミング </div>
                  <div>例: 1日3回 朝昼夕食後</div>
                </Label>
                <Flex>
                  <AudioWrapper style={{ marginBottom: 11, marginRight: 6 }}>
                    <Radio.Group
                      onChange={handleOnChangeTimingSpecification}
                      value={medicineInfo?.timingSpecification}
                    >
                      <Radio value={1} />
                    </Radio.Group>
                  </AudioWrapper>
                  <NotClickSection
                    disabled={medicineInfo?.timingSpecification !== 1}
                  >
                    <WrapperError error={error["timeMealCheckbox"]}>
                      <Checkbox
                        row
                        list={[
                          { key: 1, value: "朝", name: "朝" },
                          { key: 2, value: "昼", name: "昼" },
                          { key: 3, value: "夕", name: "夕" },
                          { key: 4, value: "起床時", name: "起床時" },
                          { key: 5, value: "就寝前", name: "就寝前" },
                        ]}
                        value={medicineInfo?.timeMealCheckbox}
                        onChange={handleTimeMealCheckboxOnChange}
                      />
                      {error["timeMealCheckbox"] && (
                        <WrapperErrorMessage>
                          <ErrorMessage>
                            {error["timeMealCheckbox"]}
                          </ErrorMessage>
                        </WrapperErrorMessage>
                      )}
                    </WrapperError>
                  </NotClickSection>
                </Flex>
                <NotClickSection
                  disabled={medicineInfo?.timingSpecification !== 1}
                  style={{ paddingLeft: 26 }}
                >
                  <WrapperError
                    error={error["timingMeals"]}
                    style={{
                      width: 400,
                      height: 65,
                    }}
                  >
                    <TakeMedWrapper
                      style={{ width: 400, position: "relative" }}
                    >
                      <Radio.Group
                        onChange={(e: RadioChangeEvent) => {
                          setMedicineInfo((prevState: any) => ({
                            ...prevState,
                            timingMeals: e.target.value,
                          }));
                          setTimeMealsRadioList(
                            timingMealsOptions.map((i: any) => {
                              if (i.value !== e.target.value) {
                                return {
                                  ...i,
                                  disabled: true,
                                };
                              }
                              return i;
                            })
                          );
                        }}
                        value={medicineInfo?.timingMeals}
                      >
                        <AudioWrapper>
                          {timeMealsRadioList.map((i: any) => {
                            return (
                              <Radio key={i.value} value={i.value}>
                                <TextRadio disabled={i.disabled}>
                                  {i.name}
                                </TextRadio>
                              </Radio>
                            );
                          })}

                          <Radio key={6} value={6} />
                        </AudioWrapper>
                      </Radio.Group>
                      <WrapperSelectWithTextCenter
                        style={{
                          position: "absolute",
                          bottom: -6,
                          left: 30,
                        }}
                      >
                        <TextRadio
                          style={{ display: "flex" }}
                          disabled={
                            medicineInfo?.timingMeals &&
                            medicineInfo?.timingMeals !== 6
                          }
                        >
                          <Select
                            fs={14}
                            width="60px"
                            height="31px"
                            options={commonTimeOptions}
                            onChange={handleOnChangeTime}
                            value={medicineInfo?.time}
                          />
                          <span className="unit">時</span>
                        </TextRadio>
                      </WrapperSelectWithTextCenter>
                    </TakeMedWrapper>
                    {error["timingMeals"] && (
                      <WrapperErrorMessage>
                        <ErrorMessage>{error["timingMeals"]}</ErrorMessage>
                      </WrapperErrorMessage>
                    )}
                  </WrapperError>
                </NotClickSection>
              </div>
              <div className="5" style={{ marginBottom: 16 }}>
                <Label>
                  <div>頓服</div>
                  <div>例：頭痛時1日2回まで 6時間以上開ける</div>
                </Label>

                <Flex style={{ gap: 8 }}>
                  <AudioWrapperIndividual>
                    <Radio.Group
                      onChange={handleOnChangeTimingSpecification}
                      value={medicineInfo?.timingSpecification}
                    >
                      <Radio value={5} />
                    </Radio.Group>
                  </AudioWrapperIndividual>
                  <div>
                    <NotClickSection
                      disabled={medicineInfo?.timingSpecification !== 5}
                      style={{ display: "flex", gap: 6, marginBottom: 15 }}
                    >
                      <Select
                        fs={14}
                        width="139px"
                        height="31px"
                        placeholder="症状等選択"
                        options={eventCategoryOptions}
                        value={medicineInfo?.eventCategory}
                        onChange={(e: any) =>
                          setMedicineInfo((prevState: any) => ({
                            ...prevState,
                            eventCategory: e,
                          }))
                        }
                        error={error["eventCategory"]}
                      />
                      <Select
                        fs={14}
                        width="139px"
                        height="31px"
                        options={obj2[medicineInfo?.eventCategory]}
                        value={medicineInfo?.eventName}
                        onChange={(e: any) =>
                          setMedicineInfo((prevState: any) => ({
                            ...prevState,
                            eventName: e,
                          }))
                        }
                        error={error["eventName"]}
                      />
                    </NotClickSection>
                    <Flex style={{ gap: 78 }}>
                      <NotClickSection
                        disabled={medicineInfo?.timingSpecification !== 5}
                        style={{ display: "flex", gap: 16 }}
                      >
                        <CheckboxStyled
                          checked={medicineInfo?.maximumDosageFrequency}
                          onChange={(e: any) =>
                            // setMinimumTimeInterval(e.target.checked)
                            setMedicineInfo((prevState: any) => ({
                              ...prevState,
                              maximumDosageFrequency: e.target.checked,
                            }))
                          }
                        />
                        <WrapperSelect
                          style={{ paddingLeft: "28px", right: "-170px" }}
                        >
                          <Select
                            fs={14}
                            width="60px"
                            height="31px"
                            options={dailyDosageFrequencyOptions}
                            onChange={(e: any) =>
                              setMedicineInfo((prevState: any) => ({
                                ...prevState,
                                maximumDosageFrequencyValue: e,
                              }))
                            }
                            value={medicineInfo?.maximumDosageFrequencyValue}
                          />
                          <span>時間以上あける</span>
                        </WrapperSelect>
                      </NotClickSection>
                    </Flex>
                  </div>
                </Flex>
              </div>

              <div className="3" style={{ marginBottom: 16 }}>
                <Label>
                  <div>時間指定</div>
                  <div>例：1日3回 8時 12時 18時</div>
                </Label>
                <Flex>
                  <AudioWrapper
                    style={{
                      paddingTop: 6,
                      marginRight: 9,
                    }}
                  >
                    <Radio.Group
                      onChange={handleOnChangeTimingSpecification}
                      value={medicineInfo?.timingSpecification}
                    >
                      <Radio value={3} />
                    </Radio.Group>
                  </AudioWrapper>
                  <NotClickSection
                    disabled={medicineInfo?.timingSpecification !== 3}
                    style={{ display: "flex", gap: 16 }}
                  >
                    <WrapperSelectStyled>
                      <Select
                        fs={14}
                        width="60px"
                        height="31px"
                        options={commonTimeOptions}
                        onChange={handleOnChangeDosageTimeOne}
                        value={
                          medicineInfo?.dosageTime &&
                          medicineInfo?.dosageTime[0]
                        }
                      />
                      <span>時</span>
                    </WrapperSelectStyled>
                    <WrapperSelectStyled>
                      <Select
                        fs={14}
                        width="60px"
                        height="31px"
                        options={commonTimeOptions}
                        onChange={handleOnChangeDosageTimeTwo}
                        value={
                          medicineInfo?.dosageTime &&
                          medicineInfo?.dosageTime[1]
                        }
                      />
                      <span>時</span>
                    </WrapperSelectStyled>
                    <WrapperSelectStyled>
                      <Select
                        fs={14}
                        width="60px"
                        height="31px"
                        options={commonTimeOptions}
                        onChange={handleOnChangeDosageTimeThree}
                        value={
                          medicineInfo?.dosageTime &&
                          medicineInfo?.dosageTime[2]
                        }
                      />
                      <span>時</span>
                    </WrapperSelectStyled>
                  </NotClickSection>
                </Flex>
              </div>

              <div className="2" style={{ marginBottom: 16 }}>
                <Label>
                  <div>時間間隔</div>
                  <div>例：1日3回 8時間ごと</div>
                </Label>
                <Flex>
                  <AudioWrapper
                    style={{
                      paddingTop: 6,
                      marginRight: 9,
                    }}
                  >
                    <Radio.Group
                      onChange={handleOnChangeTimingSpecification}
                      value={medicineInfo?.timingSpecification}
                    >
                      <Radio value={2} />
                    </Radio.Group>
                  </AudioWrapper>
                  <NotClickSection
                    disabled={medicineInfo?.timingSpecification !== 2}
                    style={{ display: "flex", gap: 16 }}
                  >
                    <WrapperSelectStyled>
                      <Select
                        fs={14}
                        width="60px"
                        height="31px"
                        options={commonTimeOptions
                          .filter((i: any) => i.value !== "A")
                          .filter((i: any) => i.id <= 12)}
                        onChange={handleOnchangeDosageInterval}
                        value={medicineInfo?.dosageInterval}
                      />
                      <span>時間ごと</span>
                    </WrapperSelectStyled>
                  </NotClickSection>
                </Flex>
              </div>

              <div className="4">
                <Label>
                  <div>空腹時・哺乳時</div>
                  <div>例：1日10回 哺乳時</div>
                </Label>
                <Flex
                  style={{
                    alignItems: "center",
                    gap: 6,
                  }}
                >
                  <AudioWrapper>
                    <Radio.Group
                      onChange={(e) => {
                        // setTwoCaseValue(e.target.value);
                        setMedicineInfo((prevState: any) => ({
                          ...prevState,
                          timingSpecification: 4,
                          twoCaseValue: e.target.value,
                        }));
                        setExtraRadioList(
                          extraOptions.map((i: any) => {
                            if (i.value !== e.target.value) {
                              return {
                                ...i,
                                disabled: true,
                              };
                            }
                            return {
                              ...i,
                              disabled: false,
                            };
                          })
                        );
                      }}
                      value={medicineInfo?.twoCaseValue}
                    >
                      <AudioWrapper>
                        {extraRadioList.map((i: any) => {
                          return (
                            <Radio key={i.id} value={i.value}>
                              <TextRadio disabled={i.disabled}>
                                {i.name}
                              </TextRadio>
                            </Radio>
                          );
                        })}
                      </AudioWrapper>
                    </Radio.Group>
                  </AudioWrapper>
                </Flex>
              </div>
            </div>
          </Flex>
          <div
            style={{
              padding: 16,
              borderTop: "1px solid #e0e0e0",
            }}
          >
            <WrapperError error={error["dispensingInstruction"]}>
              <Radio.Group
                onChange={(e) => {
                  setMedicineInfo((prevState: any) => ({
                    ...prevState,
                    dispensingInstruction: e.target.value,
                  }));
                  setDispensingInstructionRadioList(
                    dispensingInstructionOptions.map((i: any) => {
                      if (i.value !== e.target.value) {
                        return {
                          ...i,
                          disabled: true,
                        };
                      }
                      return i;
                    })
                  );
                }}
                value={medicineInfo?.dispensingInstruction}
              >
                <AudioWrapper>
                  {dispensingInstructionRadioList.map((i: any) => {
                    return (
                      <Radio key={i.id} value={i.value}>
                        <TextRadio disabled={i.disabled}>{i.name}</TextRadio>
                      </Radio>
                    );
                  })}
                </AudioWrapper>
              </Radio.Group>
              {error["dispensingInstruction"] && (
                <WrapperErrorMessage>
                  <ErrorMessage>{error["dispensingInstruction"]}</ErrorMessage>
                </WrapperErrorMessage>
              )}
            </WrapperError>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const ExternalMedicine = () => {
    return (
      <React.Fragment>
        <div
          className="right-1"
          style={{
            borderRight: "1px solid #e0e0e0",
          }}
        >
          <NotClickSection
            disabled={
              medicineInfo?.timingSpecification === 5 &&
              medicineInfo?.basicDosage === 2
            }
          >
            <div style={{ marginBottom: 8 }}>1日回数</div>
            <Select
              fs={14}
              width="60px"
              height="31px"
              value={medicineInfo?.dailyDosageFrequency}
              options={dailyDosageFrequencyOptions}
              onChange={(e: any) => {
                const newOptions = dailyDosageFrequencyOptions.map((i: any) => {
                  if (typeof e === "string") {
                    if (i.id < examObj[e]) {
                      return {
                        ...i,
                        disabled: true,
                      };
                    }
                  }
                  if (i.id <= e) {
                    return {
                      ...i,
                      disabled: true,
                    };
                  }
                  return i;
                });

                setBigNumberOptions(newOptions);
                if (typeof e === "string") {
                  if (examObj[e] === 12) {
                    setMedicineInfo((prevState: any) => ({
                      ...prevState,
                      dailyDosageFrequency: e,
                      bigNumberValue: 12,
                    }));
                  } else {
                    setMedicineInfo((prevState: any) => ({
                      ...prevState,
                      dailyDosageFrequency: e,
                      bigNumberValue: examObj[e] + 1,
                    }));
                  }
                } else {
                  setMedicineInfo((prevState: any) => ({
                    ...prevState,
                    dailyDosageFrequency: e,
                    bigNumberValue: e + 1,
                  }));
                }
              }}
            />
          </NotClickSection>
        </div>
        <div className="right-1" style={{ width: "100%" }}>
          <Text>用法詳細区分</Text>
          <Select
            fs={14}
            width="114px"
            height="31px"
            marginForm="0 0 16px"
            options={detailDosage2}
            onChange={(e: any) =>
              setMedicineInfo((prevState: any) => ({
                ...prevState,
                detailDosage: e,
              }))
            }
            value={medicineInfo?.detailDosage}
          />
          <Text>外用パターン選択</Text>
          <div className="7" style={{ marginBottom: 16 }}>
            <Label>
              <div>回数で指定</div>
              <div>例：1日3回から数回 / 3回程度 / 3回から4回</div>
            </Label>
            <Flex>
              <AudioWrapper style={{ marginRight: 6, paddingTop: 3 }}>
                <Radio.Group
                  onChange={handleOnChangeTimingSpecification}
                  value={medicineInfo?.timingSpecification}
                >
                  <Radio value={7} />
                </Radio.Group>
              </AudioWrapper>

              <WrapperError
                error={error["additionalInfo"]}
                style={{
                  width: 320,
                  padding: 0,
                }}
              >
                <NotClickSection
                  disabled={medicineInfo?.timingSpecification !== 7}
                >
                  <div style={{ marginBottom: 12, marginTop: 4 }}>
                    回数のみ指定
                  </div>
                  <Flex style={{ gap: 4 }}>
                    <Radio.Group
                      onChange={handleAdditionalInfoOnChange}
                      value={medicineInfo?.additionalInfo}
                    >
                      <AudioWrapper>
                        {additionalInfoRadioList.map((i: any) => {
                          return (
                            <Radio key={i.id} value={i.value}>
                              <TextRadio disabled={i.disabled}>
                                {i.name}
                              </TextRadio>
                            </Radio>
                          );
                        })}
                      </AudioWrapper>
                    </Radio.Group>
                    <WrapperSelect>
                      <TextRadio
                        style={{ display: "flex" }}
                        disabled={
                          medicineInfo?.additionalInfo &&
                          medicineInfo?.additionalInfo !== "C"
                        }
                      >
                        <Select
                          fs={14}
                          width="60px"
                          height="31px"
                          options={bigNumberOptions}
                          value={medicineInfo?.bigNumberValue}
                          onChange={(value: any) =>
                            setMedicineInfo((prevState: any) => ({
                              ...prevState,
                              bigNumberValue: value,
                            }))
                          }
                        />
                        <span className="unit">回</span>
                      </TextRadio>
                    </WrapperSelect>
                  </Flex>
                </NotClickSection>
                {error["additionalInfo"] && (
                  <WrapperErrorMessage>
                    <ErrorMessage>{error["additionalInfo"]}</ErrorMessage>
                  </WrapperErrorMessage>
                )}
              </WrapperError>
            </Flex>
          </div>
          <div className="6" style={{ marginBottom: 16, position: "relative" }}>
            <Label>
              <div>生活リズムで指定</div>
              <div>例：1日3回 朝昼夕</div>
            </Label>

            <Flex>
              <AudioWrapper
                style={{ marginBottom: 11, marginRight: 6, paddingTop: 3 }}
              >
                <Radio.Group
                  onChange={handleOnChangeTimingSpecification}
                  value={medicineInfo?.timingSpecification}
                >
                  <Radio value={6} />
                </Radio.Group>
              </AudioWrapper>
              <WrapperError
                error={error["lifeRhythm"]}
                style={{ width: 338, height: 70 }}
              >
                <NotClickSection
                  disabled={medicineInfo?.timingSpecification !== 6}
                >
                  <Checkbox
                    row
                    list={[
                      { key: 1, value: "朝", name: "朝" },
                      { key: 2, value: "昼", name: "昼" },
                      { key: 3, value: "夜", name: "夜" },
                      { key: 4, value: "起床時", name: "起床時" },
                      { key: 5, value: "就寝前", name: "就寝前" },
                      { key: 6, value: "custom", name: "" },
                    ]}
                    value={medicineInfo?.lifeRhythm}
                    onChange={(e: any) =>
                      setMedicineInfo((prevState: any) => ({
                        ...prevState,
                        lifeRhythm: e,
                      }))
                    }
                    gap="10px 16px"
                  />
                </NotClickSection>
                {error["lifeRhythm"] && (
                  <WrapperErrorMessage>
                    <ErrorMessage>{error["lifeRhythm"]}</ErrorMessage>
                  </WrapperErrorMessage>
                )}
              </WrapperError>
            </Flex>
            <NotClickSection disabled={medicineInfo?.timingSpecification !== 6}>
              <WrapperSelectWithTextCenter
                style={{
                  position: "absolute",
                  bottom: 5,
                  left: 58,
                }}
              >
                <Select
                  fs={14}
                  width="60px"
                  height="31px"
                  options={commonTimeOptions}
                  value={medicineInfo?.time}
                  onChange={(e: any) =>
                    setMedicineInfo((prevState: any) => ({
                      ...prevState,
                      time: e,
                    }))
                  }
                />
                <span>時</span>
              </WrapperSelectWithTextCenter>
            </NotClickSection>
          </div>

          <div className="8" style={{ marginBottom: 16 }}>
            <Label>
              <div>時間間隔で指定</div>
              <div>
                例：1日3回 8時間ごと / 2〜3時間ごと / 数時間ごと / 約2時間ごと
              </div>
            </Label>
            <Flex>
              <AudioWrapper style={{ marginRight: 9, paddingTop: 6 }}>
                <Radio.Group
                  onChange={handleOnChangeTimingSpecification}
                  value={medicineInfo?.timingSpecification}
                >
                  <Radio value={8} />
                </Radio.Group>
              </AudioWrapper>
              <WrapperError
                error={error["additionalInfo2"]}
                style={{
                  width: 300,
                  padding: "2px 4px 4px 1px",
                }}
              >
                <NotClickSection
                  disabled={medicineInfo?.timingSpecification !== 8}
                >
                  <WrapperSelectStyled style={{ marginBottom: 8 }}>
                    <Select
                      fs={14}
                      width="60px"
                      height="31px"
                      options={commonTimeOptions
                        .filter((i: any) => i.value !== "A")
                        .filter((i: any) => i.id <= 12)}
                      onChange={handleOnchangeDosageInterval2}
                      value={medicineInfo?.dosageInterval2}
                    />
                    <span>時間ごと</span>
                  </WrapperSelectStyled>

                  <Flex style={{ gap: 4 }}>
                    <Radio.Group
                      onChange={(e: RadioChangeEvent) => {
                        setMedicineInfo((prevState: any) => ({
                          ...prevState,
                          additionalInfo2: e.target.value,
                        }));
                        setAdditionalInfo2RadioList(
                          additionalInfo2Options.map((i: any) => {
                            if (i.value !== e.target.value) {
                              return {
                                ...i,
                                disabled: true,
                              };
                            }
                            return i;
                          })
                        );
                      }}
                      value={medicineInfo?.additionalInfo2}
                    >
                      <AudioWrapper>
                        {additionalInfo2RadioList.map((i: any) => {
                          return (
                            <Radio key={i.value} value={i.value}>
                              <TextRadio disabled={i.disabled}>
                                {i.name}
                              </TextRadio>
                            </Radio>
                          );
                        })}
                      </AudioWrapper>
                    </Radio.Group>

                    <WrapperSelect style={{ right: -94 }}>
                      <TextRadio
                        style={{ display: "flex" }}
                        disabled={
                          medicineInfo?.additionalInfo2 &&
                          medicineInfo?.additionalInfo2 !== "C"
                        }
                      >
                        <Select
                          fs={14}
                          width="60px"
                          height="31px"
                          options={customValueOptions}
                          value={medicineInfo?.customValue}
                          onChange={(e: any) => {
                            setMedicineInfo((prevState: any) => ({
                              ...prevState,
                              customValue: e,
                            }));
                          }}
                        />
                        <span className="unit">時間</span>
                      </TextRadio>
                    </WrapperSelect>
                  </Flex>
                </NotClickSection>
                {error["additionalInfo2"] && (
                  <WrapperErrorMessage>
                    <ErrorMessage>{error["additionalInfo2"]}</ErrorMessage>
                  </WrapperErrorMessage>
                )}
              </WrapperError>
            </Flex>
          </div>

          <div className="5">
            <Label>
              <div>頓用</div>
              <div>例：頭痛時1日2回まで 6時間以上開ける</div>
            </Label>

            <Flex style={{ gap: 4 }}>
              <AudioWrapperIndividual style={{ marginRight: 7 }}>
                <Radio.Group
                  onChange={handleOnChangeTimingSpecification}
                  value={medicineInfo?.timingSpecification}
                >
                  <Radio value={5} />
                </Radio.Group>
              </AudioWrapperIndividual>
              {/* <WrapperError
                error={error["timingMeals"]}
                style={{
                  width: 400,
                }}
              > */}
              <div>
                <Flex
                  style={{
                    alignItems: "center",
                    gap: 6,
                  }}
                >
                  <NotClickSection
                    disabled={medicineInfo?.timingSpecification !== 5}
                    style={{ display: "flex", gap: 6 }}
                  >
                    <Select
                      fs={14}
                      width="139px"
                      height="31px"
                      placeholder="症状等選択"
                      options={eventCategoryOptions}
                      value={medicineInfo?.eventCategory}
                      onChange={(e: any) =>
                        setMedicineInfo((prevState: any) => ({
                          ...prevState,
                          eventCategory: e,
                        }))
                      }
                      error={error["eventCategory"]}
                    />
                    <Select
                      fs={14}
                      width="139px"
                      height="31px"
                      options={obj2[medicineInfo?.eventCategory]}
                      value={medicineInfo?.eventName}
                      onChange={(e: any) =>
                        setMedicineInfo((prevState: any) => ({
                          ...prevState,
                          eventName: e,
                        }))
                      }
                      error={error["eventName"]}
                    />
                  </NotClickSection>
                </Flex>
                <NotClickSection
                  disabled={medicineInfo?.timingSpecification !== 5}
                >
                  <Flex style={{ gap: 110, marginTop: 15 }}>
                    <Flex>
                      <CheckboxStyled
                        checked={medicineInfo?.maximumDosageFrequency}
                        onChange={(e: any) =>
                          setMedicineInfo((prevState: any) => ({
                            ...prevState,
                            maximumDosageFrequency: e.target.checked,
                          }))
                        }
                      >
                        回数制限
                      </CheckboxStyled>
                      <WrapperSelect style={{ right: -92 }}>
                        <Select
                          fs={14}
                          width="60px"
                          height="31px"
                          options={dailyDosageFrequencyOptions}
                          onChange={(e: any) =>
                            setMedicineInfo((prevState: any) => ({
                              ...prevState,
                              maximumDosageFrequencyValue: e,
                            }))
                          }
                          value={medicineInfo?.maximumDosageFrequencyValue}
                        />
                        <span>まで</span>
                      </WrapperSelect>
                    </Flex>
                    <Flex style={{ gap: 16 }}>
                      <CheckboxStyled
                        checked={medicineInfo?.minimumTimeInterval}
                        onChange={(e: any) =>
                          setMedicineInfo((prevState: any) => ({
                            ...prevState,
                            minimumTimeInterval: e.target.checked,
                          }))
                        }
                      />
                      <WrapperSelect
                        style={{ paddingLeft: "28px", right: "-170px" }}
                      >
                        <Select
                          fs={14}
                          width="60px"
                          height="31px"
                          options={commonTimeOptions.filter(
                            (i: any) => i.id <= 12
                          )}
                          onChange={(e: any) =>
                            setMedicineInfo((prevState: any) => ({
                              ...prevState,
                              minimumTimeIntervalValue: e,
                            }))
                          }
                          value={medicineInfo?.minimumTimeIntervalValue}
                        />
                        <span>時間以上あける</span>
                      </WrapperSelect>
                    </Flex>
                  </Flex>
                </NotClickSection>
                <NotClickSection
                  disabled={medicineInfo?.timingSpecification !== 5}
                >
                  <Flex
                    style={{
                      marginTop: 16,
                      gap: 8,
                      alignItems: "center",
                    }}
                  >
                    <CheckboxStyled
                      disabled={false}
                      style={{ height: 24 }}
                      checked={medicineInfo?.selectPartsChecked}
                      onChange={(e: any) =>
                        // setSelectPartsChecked(!selectPartsChecked)
                        setMedicineInfo((prevState: any) => ({
                          ...prevState,
                          selectPartsChecked: e.target.checked,
                        }))
                      }
                    >
                      <span>部位情報を附帯する</span>
                    </CheckboxStyled>
                    {medicineInfo?.selectPartsChecked && (
                      <GroupButtons style={{ gap: 8, alignItems: "center" }}>
                        <Button
                          name="部位追加"
                          onClick={(e: any) => {
                            if (e && e.target) {
                              e.target.blur();
                            }
                            handleAddRow();
                          }}
                          background="#FFFFFF"
                          border="1px solid #2AC769"
                          bdr="4px"
                          fontSize={14}
                          padding="3px 6px 3px 4px"
                          color="#2AC769"
                          lineHeight="16px"
                          icon={<PlusIcon fill="#2AC769" />}
                        />
                        <Button
                          name="部位削除"
                          onClick={(e: any) => {
                            if (e && e.target) {
                              e.target.blur();
                            }
                            handleSubtractRow();
                          }}
                          background="#FFFFFF"
                          border="1px solid #FB4E4E"
                          bdr="4px"
                          fontSize={14}
                          color="#FB4E4E"
                          lineHeight="16px"
                          padding="3px 6px 3px 4px"
                          icon={<MinusIcon />}
                        />
                      </GroupButtons>
                    )}
                  </Flex>
                  <SelectedBodyPartsWrapper>
                    {attachPartInformation.length > 0 &&
                      renderSelectBodyParts()}
                  </SelectedBodyPartsWrapper>
                </NotClickSection>
              </div>
            </Flex>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const obj: any = {
    1: <OralMedicine />,
    2: <ExternalMedicine />,
  };

  return (
    <ModalStyled
      type="form"
      title={"処方箋作成"}
      visible={visible}
      onCancel={handleCancel}
      onOk={handleSubmitOK}
    >
      <ContentWrapper>
        <div className="left-block">
          <LeftBlockContent>
            <SearchBlock>
              <div className="title">薬剤検索</div>
              <div className="search-wrapper">
                <div className="input-group">
                  <AutoComplete
                    options={medicineOptions}
                    style={{ width: 399, height: 30, borderRadius: 6 }}
                    onSelect={onSelect}
                    onSearch={(text: any) => setMedicineName(text)}
                    placeholder="入力してください"
                  />
                  <SearchIcon />
                </div>
              </div>
            </SearchBlock>
            {Object.keys(medicineInfo).length > 0 && (
              <React.Fragment>
                <SelectedDrugBlock>
                  <div className="title">選択した薬</div>
                  <div className="first-line">
                    <div className="drug">{medicineInfo?.name}</div>
                    <div className="unit-block">
                      <Input
                        fs={14}
                        lh="24px"
                        height={30}
                        type="number"
                        value={medicineInfo?.amount}
                        onChange={handleOnChangeAmount}
                        error={error["amount"]}
                      />
                      <div style={{ background: "#F2F2F2" }}>
                        <Select
                          width="80px"
                          height="30px"
                          options={unitOptions}
                          value={medicineInfo?.amount_type_id}
                          bdr="0 6px 6px 0"
                          fs={14}
                          onChange={(value: any) =>
                            setMedicineInfo((prevState: any) => ({
                              ...prevState,
                              amount_type_id: value,
                            }))
                          }
                        />
                      </div>
                      {/* <div className="unit">{medicineInfo?.unit}/1日</div> */}
                    </div>
                    <Button
                      onClick={() =>
                        setMedicineInfo((prevState: any) => ({
                          ...prevState,
                          amount: "",
                        }))
                      }
                      background="#FFFFFF"
                      border="1px solid #E0E0E0"
                      bdr="6px"
                      fontSize={14}
                      padding="2px 2px 4px 3px"
                      icon={<ReloadIcon />}
                    />
                    <Button
                      onClick={() => setMedicineInfo({})}
                      background="#FFFFFF"
                      border="1px solid #E0E0E0"
                      bdr="6px"
                      fontSize={14}
                      padding="2px"
                      icon={<DeleteIcon2 />}
                    />
                  </div>
                  <InputBlock>
                    <div className="left">
                      <span>服薬の方法</span>
                    </div>
                    <div className="right">
                      <TakeMedWrapper>
                        <Radio.Group
                          onChange={(e: RadioChangeEvent) => {
                            let params: any = {};
                            if (e.target.value === 1) {
                              params = {
                                basicDosage: 1,
                                dosageTime: ["I", "M", "S"],
                                comment: "",
                                detailDosage: 0,
                                timingSpecification: 1,
                                time: "P",
                                dailyDosageFrequency: 3,
                                maximumDosageFrequencyValue: 6,
                                dosageInterval: "I",
                                supplyType: 1,
                              };
                            } else {
                              params = {
                                timingSpecification: 7,
                                detailDosage: "A",
                                bigNumberValue: 4,
                                time: "P",
                                customValue: 3,
                                maximumDosageFrequencyValue: 2,
                                minimumTimeIntervalValue: "G",
                                additionalInfo: null,
                              };
                            }
                            setMedicineInfo((prevState: any) => ({
                              ...prevState,
                              basicDosage: e.target.value,
                              ...params,
                            }));
                            setBasicDosageRadioList(
                              basicDosageOptions.map((i: any) => {
                                if (i.value !== e.target.value) {
                                  return {
                                    ...i,
                                    disabled: true,
                                  };
                                }
                                return {
                                  ...i,
                                  disabled: false,
                                };
                              })
                            );
                          }}
                          value={medicineInfo?.basicDosage}
                        >
                          <AudioWrapper>
                            {basicDosageRadioList.map((i: any) => {
                              return (
                                <Radio key={i.value} value={i.value}>
                                  <TextRadio disabled={i.disabled}>
                                    {i.name}
                                  </TextRadio>
                                </Radio>
                              );
                            })}
                          </AudioWrapper>
                        </Radio.Group>
                      </TakeMedWrapper>
                    </div>
                  </InputBlock>
                  {medicineInfo?.basicDosage && (
                    <InputBlock>
                      <div className="left">用法</div>
                      {obj[medicineInfo?.basicDosage]}
                    </InputBlock>
                  )}

                  <InputBlock>
                    <div className="left">日数</div>
                    <div className="right">
                      <TakeMedWrapper>
                        <Input
                          fs={14}
                          lh="24px"
                          value={medicineInfo?.supplyValue}
                          width={"100px"}
                          padding="3px 8px 3px 8px"
                          placeholder="入力してください"
                          height={30}
                          type="number"
                          onChange={handleOnChangeSupplyValue}
                          error={error["supplyValue"]}
                        />
                        <Radio.Group
                          onChange={handleOnChangeSupplyType}
                          value={medicineInfo?.supplyType}
                        >
                          <AudioWrapper>
                            {supplyTypeRadioList.map((i: any) => {
                              return (
                                <Radio key={i.id} value={i.value}>
                                  <TextRadio disabled={i.disabled}>
                                    {i.name}
                                  </TextRadio>
                                </Radio>
                              );
                            })}
                          </AudioWrapper>
                        </Radio.Group>
                      </TakeMedWrapper>
                    </div>
                  </InputBlock>
                  <InputBlock>
                    <div className="left">コメント</div>
                    <div className="right">
                      <Input
                        fs={14}
                        lh="24px"
                        value={medicineInfo?.comment}
                        padding="3px 8px 3px 8px"
                        placeholder="入力してください"
                        height={30}
                        onChange={(e: any) =>
                          setMedicineInfo((prevState: any) => ({
                            ...prevState,
                            comment: e.target.value,
                          }))
                        }
                      />
                    </div>
                  </InputBlock>
                </SelectedDrugBlock>
                <ConfirmButtonBlock>
                  <Button
                    onClick={handleCancel}
                    background="#E0E0E0"
                    color="#fff"
                    name={"キャンセル"}
                    border="none"
                    bdr="6px"
                    fontSize={16}
                    lineHeight="16px"
                    padding="13px 24px 11px"
                    fontWeight={700}
                  />

                  <Button
                    onClick={handleCreateMedicine}
                    background="#2AC769"
                    color="#fff"
                    name="登　録"
                    border="none"
                    bdr="6px"
                    fontSize={16}
                    lineHeight="16px"
                    padding="13px 32px 11px"
                    fontWeight={700}
                  />
                </ConfirmButtonBlock>
              </React.Fragment>
            )}
          </LeftBlockContent>
        </div>

        <div className="right-block">
          <RightBlockContent>
            <div className="title">
              処方履歴から薬剤をコピー（用量も入ります）
            </div>
            {medicineList.map((i: any, idx) => {
              return (
                <div className="table" key={idx}>
                  <div className="head">{dateList[idx]}</div>
                  <div className="body">
                    {i.map((ci: any, ciIdx: any) => {
                      return (
                        <div className="item" key={ciIdx}>
                          <div className="medicine">
                            <div className="med-title">{ci.basic_dosage}</div>
                            <div className="med-volume">
                              <div className="left">
                                <LeftArrow
                                  fill="#2F8CAE"
                                  onClick={() => {
                                    let objTest: any = {};
                                    const timeMealCheckbox = [];
                                    const lifeRhythm = [];
                                    let timingMeals = 0;
                                    let additionalTimeSpecification = 0;
                                    let time = 0;
                                    let additionalInfo2 = "";
                                    let customValue = 0;
                                    let maximumDosageFrequency = false;
                                    let minimumTimeInterval = false;
                                    let twoCase = 0;
                                    if (
                                      ci.detail["timing_specification"].code ===
                                      4
                                    ) {
                                      if (ci.detail["event_name"].code === 8) {
                                        twoCase = 1;
                                      } else {
                                        twoCase = 2;
                                      }
                                    }

                                    for (const property in ci.detail) {
                                      if (mealTiming.includes(property)) {
                                        if (ci.detail[property].code > 0) {
                                          timeMealCheckbox.push(
                                            mealTimingJa[property]
                                          );
                                          timingMeals =
                                            ci.detail[property].code;

                                          if (ci.detail[property].code === 6) {
                                            additionalTimeSpecification =
                                              ci.detail[
                                                "additional_time_specification"
                                              ].code;
                                          }
                                        }
                                      }

                                      if (mealTiming2.includes(property)) {
                                        if (ci.detail[property].code > 0) {
                                          lifeRhythm.push(
                                            mealTimingJa2[property]
                                          );
                                          timingMeals =
                                            ci.detail[property].code;

                                          if (ci.detail[property].code === 6) {
                                            time =
                                              ci.detail[
                                                "additional_time_specification"
                                              ].code;
                                          }
                                        }
                                      }

                                      if (
                                        property ===
                                          "maximum_dosage_frequency" &&
                                        ci.detail[property].code
                                      ) {
                                        maximumDosageFrequency = true;
                                      }

                                      if (
                                        property === "minimum_time_interval" &&
                                        ci.detail[property].code
                                      ) {
                                        minimumTimeInterval = true;
                                      }

                                      if (property === "dosage_times") {
                                        objTest = {
                                          ...objTest,
                                          [identification[property]]:
                                            ci.detail[property].codes,
                                        };
                                      } else if (
                                        property ===
                                          "dosage_interval_additional_info" &&
                                        typeof ci.detail[property].code ===
                                          "number"
                                      ) {
                                        additionalInfo2 = "C";
                                        customValue = ci.detail[property].code;
                                      } else if (
                                        property === "event_category"
                                      ) {
                                        objTest = {
                                          ...objTest,
                                          [identification[property]]: parseInt(
                                            ci.detail[property].code
                                          )
                                            ? parseInt(ci.detail[property].code)
                                            : ci.detail[property].code,
                                        };
                                      } else {
                                        objTest = {
                                          ...objTest,
                                          [identification[property]]:
                                            ci.detail[property].code,
                                        };
                                      }
                                    }

                                    const strLength = ci.supply.length;
                                    const splValue = ci.supply.slice(
                                      0,
                                      strLength - 2
                                    );
                                    const splType = ci.supply.slice(-2);
                                    const splTypeObj: any = {
                                      日分: 1,
                                      回分: 2,
                                      総量: 3,
                                    };
                                    setExtraRadioList(
                                      extraOptions.map((i: any) => {
                                        if (i.value !== twoCase) {
                                          return {
                                            ...i,
                                            disabled: true,
                                          };
                                        }
                                        return {
                                          ...i,
                                          disabled: false,
                                        };
                                      })
                                    );
                                    setMedicineInfo({
                                      amount_type_id: ci.amount_type_id,
                                      amount: ci.amount,
                                      name: ci.medicine_name,
                                      unit: ci.medicine_unit,
                                      medicineID: ci.medicine_id,
                                      timeMealCheckbox: timeMealCheckbox,
                                      timingMeals: timingMeals,
                                      time: time,
                                      lifeRhythm: lifeRhythm,
                                      comment: ci.comment,
                                      additionalTimeSpecification:
                                        additionalTimeSpecification,
                                      customValue: customValue,
                                      additionalInfo2: additionalInfo2,
                                      maximumDosageFrequency:
                                        maximumDosageFrequency,
                                      minimumTimeInterval: minimumTimeInterval,
                                      supplyType: splTypeObj[splType],
                                      supplyValue: splValue,
                                      twoCaseValue: twoCase,
                                      ...objTest,
                                    });
                                    setUnitOptions([
                                      {
                                        id: 1,
                                        value: 1,
                                        name: `${ci.medicine_unit}/1日`,
                                      },
                                      {
                                        id: 2,
                                        value: 2,
                                        name: `${ci.medicine_unit}/1回`,
                                      },
                                    ]);
                                  }}
                                />
                                <div>{ci.medicine_name}</div>
                              </div>
                              <div className="right">
                                {ci.amount}
                                {ci.medicine_unit}
                                {ci.amount_type && `/${ci.amount_type}`}
                              </div>
                            </div>
                          </div>
                          <div className="prescription">
                            <div>{ci.usage}</div>
                            <div>{ci.supply}</div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </RightBlockContent>
        </div>
      </ContentWrapper>
    </ModalStyled>
  );
};

export default CreateModal;
