import React, { useState, useEffect, Fragment } from "react";
import { Layout, Menu, Grid, Drawer, Divider } from "antd";
import styled from "styled-components";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import {
  Logo,
  LogoMobile,
  CloseSidebarIcon,
  MenuIcon,
  CloseMenuIcon,
  LogoutIcon,
  LogoMonotone,
  LimitedAdminIcon,
} from "icons";
import { ConfirmModal } from "components";
import { theme } from "theme/theme";
import {
  localStorageHelper,
  removeCookie,
  handleGetTimeByTimeZone,
} from "utils";
import { authTokenKey } from "services/Api";

import { MenuItems, AdminMenuItems } from "./constant";

const { Header, Sider, Content, Footer } = Layout;
const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const WrapperLogo = styled.div`
  display: flex;
  align-items: center;
  ${theme.media.laptopL} {
    width: 200px;
    padding-left: 21px;
    margin-right: 26px;
  }
`;

const HeaderStyled = styled(Header)`
  background: ${theme.colors.white};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  height: 60px;
  width: 100%;
  position: fixed;
  left: 0;
  padding-left: 0;
  padding-right: 0;
  z-index: 10;
  border-bottom: 2px solid #1a2f62;
`;

const LineVertical = styled.div(({ theme }) => ({
  background: theme.colors.background,
  height: 28,
  position: "absolute",
  width: 1,
  left: 0,
  top: 16,
}));

const CloseSidebarText = styled.div(({ theme }) => ({
  fontSize: theme.sizes.md,
  fontWeight: theme.fontWeight.bold,
  marginLeft: 10,
  color: theme.colors.text.secondary,
}));

const CloseSidebarTextMobile = styled.div`
  font-size: ${theme.sizes.sm};
  font-weight: ${theme.fontWeight.bold};
  color: ${theme.colors.text.secondary};
  line-height: 18px;
  text-align: center;
  ${theme.media.tablet} {
    font-size: ${theme.sizes.md};
    line-height: 16px;
  }
  ${theme.media.laptopL} {
    display: none;
  }
`;

const WrapperHeaderLabel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .limited-badge {
    line-height: 9px;
    margin-top: 5px;
  }
  ${theme.media.tablet} {
    flex-direction: row;
    .limited-badge {
      padding-left: 4px;
      margin-top: unset;
    }
  }
`;

const Time = styled.div(({ theme }) => ({
  fontSize: theme.sizes.md,
  fontWeight: theme.fontWeight.regular,
  color: theme.colors.text.secondary,
  marginRight: 24,
}));

const SiderStyled = styled(Sider)(({ theme }) => ({
  background: theme.colors.white,
  marginTop: 60,
  borderRight: `1px solid ${theme.colors.border}`,
  overflowX: "hidden",
  position: "fixed",
  left: 0,
  top: 0,
  bottom: 0,
  ".ant-menu": {
    paddingTop: 16,
    ".ant-menu-item:last-child": {
      "&:hover": {
        color: theme.colors.text.primary,
      },
    },
  },
  ".ant-menu-item": {
    paddingLeft: "22px!important",
  },
  ".ant-menu-submenu-title": {
    paddingLeft: "22px!important",
    height: "auto!important",
  },
  ".ant-menu-item-only-child": {
    paddingLeft: "45px!important",
    height: "auto!important",
  },
  ".ant-menu-submenu-selected": {
    color: theme.colors.textLink,
  },
  ".ant-menu-submenu-selected svg": {
    fill: theme.colors.textLink,
  },
  ".ant-menu-submenu-selected .ant-menu-submenu-arrow": {
    color: theme.colors.textLink,
  },
  ".ant-layout-sider-children": {
    display: "flex",
    flexDirection: "column",
  },
}));

const WrapperLogoMonotone = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 60px;
  padding-bottom: 40px;
  padding-top: 24px;
  ${theme.media.laptop} {
    padding-bottom: 32px;
    margin-bottom: unset;
  }
`;

const FlexItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WrapperHeaderWeb = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  align-items: center;
  margin-top: 10px;
  ${theme.media.laptopL} {
    margin-top: 0;
    display: flex;
    padding: 0;
    justify-content: unset;
    align-items: unset;
  }
`;

const WrapperLeftHeader = styled.div`
  display: none;
  ${theme.media.laptopL} {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 32px 0 12px;
    position: relative;
  }
`;

const MenuIconStyled = styled(MenuIcon)`
  ${theme.media.laptopL} {
    display: none;
  }
`;

const CloseMenuIconStyled = styled(CloseMenuIcon)`
  ${theme.media.laptopL} {
    display: none;
  }
`;

const ContentStyled = styled(Content)`
  padding: 32px 16px 0;
  min-height: 0px;
  background: #f2f2f2;
  ${theme.media.laptopL} {
    padding: 32px 32px 0;
  }
`;

const CopyRight = styled.div`
  text-align: center;
  font-weight: ${theme.fontWeight.medium};
  font-size: ${theme.sizes.xs};
  color: ${theme.colors.icon};
`;

const OpenSidebarIcon = styled(CloseSidebarIcon)`
  cursor: pointer;
  transform: rotate(-180deg);
`;

const FooterStyled = styled(Footer)`
  padding: 16px;
  ${theme.media.desktop} {
    padding: 24px;
  }
`;

const ContainerDrawer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  .ant-menu-submenu-selected {
    color: ${theme.colors.textLink};
  }
  .ant-menu-submenu-selected svg {
    fill: ${theme.colors.textLink};
  }
  .ant-menu-submenu-selected .ant-menu-submenu-arrow {
    color: ${theme.colors.textLink};
  }
`;

const LogOutTooltip = styled.div`
  display: none;
  position: absolute;
  right: 0;
  top: 46px;
  width: max-content;
  background: ${theme.colors.white};
  color: ${theme.colors.textLink};
  font-size: ${theme.sizes.sm};
  font-weight: ${theme.fontWeight.regular};
  line-height: 14px;
  padding: 7px 8px 9px;
  z-index: 100;
  border-radius: 6px;
  border: 1px solid ${theme.colors.border};
  transform-origin: 50% 89.9943px;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.16);
  cursor: pointer;
  &::after {
    content: "";
    position: absolute;
    top: -5px;
    right: 15px;
    height: 7px;
    width: 7px;
    background: ${theme.colors.border};
    transform: rotate(45deg);
  }
  &::before {
    content: "";
    position: absolute;
    top: -3px;
    right: 15px;
    width: 7px;
    height: 7px;
    background: ${theme.colors.white};
    transform: rotate(45deg);
    z-index: 1;
  }
`;

const WrapperLogout = styled.div`
  &:hover {
    div {
      display: block;
    }
  }
`;

const IconButton = styled.div`
  cursor: pointer;
`;

const PopUpWrapper = styled.div`
  padding: 0px 8px 24px;
`;

export const Text = styled.div<IText>(
  ({ size, weight, lnHeight, width, minWidth, color, mg, align, theme }) => ({
    color: color || theme.colors.text.primary,
    fontSize: size || "16px",
    lineHeight: lnHeight || "24px",
    width: width,
    minWidth: minWidth,
    fontWeight: weight || theme.fontWeight.regular,
    margin: mg,
    textAlign: align,
  })
);

export const LayoutStyled = styled(Layout)`
  min-height: 100vh;
  transition: all 0.5s;
  ${theme.media.laptopL} {
    margin-left: 200px;
  }
`;

interface IText {
  size?: number;
  weight?: number;
  lnHeight?: string;
  width?: string;
  minWidth?: string;
  color?: string;
  mg?: string;
  align?: any;
}

interface IItem {
  key: string;
  path: string;
  label?: string;
}

export interface IItems {
  key: string;
  path: string;
  label?: string;
  icon?: React.ReactNode;
  children?: IItem[];
}

const MainLayout: React.FC = ({ children }) => {
  const { t }: any = useTranslation("translation", { keyPrefix: "layout" });
  const { t: t1 }: any = useTranslation("translation", { keyPrefix: "common" });

  const location = useLocation();
  const { push } = useHistory();
  const screens = useBreakpoint();
  const dispatch = useDispatch();
  const url =
    "https://leber.zendesk.com/hc/ja/articles/17752632353049--%E4%BB%95%E6%A7%98%E5%A4%89%E6%9B%B4-%E4%BD%93%E6%B8%A9%E5%A0%B1%E5%91%8A%E6%A9%9F%E8%83%BD%E3%81%AE%E4%BB%BB%E6%84%8F%E5%8C%96%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6";
  const accountRole = localStorageHelper.getItem("role");

  const [isVisible, setIsVisible] = useState(false);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [dateTime, setDateTime] = useState<any>();

  const [openKeyItems, setOpenKeyItems] = useState(["1"]);
  const [openKeys, setOpenKeys] = useState([]);

  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const showDrawer = () => {
    setIsVisible(!isVisible);
  };

  const handleOpenChange = (keys: any) => {
    setOpenKeys(keys);
  };

  const getDateTime = () => {
    const currentLanguage = localStorageHelper.getItem("i18nextLng");
    const currentTimeZone = localStorageHelper.getItem("currentTimeZone");
    const format: any = {
      ja: "YYYY年MM月DD日 HH:mm",
      en: "DD MMMM YYYY HH:mm",
    };

    const time = handleGetTimeByTimeZone(
      format[currentLanguage],
      currentTimeZone
    );
    // if (currentTimeZone) {
    // } else {
    //   time = moment().format(format[currentLanguage]);
    // }

    setDateTime(time);
  };

  const handleHeaderName = () => {
    const currentLanguage = localStorageHelper.getItem("i18nextLng");
    const companyNameJa = localStorageHelper.getItem("company_name");

    if (currentLanguage === "en" && companyNameJa) {
      return companyNameJa.slice(0, companyNameJa.length - 4) + " admin screen";
    }

    // return companyNameJa;
    return "オンライン診療アドミン";
  };

  const handleLogout = () => {
    removeCookie(authTokenKey);
    dispatch({
      type: "LOGOUT",
    });
    localStorageHelper.removeItem("persist:root");
    window.localStorage.clear();
    push("/login");
  };

  const handleMarginLayout = () => {
    if (window.innerWidth < 1200) {
      return {
        marginLeft: "unset",
      };
    } else {
      return { marginLeft: !isCollapsed ? 200 : 80 };
    }
  };

  // const [menuItems, setMenuItem] = useState<any>([]);

  const menuItems = React.useMemo(() => AdminMenuItems, []);

  // useEffect(() => {
  //   if (accountRole) {
  //     const dashboardMenuItems = accountRole === "2" ? AdminMenuItems : MenuItems;
  //     setMenuItem(dashboardMenuItems);
  //   }
  // }, [accountRole]);

  // useEffect(() => {
  //   const dashboardMenuItems = AdminMenuItems;
  //   setMenuItem(dashboardMenuItems);
  // }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      getDateTime();
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    let currentItem: any = {};
    menuItems.forEach((_item: IItems) => {
      if (_item.children) {
        const item = _item.children.find((itemChild: IItems) =>
          location.pathname.startsWith(itemChild.path)
        );
        if (item) {
          currentItem = item;
        }
      }
      if (location.pathname === _item.path) {
        currentItem = _item;
      }
      if (location.pathname.startsWith("/reservation-details")) {
        currentItem = menuItems[1];
      }
    });

    const newOpenKeyItems = [];
    newOpenKeyItems.push(currentItem.key);

    setOpenKeyItems(newOpenKeyItems);
  }, [location, menuItems]);

  const header = React.useMemo(
    () => (
      <HeaderStyled className="site-layout-background">
        <WrapperHeaderWeb>
          <WrapperLogo>
            {screens.sm ? (
              <Logo width="95px" height="16px" />
            ) : (
              <LogoMobile height="16px" />
            )}
          </WrapperLogo>
          <WrapperHeaderLabel>
            <CloseSidebarTextMobile>
              {handleHeaderName()}
            </CloseSidebarTextMobile>
            {!screens.lg && accountRole === "2" && (
              <div className="limited-badge">
                <LimitedAdminIcon />
              </div>
            )}
          </WrapperHeaderLabel>
          {isVisible ? (
            <CloseMenuIconStyled onClick={showDrawer} />
          ) : (
            <MenuIconStyled onClick={showDrawer} />
          )}
          <WrapperLeftHeader>
            <LineVertical />
            <FlexItem>
              {isCollapsed ? (
                <OpenSidebarIcon onClick={handleToggle} />
              ) : (
                <CloseSidebarIcon
                  onClick={handleToggle}
                  style={{ cursor: "pointer" }}
                />
              )}
              <CloseSidebarText>{handleHeaderName()}</CloseSidebarText>
              {accountRole === "2" && (
                <div style={{ lineHeight: "13px", paddingLeft: "4px" }}>
                  <LimitedAdminIcon />
                </div>
              )}
            </FlexItem>
            <FlexItem>
              <Time>{dateTime}</Time>
              <div style={{ position: "relative", height: 40 }}>
                <WrapperLogout>
                  <IconButton onClick={() => setIsVisibleModal(true)}>
                    <LogoutIcon />
                  </IconButton>
                  <LogOutTooltip onClick={() => setIsVisibleModal(true)}>
                    {t("logout-label")}
                  </LogOutTooltip>
                </WrapperLogout>
              </div>
            </FlexItem>
          </WrapperLeftHeader>
        </WrapperHeaderWeb>
      </HeaderStyled>
    ),
    [isCollapsed]
  );

  return (
    <Fragment>
      <Layout>
        {header}
        <div style={{ height: "60px", background: "transparent" }}></div>
        <Layout>
          {screens.xl && (
            <SiderStyled
              trigger={null}
              collapsible
              collapsed={isCollapsed}
              breakpoint="xl"
            >
              <Menu
                theme="light"
                mode="inline"
                openKeys={openKeys}
                selectedKeys={openKeyItems}
                onOpenChange={handleOpenChange}
              >
                {menuItems.map((item: any) => {
                  if (item.children?.length) {
                    return (
                      <SubMenu
                        key={item.key}
                        title={t(item.label)}
                        icon={item.icon}
                      >
                        {item.children?.map((subItem: any) => (
                          <Menu.Item key={subItem.key}>
                            <Link to={subItem.path}>{t(subItem.label)}</Link>
                          </Menu.Item>
                        ))}
                      </SubMenu>
                    );
                  }
                  return (
                    <Menu.Item key={item.key} icon={item.icon}>
                      <Link to={item.path}>{t(item.label)}</Link>
                    </Menu.Item>
                  );
                })}
              </Menu>
              <WrapperLogoMonotone>
                <LogoMonotone />
              </WrapperLogoMonotone>
            </SiderStyled>
          )}
          <LayoutStyled className="site-layout" style={handleMarginLayout()}>
            <ContentStyled className="site-layout-background site-layout-background-animation">
              {children}
            </ContentStyled>
            <FooterStyled>
              <CopyRight>© LEBER, Inc. All Rights Reserved.</CopyRight>
            </FooterStyled>
          </LayoutStyled>
          <Drawer
            title={
              <Row>
                <Col
                  xs={8}
                  sm={10}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "right",
                  }}
                >
                  <LogoutIcon onClick={() => setIsVisibleModal(true)} />
                </Col>
              </Row>
            }
            onClose={showDrawer}
            visible={isVisible}
          >
            <ContainerDrawer>
              <Menu theme="light" mode="inline" selectedKeys={openKeyItems}>
                {menuItems.map((item: any) => {
                  if (item.children?.length) {
                    return (
                      <SubMenu
                        key={item.key}
                        title={t(item.label)}
                        icon={item.icon}
                      >
                        {item.children?.map((subItem: any) => (
                          <Menu.Item key={subItem.key}>
                            <Link to={subItem.path}>{t(subItem.label)}</Link>
                          </Menu.Item>
                        ))}
                      </SubMenu>
                    );
                  }
                  return (
                    <Menu.Item key={item.key} icon={item.icon}>
                      <Link to={item.path}>{t(item.label)}</Link>
                    </Menu.Item>
                  );
                })}
              </Menu>
              <WrapperLogoMonotone>
                <LogoMonotone />
              </WrapperLogoMonotone>
            </ContainerDrawer>
          </Drawer>
          {isVisibleModal && (
            <ConfirmModal
              isVisible={isVisibleModal}
              close={() => {
                setIsVisibleModal(false);
              }}
              ok={handleLogout}
            >
              {t1("logout-description")}
            </ConfirmModal>
          )}
        </Layout>
      </Layout>
    </Fragment>
  );
};

export default MainLayout;
