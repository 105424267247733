import styled, { DefaultTheme } from "styled-components";
import { theme } from "theme/theme";

export const WrapperErrorMessage = styled.div`
  position: absolute;
  bottom: -6px;
  width: 100%;
`;

export const WrapperAreaErrorMessage = styled(WrapperErrorMessage)`
  bottom: -2px;
`;

export const ErrorMessage = styled.div`
  position: absolute;
  display: block;
  background: ${theme.colors.white};
  color: ${theme.colors.error};
  font-size: ${theme.sizes.sm};
  font-weight: ${theme.fontWeight.regular};
  line-height: 16px;
  padding: 9px 8px;
  z-index: 1;
  border-radius: 8px;
  border: 1px solid ${theme.colors.error};
  transform-origin: 50% 89.9943px;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.16);
  &::after {
    content: "";
    position: absolute;
    top: -5px;
    left: 18px;
    height: 7px;
    width: 7px;
    background: ${theme.colors.error};
    transform: rotate(45deg);
  }
  &::before {
    content: "";
    position: absolute;
    top: -4px;
    left: 17px;
    width: 6px;
    height: 10px;
    background: ${theme.colors.white};
    transform: rotate(45deg);
    z-index: 1;
  }
`;

export const LinkStyled = (theme: DefaultTheme) => {
  return {
    fontSize: theme.sizes.xs,
    fontWeight: theme.fontWeight.medium,
    lineHeight: "12px",
    color: theme.colors.textLink,
    textDecoration: "underline",
    cursor: "pointer",
  };
};

export const NoDataMessage = styled.div`
  margin-top: 40px;
  margin-left: 16px;
  font-weight: ${theme.fontWeight.regular};
  font-size: ${theme.sizes.md};
  line-height: 18px;
`;
