import { IResponse } from "models";

export enum ActionType {
  AGREE_HOSPITALS_LIST = "action/AGREE_HOSPITALS_LIST",
  AGREE_HOSPITALS_LIST_SUCCESS = "action/AGREE_HOSPITALS_LIST_SUCCESS",
  AGREE_HOSPITALS_LIST_ERROR = "action/AGREE_HOSPITALS_LIST_ERROR",
  AGREE_HOSPITALS_LIST_RESET = "action/AGREE_HOSPITALS_LIST_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface AgreeHospitalsPayload {
  name: string;
  page: number;
  per_page: number;
}

export interface IAgreeHospital {
  id: number;
  name: string;
}

export interface AgreeHospitalsListResponse extends IResponse {
  total_pages: number;
  current_page: number;
  next_page: number;
  prev_page: number;
  result: IAgreeHospital[];
}
