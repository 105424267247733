import { put, takeLatest, fork, call } from "redux-saga/effects";

import {
  ActionType,
  AgreeSuperCitiesPayload,
  AgreeSuperCitiesListResponse,
} from "models/hospitals/agreeSuperCitiesList";
import { httpStatus } from "configs/httpStatus";
import { getAgreeSuperCities } from "services/hospitals";

import {
  handleSuccessAction,
  handleErrorAction,
} from "./agreeSuperCitiesActions";

function* getAgreeSuperCitiesSaga({
  payload,
}: {
  payload: AgreeSuperCitiesPayload;
}) {
  try {
    const response: AgreeSuperCitiesListResponse = yield call(
      getAgreeSuperCities,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccessAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetAgreeSuperCitiesWatcher() {
  yield takeLatest(
    ActionType.AGREE_SUPER_CITY_LIST as any,
    getAgreeSuperCitiesSaga
  );
}

export default [fork(onGetAgreeSuperCitiesWatcher)];
