import { createApiCall, MethodType, URI } from "../Api";

import { IDeleteDoctorPayload } from "models/doctor/deleteDoctor";
import { ICreateDoctorPayload } from "models/doctor/createDoctor";
import { IEditDoctorPayload } from "models/doctor/editDoctor";

export const fetchDoctorList = (payload: any) => {
  return createApiCall(MethodType.GET, URI.DOCTOR.DOCTOR_LIST, payload);
};

export const deleteDoctor = (payload: IDeleteDoctorPayload) => {
  return createApiCall(
    MethodType.DELETE,
    URI.DOCTOR.DELETE_DOCTOR(payload.doctor_id)
  );
};

export const createDoctor = (payload: ICreateDoctorPayload) => {
  return createApiCall(MethodType.POST, URI.DOCTOR.CREATE_DOCTOR, payload);
};

export const editDoctor = (payload: IEditDoctorPayload) => {
  return createApiCall(MethodType.PUT, URI.DOCTOR.EDIT_DOCTOR(payload.id), {
    doctor: payload.doctor,
  });
};
