import { createApiCall, MethodType, URI } from "../Api";

import { ICreateHospitalPayload } from "models/hospitals/createHospital";
import { IUpdateHospitalPayload } from "models/hospitals/updateHospital";
import { AgreeSuperCitiesPayload } from "models/hospitals/agreeSuperCitiesList";
import { AgreeHospitalsPayload } from "models/hospitals/agreeHospitalsList";
import { IDeleteHospitalPayload } from "models/hospitals/deleteHospital";

export const getHospitalList = () => {
  return createApiCall(MethodType.GET, URI.DOCTOR.GET_HOSPITALS);
};

export const createHospital = (payload: ICreateHospitalPayload) => {
  return createApiCall(MethodType.POST, URI.HOSPITALS.CREATE_HOSPITAL, payload);
};

export const updateHospital = (payload: IUpdateHospitalPayload) => {
  return createApiCall(
    MethodType.PATCH,
    URI.HOSPITALS.UPDATE_HOSPITAL(payload.hospital_id),
    {
      hospital: payload.hospital,
    }
  );
};

export const getAgreeSuperCities = (payload: AgreeSuperCitiesPayload) => {
  return createApiCall(
    MethodType.GET,
    URI.HOSPITALS.GET_AGREE_SUPER_CITIES,
    payload
  );
};

export const getAgreeHospitals = (payload: AgreeHospitalsPayload) => {
  return createApiCall(
    MethodType.GET,
    URI.HOSPITALS.GET_AGREE_HOSPITAL,
    payload
  );
};

export const deleteHospital = (payload: IDeleteHospitalPayload) => {
  return createApiCall(
    MethodType.DELETE,
    URI.HOSPITALS.DELETE_HOSPITAL(payload.hospital_id)
  );
};
