import {
  IUpdateHospitalPayload,
  ActionType,
  UpdateHospitalResponse,
} from "models/hospitals/updateHospital";

export const updateHospitalAction = (payload: IUpdateHospitalPayload) => {
  return {
    type: ActionType.UPDATE_HOSPITAL,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.UPDATE_HOSPITAL_ERROR,
    payload,
  };
};

export const handleSuccessAction = (payload: UpdateHospitalResponse) => {
  return {
    type: ActionType.UPDATE_HOSPITAL_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.UPDATE_HOSPITAL_ERROR,
  };
};
