import React, { useState, useCallback, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, notification } from "antd";
import { registerLocale } from "react-datepicker";
import ja from "date-fns/locale/ja";
import FullCalendar, {
  DateSelectArg,
  EventApi,
  EventClickArg,
  CalendarApi,
  DatesSetArg,
  DateInput,
} from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import allLocales from "@fullcalendar/core/locales-all";
import interactionPlugin from "@fullcalendar/interaction";
// import { INITIAL_EVENTS, createEventId } from "./event-utils";
import timeGridPlugin from "@fullcalendar/timegrid";
import { EventInput } from "@fullcalendar/react";
import moment from "moment";
// import { Tooltip } from "bootstrap";
import { Tooltip } from "bootstrap";

import { IOption } from "components/select/Select";
import { Button, Select, Box, Input } from "components";
import {
  AttentionIcon,
  MeetIcon,
  ConfirmFailIcon,
  SendCompleteIcon,
  DeleteCircle,
} from "icons";
import { uuid, uuid2, returnGender } from "utils";
import {
  GetDoctorsListActionCreators,
  GetMonthSchedulesActionCreators,
  GetWeekSchedulesActionCreators,
  GetAppointmentsListActionCreators,
  GetDoctorAppointmentsActionCreators,
  GetAppointmentDetailsActionCreators,
  PostDoctorSchedulesActionCreators,
  DeleteScheduleActionCreators,
  CreateBulkSchedulesActionCreators,
  GetMonthAppointmentsActionCreators,
  CancelAppointmentActionCreators,
} from "redux/rootActions";
import { IDoctor } from "models/doctor/doctorsList";
import { IMonthSchedule } from "models/schedules/monthSchedules";
import { IWeekSchedule } from "models/schedules/weekSchedules";
import { IDoctorAppointment } from "models/appointments/doctorAppointmentsList";
import { IAppointmentDetails } from "models/appointments/appointmentDetails";
import { IAppointmentListItem } from "models/appointments/appointmentsList";
import { IDoctorSchedule } from "models/schedules/createBulkSchedules";
import { IMonthAppointmentListItem } from "models/appointments/monthAppointmentsList";
import {
  selectIsLoading,
  selectErrorMessage,
} from "redux/authentication/authenticationStates";
import {
  selectIsLoadingDoctors,
  doctorsListDataResponse,
} from "redux/doctor/doctorsList/doctorsListState";
import {
  selectIsLoadingMonthSchedules,
  monthSchedulesResponse,
} from "redux/schedules/monthSchedules/monthSchedulesStates";
import {
  selectIsLoadingAppointmentsList,
  selectAppointmentsListErrorMessage,
  appointmentsListResponse,
} from "redux/appointments/appointmentsList/appointmentsListStates";
import {
  selectIsLoadingDoctorAppointments,
  selectDoctorAppointmentsErrorMessage,
  doctorAppointmentsResponse,
} from "redux/appointments/doctorAppointments/doctorAppointmentsStates";
import {
  selectIsLoadingWeekSchedules,
  weekSchedulesResponse,
} from "redux/schedules/weekSchedules/weekSchedulesStates";
import {
  selectIsLoadingAppointmentDetails,
  selectAppointmentDetailsErrorMessage,
  appointmentDetailsResponse,
} from "redux/appointments/appointmentDetails/appointmentDetailsStates";
import {
  selectIsPostingDoctorSchedules,
  postDoctorSchedulesErrorMessage,
  postDoctorSchedulesDataResponse,
} from "redux/schedules/postDoctorSchedules/postDoctorSchedulesStates";
import {
  selectIsCreatingBulkSchedules,
  createBulkSchedulesErrorMessage,
  createBulkSchedulesDataResponse,
} from "redux/schedules/createBulkSchedules/createBulkSchedulesStates";
import {
  selectIsDeletingSchedule,
  deleteScheduleDataResponse,
  deleteScheduleErrorMessage,
} from "redux/schedules/deleteSchedule/deleteScheduleStates";
import {
  selectIsLoadingMonthAppointmentsList,
  selectmonthAppointmentsListErrorMessage,
  monthAppointmentsListResponse,
} from "redux/appointments/monthAppointments/monthAppointmentsStates";
import {
  selectIsLoadingCancelAppointment,
  selectCancelAppointmentErrorMessage,
  cancelAppointmentDataResponse,
} from "redux/appointments/cancelAppointment/cancelAppointmentStates";

import {
  WrapperSchedule,
  Alert,
  AlertMobile,
  Tooltip4AlertMobile,
  WrapperFullCalendar,
  WrapperModal,
  ScheduleInfo,
  DoctorName,
  Time,
  ScheduleInfoTop,
  ScheduleInfoBottom,
  WrapperBtnFooter,
  Line,
  WrapperInfo,
  WrapperCard,
  Card,
  GroupCards,
  Link,
  Bold,
  WrapperDoctor,
  ScheduleTableStyledAdmin,
  ScheduleTitle,
  ModalStyledAdmin,
  VideoModalContentWrapper,
  ScheduleTooltipWrapper,
  ErrorMessage,
  StyledLink,
  CancelModalStyled,
  WrapperContentModal,
  CancelModalWrapperFooter,
} from "./Schedule.style";
import { Modal, Image } from "./components";
registerLocale("ja", ja);

let tooltipInstance: any = null;
let eventGuid = 0;
const todayStr = new Date().toISOString().replace(/T.*$/, ""); // YYYY-MM-DD of today
export const createEventId = () => String(eventGuid++);

const hourOptions: any = [];

for (let i = 0; i <= 24; i++) {
  hourOptions.push({
    id: i,
    value: i < 10 ? `0${i}` : `${i}`,
    name: i < 10 ? `0${i}` : `${i}`,
  });
}

export const minutesOptions = [
  {
    id: 0,
    value: "00",
    name: "00",
  },
  {
    id: 30,
    value: "30",
    name: "30",
  },
];

const { useBreakpoint } = Grid;

interface ICustomEvent extends EventInput {
  doctorId?: number;
  date?: string;
}

interface ICustomDoctorSchedule extends IDoctorSchedule {
  eventId?: number;
}

interface IScheduleTime {
  startHour: string;
  startMinute: string;
  endHour: string;
  endMinute: string;
}

interface DisplayPictures {
  insurance: boolean;
  id: boolean;
  subsidy1: boolean;
  subsidy2: boolean;
}

const ScheduleManagementAdmin: React.FC = () => {
  const dispatch = useDispatch();
  const calendarRef = useRef<any>(null);

  const isLoading = useSelector(selectIsLoading);
  const isLoadingMonthSchedules = useSelector(selectIsLoadingMonthSchedules);
  const isLoadingWeekSchedules = useSelector(selectIsLoadingWeekSchedules);
  const isLoadingDoctorAppointments = useSelector(
    selectIsLoadingDoctorAppointments
  );
  const isLoadingAppointmentsList = useSelector(
    selectIsLoadingAppointmentsList
  );
  const isLoadingDoctors = useSelector(selectIsLoadingDoctors);
  const isLoadingAppointmentDetails = useSelector(
    selectIsLoadingAppointmentDetails
  );
  const isPostingDoctorSchedules = useSelector(selectIsPostingDoctorSchedules);
  const isCreatingBulkDoctorSchedules = useSelector(
    selectIsCreatingBulkSchedules
  );
  const isLoadingMonthAppointmentList = useSelector(
    selectIsLoadingMonthAppointmentsList
  );
  const isCancelingAppointment = useSelector(selectIsLoadingCancelAppointment);

  const DoctorAppointmentsErrorMessage = useSelector(
    selectDoctorAppointmentsErrorMessage
  );
  const AppointmentsListErrorMessage = useSelector(
    selectAppointmentsListErrorMessage
  );
  const PostDoctorSchedulesErrorMessage = useSelector(
    postDoctorSchedulesErrorMessage
  );
  const CreatingBulkDoctorSchedulesErrorMessage = useSelector(
    createBulkSchedulesErrorMessage
  );
  const DeleteScheduleErrorMessage = useSelector(deleteScheduleErrorMessage);
  const monthAppointmentListError = useSelector(
    selectmonthAppointmentsListErrorMessage
  );
  const appointmentDetailsErrorMessage = useSelector(
    selectAppointmentDetailsErrorMessage
  );
  const cancelAppointmentError = useSelector(
    selectCancelAppointmentErrorMessage
  );

  const doctorList = useSelector(doctorsListDataResponse);
  const monthSchedulesData = useSelector(monthSchedulesResponse);
  const weekSchedulesData = useSelector(weekSchedulesResponse);
  const appointmentsListData = useSelector(appointmentsListResponse);
  const doctorAppointmentsData = useSelector(doctorAppointmentsResponse);
  const appointmentDetailsData = useSelector(appointmentDetailsResponse);
  const postDoctorSchedulesData = useSelector(postDoctorSchedulesDataResponse);
  const createBulkDoctorSchedulesData = useSelector(
    createBulkSchedulesDataResponse
  );
  const deleteScheduleData = useSelector(deleteScheduleDataResponse);
  const monthAppointmentsListData = useSelector(monthAppointmentsListResponse);
  const cancelAppointmentData = useSelector(cancelAppointmentDataResponse);

  const [isChecked, setIsChecked] = useState(false);
  const [isVisibleTooltip, setIsVisibleTooltip] = useState(false);
  const [isVisible4Schedule, setIsVisible4Schedule] = useState<boolean>(false);
  const [isVisible4UserInfo, setIsVisible4UserInfo] = useState(false);
  const [currentEvents, setCurrentEvents] = useState<EventApi[]>([]);
  const [createVideoVisible, setCreateVideoVisisble] = useState<{
    visible: boolean;
    link: string;
  }>({
    visible: false,
    link: "",
  });
  const [displayMode, setDisplayMode] = useState<"month" | "week">("month");
  const [doctorOptions, setDoctorOptions] = useState<IOption[]>([]);
  const [selectedDoctor, setSelectedDoctor] = useState<any>();
  const [selectedAppointment, setSelectedAppointment] = useState<any>();
  const [monthSchedulesParams, setMonthSchedulesParams] = useState<{
    start_date: string;
    end_date: string;
  }>({
    start_date: "",
    // end_date: moment(new Date()).endOf("M").format("YYYY-MM-DD"),
    end_date: "",
  });
  const [weekSchedulesParams, setWeekSchedulesParams] = useState<{
    start_date: string;
    end_date: string;
  }>({
    start_date: "",
    // start_date: moment(new Date()).startOf("W").format("YYYY-MM-DD"),
    end_date: "",
  });
  const [timeArray, setTimeArray] = useState<any>();
  const [eventsArray, setEventsArray] = useState<any>();
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [schedulesTime, setSchedulesTime] = useState<any>({});
  const [errorTemplate, setErrorTemplate] = useState<any>({});
  const [doctorSchedules, setDoctorSchedules] = useState<
    ICustomDoctorSchedule[]
  >([]);
  const [currentMonth, setCurrentMonth] = useState<number>();
  const [currentYear, setCurrentYear] = useState<number>();
  const [appointmentDetails, setAppointmentDetails] =
    useState<IAppointmentDetails>();
  const [visibleImages, setVisibleImages] = useState<DisplayPictures>({
    insurance: false,
    id: false,
    subsidy1: false,
    subsidy2: false,
  });
  const [dayEvents, setDayEvents] = useState<ICustomEvent[]>([]);
  const [appointmentListByMonth, setAppointmentListByMonth] = useState<any[]>(
    []
  );
  const [events, setEvents] = useState<ICustomEvent[]>([]);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [cancelAppointment, setCancelAppointment] = useState<any>(undefined);

  const toggleVisibleVideoCreate = () => {
    setCreateVideoVisisble({
      visible: !createVideoVisible.visible,
      link: "",
    });
  };

  const handleIsPastDate = (startDate: any) => {
    const currentDate = moment(new Date()).format("YYYY-MM-DD");
    const eventDate = moment(startDate).format("YYYY-MM-DD");
    // if (eventDate < currentDate) return true;
    // if (currentDate >= eventDate) return true;

    return eventDate < currentDate;
  };

  const handleEvents = useCallback(
    (events: EventApi[]) => setCurrentEvents(events),
    []
  );
  const [selectedEvents, setSelectedEvents] = useState<ICustomEvent[]>([]);

  const returnDoctorName = () => {
    if (!selectedDoctor || !doctorOptions.length) return;
    return doctorOptions.find((item: IOption) => item.id === selectedDoctor)
      ?.name;
  };

  const returnToday = () => {
    if (calendarRef && calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.today();
    }
  };

  const returnTitle = useCallback(() => {
    if (calendarRef && calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      return calendarApi.currentDataManager.data.viewTitle;
    }
  }, [calendarRef]);

  const returnPostalCode = (postalCode: string) => {
    return `${postalCode.substring(0, 3)}${
      postalCode.substring(3) && `-${postalCode.substring(3)}`
    }`;
  };

  const returnPhone = (phone: string) => {
    return `${phone.substring(0, 1)}${
      phone.substring(1, 5) && `-${phone.substring(1, 5)}`
    }${phone.substring(5) && `-${phone.substring(5)}`}`;
  };

  const handleChangeViewMonth = (dateClickInfo: any) => {
    if (calendarRef && calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.changeView("dayGridMonth", dateClickInfo.date);
      dispatch(GetWeekSchedulesActionCreators.handleResetAction());
      dispatch(GetDoctorAppointmentsActionCreators.handleResetAction());
      setSelectedDoctor(undefined);
      setEvents([]);
      setDayEvents([]);
      setSelectedEvents([]);
      setDisplayMode("month");
    }
  };

  const handleChangeViewWeek = (dateClickInfo: any) => {
    if (calendarRef && calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.changeView("timeGridWeek", dateClickInfo.date);
      dispatch(GetMonthSchedulesActionCreators.handleResetAction());
      // dispatch(GetAppointmentsListActionCreators.handleResetAction());
      dispatch(GetMonthAppointmentsActionCreators.handleResetAction());
      setSelectedDoctor(undefined);
      setEvents([]);
      setDayEvents([]);
      setSelectedEvents([]);
      setDisplayMode("week");
    }
  };

  const returnMonth = (month: number) => {
    if (month > 12) {
      return month - 12;
    }
    return month;
  };

  const handleDateRangeChange = (dateSets: DatesSetArg) => {
    if (dateSets.view.type === "timeGridWeek") {
      setWeekSchedulesParams({
        start_date: dateSets.startStr,
        end_date: dateSets.endStr,
      });
    } else {
      const monthCurrent: number =
        moment(dateSets.start).month() > moment(dateSets.end).month()
          ? returnMonth(
              Math.floor(
                (moment(dateSets.start).month() +
                  moment(dateSets.end).month() +
                  14) /
                  2
              )
            )
          : Math.floor(
              (moment(dateSets.start).month() +
                moment(dateSets.end).month() +
                2) /
                2
            );
      switch (monthCurrent) {
        case 12:
          setCurrentYear(moment(dateSets.start).year());
          break;
        case 1:
          setCurrentYear(moment(dateSets.end).year());
          break;
        default:
          setCurrentYear(
            Math.floor(
              (moment(dateSets.start).year() + moment(dateSets.end).year()) / 2
            )
          );
      }
      setCurrentMonth(monthCurrent);
      setMonthSchedulesParams({
        start_date: dateSets.startStr,
        end_date: dateSets.endStr,
      });
    }
  };

  const findDoctorId = (eventId: string) => {
    if (!eventsArray || !Object(eventsArray) || !parseInt(eventId)) {
      return;
    } else {
      const keys = Object.keys(eventsArray);
      return keys.find((key: string) =>
        eventsArray[key].includes(parseInt(eventId))
      );
    }
  };

  const findDateArray = (doctorId: any, dateId: any) => {
    if (!timeArray || !dateId || !timeArray[doctorId][dateId]) {
      return;
    }
    return timeArray[doctorId][dateId];
  };

  const returnStartTime = (time: string) => {
    return time.split("〜")[0];
  };

  const returnEndTime = (time: string) => {
    return time.split("〜")[1];
  };

  const recurTimeFilter = (index: number, inputArr: any, outputArr: any) => {
    if (index >= inputArr.length) return;
  };

  const shortenTime = (timeArray: any) => {
    if (timeArray && timeArray.length) {
      const newArray: any = [];
      const index = 0;
    }
  };

  const returnPlacement = (date: number, month: number, arrLength: number) => {
    if (currentMonth) {
      if (month < currentMonth || (month === currentMonth && date <= 14)) {
        return "bottom";
      }
      if (month > currentMonth) {
        if (arrLength >= 36) {
          return "bottom";
        }
        return "top";
      }
      if (month === currentMonth && date > 14) {
        if (arrLength >= 30) {
          return "bottom";
        }
        return "top";
      }
    }

    return "auto";
  };

  const handleMouseEnter = (info: any) => {
    if (info.event.title && displayMode === "month") {
      // findDateArray(
      //   findDoctorId(info.event.id),
      //   moment(info.event.startStr).format("YYYY-MM-DD").replaceAll("-", "")
      // );

      const arrLength = findDateArray(
        findDoctorId(info.event.id),
        moment(info.event.startStr).format("YYYY-MM-DD").replaceAll("-", "")
      ).length;
      tooltipInstance = new Tooltip(info.el, {
        title: `${findDateArray(
          findDoctorId(info.event.id),
          moment(info.event.startStr).format("YYYY-MM-DD").replaceAll("-", "")
        )
          .sort((a: any, b: any) => {
            if (returnStartTime(a) < returnStartTime(b)) {
              return -1;
            } else if (returnStartTime(b) > returnStartTime(a)) {
              return 1;
            } else {
              return 0;
            }
          })
          .map((item: any) => `${item}<br/>`)}`.replaceAll(",", ""),
        html: true,
        // placement:
        //   moment(info.event.startStr).date() > 14 &&
        //   moment(info.event.startStr).month() === currentMonth
        //     ? "auto"
        //     : "bottom",
        placement: returnPlacement(
          moment(info.event.startStr).date(),
          moment(info.event.startStr).month() + 1,
          arrLength
        ),
        trigger: "hover",
        container: "body",
        customClass: "tooltip-custom",
      });

      tooltipInstance.show();
    }
  };

  const disposeTooltip = useCallback(() => {
    const allToolTip = document.querySelectorAll(".tooltip");
    if (tooltipInstance && displayMode === "month") {
      tooltipInstance.dispose();
      tooltipInstance = null;
    }
    if (allToolTip && allToolTip.length) {
      for (let i = 0; i < allToolTip.length; i++) {
        allToolTip[i].remove();
      }
    }
  }, [tooltipInstance]);

  const handleMouseLeave = (info: any) => {
    if (
      info &&
      info.jsEvent &&
      info.jsEvent.relatedTarget &&
      info.jsEvent.relatedTarget.className
    ) {
      const classList: any[] = info.jsEvent.relatedTarget.className.split(" ");
      if (
        classList &&
        classList.length > 0 &&
        !classList.includes("tooltip") &&
        !classList.includes("show") &&
        !classList.includes("fade") &&
        !classList.includes("tooltip-inner")
      ) {
        disposeTooltip();
      }
    }
  };

  useEffect(() => {
    const handleMouseLeave = (event: any) => {
      if (
        event &&
        event.target &&
        event.target.classList &&
        event.target.classList.contains("tooltip-custom")
      ) {
        const classList: any[] = event.relatedTarget.className.split(" ");
        if (
          classList &&
          classList.length > 0 &&
          ((!classList.includes("fc-daygrid-event") &&
            !classList.includes("fc-daygrid-dot-event") &&
            !classList.includes("fc-event")) ||
            !classList.includes("customized-event"))
        ) {
          disposeTooltip();
        }
      }
    };

    document.addEventListener("mouseleave", handleMouseLeave, true);

    return () => {
      document.removeEventListener("mouseleave", handleMouseLeave, true);
    };
  }, []);

  const handleOnChangeDoctor = (value: any) => {
    setSelectedDoctor(value);
    // dispatch();
  };

  const returnEndtime = (start?: DateInput, end?: DateInput) => {
    const startString = moment(start).format("YYYY-MM-DD");
    const endString = moment(end).format("YYYY-MM-DD");
    if (startString !== endString) {
      // return moment(start).endOf("D").format("HH:mm");
      return "24:00";
    }
    return moment(end).format("HH:mm");
  };

  const handlePostDoctorSchedules = () => {
    if (selectedEvents.length && selectedDoctor) {
      const lastEvent = selectedEvents[0];
      dispatch(
        PostDoctorSchedulesActionCreators.postDoctorSchedulesAction({
          doctor_id: selectedDoctor,
          payload: {
            schedule: {
              schedule_date: moment(lastEvent.start).format("YYYY-MM-DD"),
              start_time: moment(lastEvent.start).format("HH:mm"),
              end_time: returnEndtime(lastEvent.start, lastEvent.end),
            },
          },
        })
      );
    }
  };

  const handleCreateBulkDoctorSchedules = () => {
    if (doctorSchedules && doctorSchedules.length) {
      // const tempDoctorSchedules: IDoctorSchedule[] = [];
      // doctorSchedules.map((schedule: ICustomDoctorSchedule) => {
      //   tempDoctorSchedules.push({
      //     doctor_id: schedule.doctor_id,
      //     schedule: {
      //       ...schedule.schedule,
      //     },
      //   });
      // });
      dispatch(
        CreateBulkSchedulesActionCreators.createBulkSchedulesAction({
          schedules: doctorSchedules,
        })
      );
      setIsVisible4Schedule(false);
      setSelectedEvents([]);
    }
  };

  const handleToggleTooltip = () => {
    setIsVisibleTooltip(!isVisibleTooltip);
  };

  const screens = useBreakpoint();

  const onChangeCheckBox = () => {
    setIsChecked(!isChecked);
  };

  const returnVideoButton = (link: string) => {
    // if (link) {
    //   return (
    //     <Button
    //       onClick={() =>
    //         setCreateVideoVisisble({
    //           visible: true,
    //           sent: link,
    //         })
    //       }
    //       background="#2F8CAE"
    //       color="#fff"
    //       name="確 認"
    //       border="none"
    //       bdr="4px"
    //       fontSize={16}
    //       lineHeight="15px"
    //       padding="8px 16px 7px"
    //       fontWeight={400}
    //     />
    //   );
    // } else {
    //   return (
    //     <Button
    //       onClick={() =>
    //         setCreateVideoVisisble({
    //           visible: true,
    //           sent: false,
    //         })
    //       }
    //       background="#2AC769"
    //       color="#fff"
    //       name="作 成"
    //       border="none"
    //       bdr="4px"
    //       fontSize={16}
    //       lineHeight="15px"
    //       padding="8px 16px 7px"
    //       fontWeight={400}
    //     />
    //   );
    // }
    return (
      <Button
        onClick={() =>
          setCreateVideoVisisble({
            visible: true,
            link,
          })
        }
        background="#2F8CAE"
        color="#fff"
        name="確 認"
        border="none"
        bdr="4px"
        fontSize={16}
        lineHeight="15px"
        padding="8px 16px 7px"
        fontWeight={400}
      />
    );
  };

  const showWarning = (temperature?: number) => {
    if (temperature) {
      return "";
    }
    const Message = () => {
      //   return (
      //     <Trans i18nKey="body-temperature.warning-first">
      //       {{ percent: temperature }}
      //     </Trans>
      //   );

      return <div>20:30に大島大輔さんからの予約が入りました。</div>;
    };
    return (
      <React.Fragment>
        {screens.md ? (
          //   <Alert background={temperature >= 5 ? "#FB2121" : "#EBC300"}>
          <Alert background={"#2F8CAE"}>
            <AttentionIcon style={{ marginRight: 8 }} />
            <Message />
          </Alert>
        ) : (
          <React.Fragment>
            {isVisibleTooltip && (
              <Tooltip4AlertMobile>
                <Message />
              </Tooltip4AlertMobile>
            )}
            <AlertMobile
              background={"#2F8CAE"}
              onClick={() => console.log("clicked")}
            >
              <AttentionIcon style={{ marginRight: 8 }} />
            </AlertMobile>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };

  const returnStatus = (status: number): string => {
    switch (status) {
      case 1:
        return "予約中";
      case 2:
        return "診察終了";
      case 3:
        return "診療前相談終了";
      case 4:
        return "請求済み";
      case 5:
        return "キャンセル";
      case 6:
        return "請求なし";
      default:
        return "";
    }
  };

  const columns = [
    {
      title: "予約日時",
      dataIndex: "date",
      key: "date",
      fixed: window.innerWidth < 650 ? "" : "left",
      width: 94,
      render: (text: string, record: any) => {
        const date = new Date(text);
        if (!text || !record) {
          return <div>-</div>;
        }
        return (
          <div>
            {moment(text).format("MM/DD")}（
            {date.toLocaleString("ja", { weekday: "short" })}）
            {record.start_time}-{record.end_time}
          </div>
        );
      },
    },
    {
      title: "予約番号",
      dataIndex: "id",
      key: "id",
      width: 72,
      fixed: window.innerWidth < 650 ? "" : "left",
      show: true,
      render: (text: string) => {
        if (!text) {
          return <div>-</div>;
        }
        return <div>{text}</div>;
      },
    },
    {
      title: "予約者名（ふりがな） / 年齢 / 性別",
      dataIndex: "patient_name",
      key: "patient_name",
      // width: window.innerWidth < 850 ? 248 : 348,
      width: 236,
      fixed: window.innerWidth < 650 ? "" : "left",
      show: true,
      render: (text: string, record: any) => {
        if (!text || !record) {
          return <div>-</div>;
        }
        return (
          <Link
            onClick={() => {
              setSelectedAppointment(record.id);
              setVisibleImages({
                insurance: false,
                id: false,
                subsidy1: false,
                subsidy2: false,
              });
              setIsVisible4UserInfo(true);
            }}
          >
            {text}
            {record.patient_kana_name &&
              record.patient_kana_name !== " " &&
              `（${record.patient_kana_name}）`}
            様{record.patient_age >= 0 && ` / ${record.patient_age}歳`}
            {record.patient_gender &&
              ` / ${returnGender(record.patient_gender)}`}
          </Link>
        );
      },
    },
    // {
    //   title: "性別",
    //   dataIndex: "patient_gender",
    //   key: "patient_gender",
    //   // width: 64,
    //   width: 64,
    //   render: (text: string) => {
    //     if (!text) {
    //       return <div>-</div>;
    //     }
    //     return <div>{returnGender(text)}</div>;
    //   },
    // },
    {
      title: "主訴",
      dataIndex: "chief_complain",
      key: "chief_complain",
      // width: 200,
      width: 196,
      show: true,
      render: (text: string) => {
        if (!text) {
          return <div>-</div>;
        }
        return <div>{text}</div>;
      },
    },
    {
      title: "担当医師",
      dataIndex: "doctor_name",
      key: "doctor_name",
      // width: 154,
      width: 100,
      render: (text: string) => {
        if (!text) {
          return <div>-</div>;
        }
        return <div>{text}</div>;
      },
    },
    {
      title: "病院名",
      dataIndex: "hospital_name",
      key: "hospital_name",
      // width: 154,
      width: 156,
      render: (text: string) => {
        if (!text) {
          return <div>-</div>;
        }
        return <div>{text}医院</div>;
      },
    },
    {
      title: "所属",
      dataIndex: "super_city_name",
      key: "super_city_name",
      // width: 154,
      width: 150,
      render: (text: string) => {
        if (!text) {
          return <div>-</div>;
        }
        return <div>{text}</div>;
      },
    },
    // {
    //   title: "ビデオ通話リンク",
    //   dataIndex: "meeting_url",
    //   key: "meeting_url",
    //   width: 160,
    //   show: true,
    //   render: (text: string) => (
    //     <div style={{ textAlign: "center" }}>{returnVideoButton(text)}</div>
    //   ),
    // },
    {
      title: "ステータス",
      dataIndex: "status",
      key: "status",
      show: true,
      width: 86,
      render: (text: any) => {
        if (!text) {
          return <div>-</div>;
        }
        return <div>{returnStatus(text)}</div>;
      },
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      width: 54,
      render: (text: any, record: any) => {
        if (!record || (record && record?.status === 5)) {
          return;
        }
        return (
          <div
            style={{
              padding: "4px 4px 2px 4px",
              borderRadius: 4,
              border: "1px solid #FB4E4E",
              fontSize: 14,
              fontWeight: 500,
              lineHeight: "16px",
              color: "#FB4E4E",
              maxWidth: 38,
              margin: "0px auto",
              cursor: "pointer",
            }}
            onClick={() => {
              setCancelAppointment(record);
              setShowCancelModal(true);
            }}
          >
            取消
          </div>
        );
      },
    },
  ];

  const filterCustomEvents = (events: ICustomEvent[]) => {
    const tempEvents = [...events];
    return tempEvents.filter(
      (item: ICustomEvent) => !item.id?.includes("custom-calendar")
    );
  };

  const handleDateSelect = useCallback(
    (selectInfo: DateSelectArg) => {
      disposeTooltip();
      if (displayMode === "month") {
        setSelectedDate(selectInfo.start);
        dispatch(GetDoctorsListActionCreators.getDoctorsListAction({}));
        dispatch(
          GetMonthSchedulesActionCreators.getMonthSchedulesAction({
            start_date: `${moment(selectInfo.start).format(
              "YYYY-MM-DD"
            )}T00:00:00`,
            end_date: `${moment(selectInfo.start).format(
              "YYYY-MM-DD"
            )}T24:00:00`,
          })
        );
        setIsVisible4Schedule(true);
      }

      // const title = prompt("イベントのタイトルを入力してください")?.trim();
      const calendarApi = selectInfo.view.calendar;
      calendarApi.unselect();
      if (displayMode === "week") {
        setEvents([
          ...filterCustomEvents(events),
          {
            start: selectInfo.startStr,
            end: selectInfo.endStr,
            display: "background",
            backgroundColor: "rgb(199, 223, 239)",
            id: uuid2(),
          },
        ]);
      }
      setSelectedEvents([
        {
          start: selectInfo.startStr,
          end: selectInfo.endStr,
          display: "background",
          backgroundColor: "rgb(199, 223, 239)",
          id: uuid2(),
        },
      ]);
    },
    [events]
  );

  useEffect(() => {
    if (
      appointmentDetailsData &&
      appointmentDetailsData.result &&
      !isLoadingAppointmentDetails
    ) {
      setAppointmentDetails(appointmentDetailsData.result);
    } else {
      setAppointmentDetails(undefined);
    }
  }, [appointmentDetailsData, isLoadingAppointmentDetails]);

  useEffect(() => {
    if (doctorList && doctorList.result) {
      let docdorsClone = [];
      let scheduleTemplate: any = {};
      let errorTemplate: any = {};
      docdorsClone = doctorList.result.map((item: any) => {
        return {
          id: item.id,
          name: item.doctor_name,
          value: item.id,
        };
      });
      if (displayMode === "month") {
        doctorList.result.map((item: any) => {
          scheduleTemplate = {
            ...scheduleTemplate,
            [item.id]: {
              startHour: "",
              startMinute: "",
              endHour: "",
              endMinute: "",
            },
          };
          errorTemplate = {
            ...errorTemplate,
            [item.id]: "",
          };
        });
      }
      if (Object(scheduleTemplate) && Object.keys(scheduleTemplate).length) {
        setSchedulesTime(scheduleTemplate);
      }
      if (Object(errorTemplate) && Object.keys(errorTemplate).length) {
        setErrorTemplate(errorTemplate);
      }
      setDoctorOptions(docdorsClone);
    }
  }, [doctorList]);

  useEffect(() => {
    dispatch(GetMonthSchedulesActionCreators.handleResetAction());
    dispatch(GetWeekSchedulesActionCreators.handleResetAction());
    dispatch(GetAppointmentDetailsActionCreators.handleResetAction());
    dispatch(GetDoctorsListActionCreators.getDoctorsListAction({}));
    dispatch(PostDoctorSchedulesActionCreators.handleResetAction());
    setSelectedDoctor(undefined);
    setDoctorSchedules([]);
  }, []);

  const getWeekData = useCallback(() => {
    if (
      displayMode === "week" &&
      selectedDoctor &&
      weekSchedulesParams.start_date
    ) {
      setSelectedEvents([]);
      dispatch(
        GetWeekSchedulesActionCreators.getWeekSchedulesAction({
          doctor_id: selectedDoctor,
          payload: weekSchedulesParams,
        })
      );
      dispatch(
        GetDoctorAppointmentsActionCreators.getDoctorAppointmentsAction({
          doctor_id: selectedDoctor,
          payload: weekSchedulesParams,
        })
      );
    }
  }, [selectedDoctor, weekSchedulesParams, displayMode]);

  const getMonthData = useCallback(() => {
    if (displayMode === "month") {
      // if (!monthSchedulesData) {
      // }
      if (monthSchedulesParams.start_date) {
        dispatch(
          GetMonthSchedulesActionCreators.getMonthSchedulesAction(
            monthSchedulesParams
          )
        );
        // dispatch(
        //   GetAppointmentsListActionCreators.getAppointmentsListAction({
        //     start_date: `${moment(monthSchedulesParams.start_date).format(
        //       "YYYY-MM-DD"
        //     )}T00:00:00`,
        //     end_date: `${moment(monthSchedulesParams.end_date).format(
        //       "YYYY-MM-DD"
        //     )}T24:00:00`,
        //   })
        // );
      }
      if (currentMonth && currentYear) {
        dispatch(
          GetMonthAppointmentsActionCreators.getMonthAppointmentsListAction({
            start_date: `${moment(`${currentYear}-${currentMonth}`)
              .startOf("M")
              .format("YYYY-MM-DD")}T00:00:00`,
            end_date: `${moment(`${currentYear}-${currentMonth}`)
              .endOf("M")
              .format("YYYY-MM-DD")}T24:00:00`,
          })
        );
      }
    }
  }, [monthSchedulesParams, displayMode]);

  useEffect(() => {
    if (selectedAppointment) {
      dispatch(
        GetAppointmentDetailsActionCreators.getAppointmentDetailsAction({
          appointment_id: selectedAppointment,
        })
      );
    }
  }, [selectedAppointment]);

  useEffect(() => {
    getWeekData();
  }, [displayMode, selectedDoctor, weekSchedulesParams]);

  useEffect(() => {
    getMonthData();
  }, [displayMode, monthSchedulesParams]);

  const cancelBulkSchedulesModal = () => {
    setIsVisible4Schedule(false);
    setSelectedEvents([]);
    setSelectedDate(undefined);
    setDoctorSchedules([]);
    setDayEvents([]);
    getMonthData();
  };

  useEffect(() => {
    if (postDoctorSchedulesData && postDoctorSchedulesData.message) {
      notification.success({
        placement: "bottomRight",
        message: postDoctorSchedulesData.message,
        icon: <SendCompleteIcon />,
      });
      dispatch(PostDoctorSchedulesActionCreators.handleResetAction());
      getWeekData();
    }
  }, [postDoctorSchedulesData]);

  useEffect(() => {
    if (
      createBulkDoctorSchedulesData &&
      createBulkDoctorSchedulesData.message
    ) {
      notification.success({
        placement: "bottomRight",
        message: createBulkDoctorSchedulesData.message,
        icon: <SendCompleteIcon />,
      });
      dispatch(CreateBulkSchedulesActionCreators.handleResetAction());
      setDoctorSchedules([]);
      getMonthData();
    }
  }, [createBulkDoctorSchedulesData]);

  useEffect(() => {
    if (deleteScheduleData && deleteScheduleData.message) {
      notification.success({
        placement: "bottomRight",
        message: deleteScheduleData.message,
        icon: <SendCompleteIcon />,
      });
      dispatch(DeleteScheduleActionCreators.handleResetAction());
      if (selectedDate) {
        dispatch(
          GetMonthSchedulesActionCreators.getMonthSchedulesAction({
            start_date: `${moment(selectedDate).format("YYYY-MM-DD")}T00:00:00`,
            end_date: `${moment(selectedDate).format("YYYY-MM-DD")}T24:00:00`,
          })
        );
      }
    }
  }, [deleteScheduleData]);

  useEffect(() => {
    if (cancelAppointmentData && !isCancelingAppointment) {
      notification.success({
        placement: "bottomRight",
        message: cancelAppointmentData.message || "Success",
        icon: <SendCompleteIcon />,
      });
      dispatch(CancelAppointmentActionCreators.handleResetAction());
      getWeekData();
      getMonthData();
    }
  }, [cancelAppointmentData, isCancelingAppointment]);

  useEffect(() => {
    if (PostDoctorSchedulesErrorMessage) {
      notification.error({
        placement: "bottomRight",
        message: PostDoctorSchedulesErrorMessage,
        icon: <ConfirmFailIcon width={53} height={53} />,
      });
      dispatch(PostDoctorSchedulesActionCreators.handleResetAction());
      getWeekData();
    }
  }, [PostDoctorSchedulesErrorMessage]);

  useEffect(() => {
    if (DeleteScheduleErrorMessage) {
      notification.error({
        placement: "bottomRight",
        message: DeleteScheduleErrorMessage,
        icon: <ConfirmFailIcon width={53} height={53} />,
      });
      dispatch(DeleteScheduleActionCreators.handleResetAction());
      if (selectedDate) {
        dispatch(
          GetMonthSchedulesActionCreators.getMonthSchedulesAction({
            start_date: `${moment(selectedDate).format("YYYY-MM-DD")}T00:00:00`,
            end_date: `${moment(selectedDate).format("YYYY-MM-DD")}T24:00:00`,
          })
        );
      }
    }
  }, [DeleteScheduleErrorMessage]);

  useEffect(() => {
    if (CreatingBulkDoctorSchedulesErrorMessage) {
      notification.error({
        placement: "bottomRight",
        message: CreatingBulkDoctorSchedulesErrorMessage,
        icon: <ConfirmFailIcon width={53} height={53} />,
      });
      dispatch(CreateBulkSchedulesActionCreators.handleResetAction());
      setDoctorSchedules([]);
      getMonthData();
    }
  }, [CreatingBulkDoctorSchedulesErrorMessage]);

  useEffect(() => {
    if (DoctorAppointmentsErrorMessage) {
      // notification.error({
      //   placement: "bottomRight",
      //   message: "No doctor appointments this week.",
      //   icon: <ConfirmFailIcon width={53} height={53} />,
      // });
      dispatch(GetDoctorAppointmentsActionCreators.handleResetAction());
    }
  }, [DoctorAppointmentsErrorMessage]);

  useEffect(() => {
    if (monthAppointmentListError) {
      // notification.error({
      //   placement: "bottomRight",
      //   message: "No doctor appointments this month.",
      //   icon: <ConfirmFailIcon width={53} height={53} />,
      // });
      dispatch(GetMonthAppointmentsActionCreators.handleResetAction());
      setAppointmentListByMonth([]);
    }
  }, [monthAppointmentListError]);

  useEffect(() => {
    if (appointmentDetailsErrorMessage) {
      notification.error({
        placement: "bottomRight",
        message: "患者のデータがみつかりません。",
        icon: <ConfirmFailIcon width={53} height={53} />,
      });
      setAppointmentDetails(undefined);
      dispatch(GetAppointmentDetailsActionCreators.handleResetAction());
    }
  }, [appointmentDetailsErrorMessage]);

  useEffect(() => {
    if (cancelAppointmentError) {
      notification.error({
        placement: "bottomRight",
        message: "データが見つかりません。",
        icon: <ConfirmFailIcon width={53} height={53} />,
      });
      dispatch(CancelAppointmentActionCreators.handleResetAction());
    }
  }, [cancelAppointmentError]);

  useEffect(() => {
    if (
      monthSchedulesData &&
      !monthSchedulesData.result.length &&
      !isLoadingMonthSchedules &&
      displayMode === "month"
    ) {
      if (!isVisible4Schedule) {
        setEvents([]);
        setTimeArray(undefined);
        setEventsArray(undefined);
      } else {
        setDayEvents([]);
      }
    }
    if (
      monthSchedulesData &&
      monthSchedulesData.result.length &&
      !isLoadingMonthSchedules &&
      displayMode === "month"
    ) {
      if (!isVisible4Schedule) {
        const eventArray: ICustomEvent[] = [];
        let tempTimeObject: any = {};
        let tempEventsObject: any = {};
        monthSchedulesData.result.map((item: IMonthSchedule) => {
          const dateIndex = item.date.replaceAll("-", "");
          if (!eventArray.length) {
            eventArray.push({
              id: item.id.toString(),
              title: item.doctor_name,
              start: `${item.date}T${item.start_time}`,
              end: `${item.date}T${item.end_time}`,
              date: item.date,
              doctorId: item.doctor_id,
              clickable: false,
              editable: false,
              backgroundColor: handleIsPastDate(item.date)
                ? "#B7B7B7"
                : "#EBF4FA",
              borderColor: handleIsPastDate(item.date) ? "#B7B7B7" : "#2F8CAE",
              className: handleIsPastDate(item.date)
                ? ["past-date"]
                : ["future-date"],
            });

            tempEventsObject = {
              [item.doctor_id]: [item.id],
            };
            tempTimeObject = {
              [item.doctor_id]: {
                [dateIndex]: [`${item.start_time}〜${item.end_time}`],
              },
            };
          } else {
            if (
              eventArray.some(
                (eventItem: ICustomEvent) =>
                  eventItem.doctorId === item.doctor_id &&
                  eventItem.date === item.date
              )
            ) {
              tempEventsObject = {
                ...tempEventsObject,
                [item.doctor_id]: [
                  ...tempEventsObject[item.doctor_id],
                  item.id,
                ],
              };
              tempTimeObject = {
                ...tempTimeObject,
                [item.doctor_id]: {
                  ...tempTimeObject[item.doctor_id],
                  [dateIndex]: [
                    ...tempTimeObject[item.doctor_id][dateIndex],
                    `${item.start_time}〜${item.end_time}`,
                  ],
                },
              };
            } else if (
              eventArray.some(
                (eventItem: ICustomEvent) =>
                  eventItem.doctorId === item.doctor_id &&
                  eventItem.date !== item.date
              )
            ) {
              const tempEventsArr = [...tempEventsObject[item.doctor_id]];
              if (!tempEventsArr.includes(item.id)) {
                tempEventsArr.push(item.id);
                eventArray.push({
                  id: item.id.toString(),
                  title: item.doctor_name,
                  start: `${item.date}T${item.start_time}`,
                  end: `${item.date}T${item.end_time}`,
                  date: item.date,
                  doctorId: item.doctor_id,
                  clickable: false,
                  editable: false,
                  backgroundColor: handleIsPastDate(item.date)
                    ? "#B7B7B7"
                    : "#EBF4FA",
                  borderColor: handleIsPastDate(item.date)
                    ? "#B7B7B7"
                    : "#2F8CAE",
                  className: handleIsPastDate(item.date)
                    ? ["past-date"]
                    : ["future-date"],
                });
              }

              tempEventsObject = {
                ...tempEventsObject,
                [item.doctor_id]: tempEventsArr,
              };
              tempTimeObject = {
                ...tempTimeObject,
                [item.doctor_id]: {
                  ...tempTimeObject[item.doctor_id],
                  [dateIndex]: [`${item.start_time}〜${item.end_time}`],
                },
              };
            }
            // if (
            //   eventArray.some(
            //     (eventItem: ICustomEvent) =>
            //       eventItem.doctorId !== item.doctor_id
            //   )
            // )
            else {
              tempEventsObject = {
                ...tempEventsObject,
                [item.doctor_id]: [item.id],
              };
              tempTimeObject = {
                ...tempTimeObject,
                [item.doctor_id]: {
                  [dateIndex]: [`${item.start_time}〜${item.end_time}`],
                },
              };
              eventArray.push({
                id: item.id.toString(),
                title: item.doctor_name,
                start: `${item.date}T${item.start_time}`,
                end: `${item.date}T${item.end_time}`,
                date: item.date,
                doctorId: item.doctor_id,
                clickable: false,
                editable: false,
                backgroundColor: handleIsPastDate(item.date)
                  ? "#B7B7B7"
                  : "#EBF4FA",
                borderColor: handleIsPastDate(item.date)
                  ? "#B7B7B7"
                  : "#2F8CAE",
                className: handleIsPastDate(item.date)
                  ? ["past-date"]
                  : ["future-date"],
              });
            }
          }
        });
        // setTimeArray({
        //   ...timeArray,
        //   ...tempTimeObject,
        // });
        // setEventsArray({
        //   ...eventsArray,
        //   ...tempEventsObject,
        // });
        // eventArray.filter((event: ICustomEvent) =>
        //   events.includes((evt: ICustomEvent) => evt.id === event.id)
        // );
        // setEvents([...events, ...eventArray]);
        setTimeArray(tempTimeObject);
        setEventsArray(tempEventsObject);
        setEvents(eventArray);
      } else {
        const eventArray: ICustomEvent[] = [];
        const scheduleArray: ICustomDoctorSchedule[] = [];
        monthSchedulesData.result.map((item: IWeekSchedule) => {
          eventArray.push({
            id: item.id.toString(),
            // title: item.doctor_name,
            start: `${item.date}T${item.start_time}`,
            end: `${item.date}T${item.end_time}`,
            date: item.date,
            doctorId: item.doctor_id,
            clickable: false,
            editable: false,
            display: "background",
            backgroundColor: handleIsPastDate(item.date)
              ? "#B7B7B7"
              : "#EBF4FA",
            borderColor: handleIsPastDate(item.date) ? "#B7B7B7" : "#2F8CAE",
            className: handleIsPastDate(item.date)
              ? ["past-date"]
              : ["future-date"],
          });
        });
        monthSchedulesData.result.map((item: IWeekSchedule) => {
          scheduleArray.push({
            eventId: item.id,
            doctor_id: item.doctor_id,
            schedule: {
              schedule_date: item.date,
              start_time: item.start_time,
              end_time: item.end_time,
            },
          });
        });
        // setEvents([...events, ...eventArray]);
        setDayEvents(eventArray);
        setDoctorSchedules(scheduleArray);
      }
    }
  }, [monthSchedulesData, isVisible4Schedule]);

  useEffect(() => {
    if (
      weekSchedulesData &&
      !weekSchedulesData.result.length &&
      !isLoadingWeekSchedules &&
      displayMode === "week"
    ) {
      setEvents([]);
      setTimeArray(undefined);
      setEventsArray(undefined);
    }
    if (
      weekSchedulesData &&
      weekSchedulesData.result.length &&
      !isLoadingWeekSchedules &&
      displayMode === "week"
    ) {
      const eventArray: ICustomEvent[] = [];
      weekSchedulesData.result.map((item: IWeekSchedule) => {
        eventArray.push({
          id: item.id.toString(),
          // title: item.doctor_name,
          start: `${item.date}T${item.start_time}`,
          end: `${item.date}T${item.end_time}`,
          date: item.date,
          doctorId: item.doctor_id,
          clickable: false,
          editable: false,
          display: "background",
          backgroundColor: handleIsPastDate(item.date) ? "#B7B7B7" : "#EBF4FA",
          borderColor: handleIsPastDate(item.date) ? "#B7B7B7" : "#2F8CAE",
          className: handleIsPastDate(item.date)
            ? ["past-date"]
            : ["future-date"],
        });
      });
      // setEvents([...events, ...eventArray]);
      setEvents(eventArray);
    }
  }, [weekSchedulesData]);

  useEffect(() => {
    if (
      doctorAppointmentsData &&
      doctorAppointmentsData.result.length &&
      !isLoadingDoctorAppointments &&
      displayMode === "week"
    ) {
      const eventArray: ICustomEvent[] = [];
      // doctorAppointmentsData.result[0].appointments.map(
      doctorAppointmentsData.result.map((item: IDoctorAppointment) => {
        eventArray.push({
          id: item.id.toString(),
          title: item.patient_name,
          start: `${item.date}T${item.start_time}`,
          end: `${item.date}T${item.end_time}`,
          date: item.date,
          clickable: false,
          editable: false,
          backgroundColor: handleIsPastDate(item.date) ? "#B7B7B7" : "#2F8CAE",
          borderColor: handleIsPastDate(item.date) ? "#B7B7B7" : "#2F8CAE",
          className: handleIsPastDate(item.date)
            ? ["past-date"]
            : ["future-date"],
        });
      });
      setEvents([...events, ...eventArray]);
      // setEvents(eventArray);
    }
  }, [doctorAppointmentsData]);

  useEffect(() => {
    if (
      monthAppointmentsListData &&
      monthAppointmentsListData.result.length &&
      !isLoadingMonthAppointmentList
    ) {
      // const tempList: IMonthAppointmentListItem[] = [];
      // monthAppointmentsListData.result.map((item: IMonthAppointmentListItem) =>
      //   tempList.push({
      //     doctor_name: item.doctor_name,
      //     doctor_id: item.doctor_id,
      //     // appointments: item.appointments.reverse(),
      //     appointments:
      //       item.appointments && item.appointments.length > 0
      //         ? item.appointments
      //         : [],
      //   })
      // );
      // setAppointmentListByMonth(tempList);
      setAppointmentListByMonth(monthAppointmentsListData.result);
    }
  }, [monthAppointmentsListData]);

  const handleEventClick = useCallback((clickInfo: EventClickArg) => {
    // clickInfo.event.remove();
    return;
  }, []);

  const handleDayCell = useCallback((info: any) => {
    const day = moment(info.date).format("D");

    const originElement = info.el.querySelectorAll(".fc-daygrid-day-number");
    originElement.forEach((e: any) => e.classList.add("d-none"));

    const targetElement = info.el.querySelectorAll(".fc-daygrid-day-top");
    targetElement.forEach((e: any) => (e.innerHTML = day));
  }, []);

  const Divider = () => <>&nbsp;/&nbsp;</>;

  const resetError = useCallback(() => {
    if (Object(errorTemplate) && Object.keys(errorTemplate).length) {
      let tempObject = {};
      Object.keys(errorTemplate).map(
        (item: any) =>
          (tempObject = {
            ...tempObject,
            [item]: "",
          })
      );
      setErrorTemplate(tempObject);
    }
  }, [errorTemplate]);

  const handleAddDoctorSchedule = (doctorId: number) => {
    if (
      Object(schedulesTime) &&
      Object.keys(schedulesTime).length &&
      schedulesTime[doctorId] &&
      selectedDate
    ) {
      if (
        !doctorSchedules.some(
          (item: IDoctorSchedule) =>
            item.doctor_id === doctorId &&
            item.schedule.start_time ===
              `${schedulesTime[doctorId].startHour}:${schedulesTime[doctorId].startMinute}` &&
            item.schedule.end_time ===
              `${schedulesTime[doctorId].endHour}:${schedulesTime[doctorId].endMinute}`
        ) &&
        !doctorSchedules.some(
          (item: IDoctorSchedule) =>
            item.doctor_id === doctorId &&
            item.schedule.start_time <=
              `${schedulesTime[doctorId].startHour}:${schedulesTime[doctorId].startMinute}` &&
            item.schedule.end_time >=
              `${schedulesTime[doctorId].endHour}:${schedulesTime[doctorId].endMinute}`
        ) &&
        `${schedulesTime[doctorId].endHour}:${schedulesTime[doctorId].endMinute}` >
          `${schedulesTime[doctorId].startHour}:${schedulesTime[doctorId].startMinute}` &&
        `${schedulesTime[doctorId].endHour}:${schedulesTime[doctorId].endMinute}` <=
          "24:00"
      ) {
        setDoctorSchedules([
          ...doctorSchedules,
          {
            doctor_id: doctorId,
            schedule: {
              schedule_date: moment(selectedDate).format("YYYY-MM-DD"),
              start_time: `${schedulesTime[doctorId].startHour}:${schedulesTime[doctorId].startMinute}`,
              end_time: `${schedulesTime[doctorId].endHour}:${schedulesTime[doctorId].endMinute}`,
            },
          },
        ]);
        resetError();
      } else {
        if (Object(errorTemplate) && Object.keys(errorTemplate).length) {
          setErrorTemplate({
            ...errorTemplate,
            [doctorId]: "error",
          });
        }
      }
      setSchedulesTime({
        ...schedulesTime,
        [doctorId]: {
          startHour: "",
          startMinute: "",
          endHour: "",
          endMinute: "",
        },
      });
    }
  };

  const handleDeleteDoctorSchedule = (schedule: ICustomDoctorSchedule) => {
    if (doctorSchedules.length) {
      const tempDoctorSchedule = [...doctorSchedules];
      const index = tempDoctorSchedule.findIndex(
        (item: ICustomDoctorSchedule) =>
          item.doctor_id === schedule.doctor_id &&
          item.schedule.start_time === schedule.schedule.start_time &&
          item.schedule.end_time === schedule.schedule.end_time
      );
      tempDoctorSchedule.splice(index, 1);
      if (schedule.eventId) {
        dispatch(
          DeleteScheduleActionCreators.deleteScheduleAction({
            schedule_id: schedule.eventId,
          })
        );
      }
      // tempDoctorSchedule.filter((item: IDoctorSchedule) => {
      //   console.log(
      //     item.doctor_id !== schedule.doctor_id &&
      //       item.schedule.start_time !== schedule.schedule.start_time &&
      //       item.schedule.end_time !== schedule.schedule.end_time
      //   );
      //   return (
      //     item.doctor_id !== schedule.doctor_id &&
      //     item.schedule.start_time !== schedule.schedule.start_time &&
      //     item.schedule.end_time !== schedule.schedule.end_time
      //   );
      // });
      setDoctorSchedules(tempDoctorSchedule);
    }
  };

  const cancelDoctorAppointment = () => {
    if (cancelAppointment && cancelAppointment.id) {
      dispatch(
        CancelAppointmentActionCreators.cancelAppointmentAction({
          appointment_id: cancelAppointment.id,
        })
      );
    }
    setShowCancelModal(false);
    setCancelAppointment(undefined);
  };

  return (
    <WrapperSchedule>
      {/* {showWarning()} */}
      <Box title="スケジュール管理" padding={screens.md ? "16px" : "8px"}>
        {displayMode === "week" && (
          <WrapperDoctor>
            <Select
              width="198px"
              options={doctorOptions}
              placeholder="Select doctor"
              onChange={handleOnChangeDoctor}
            />
            <div>予約管理</div>
          </WrapperDoctor>
        )}
        <WrapperFullCalendar displayMode={displayMode}>
          {/* {displayMode === "week" && (
            <Button
              className="week-confirm"
              onClick={handlePostDoctorSchedules}
              background="#2F8CAE"
              color="#ffffff"
              name="保存"
              border="none"
              bdr="4px"
              fontSize={18}
              lineHeight="15px"
              fontWeight={400}
              padding="8px 8px 7px"
              disabled={
                !selectedEvents.length ||
                !selectedDoctor ||
                isPostingDoctorSchedules
              }
            />
          )} */}
          <FullCalendar
            events={events}
            datesSet={handleDateRangeChange}
            ref={calendarRef}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            // plugins={[dayGridPlugin, interactionPlugin]}
            slotEventOverlap={false}
            selectAllow={function (selectionInfo) {
              // Don't allow creation of events over more than 1 day or past day
              if (
                moment(selectionInfo.start).isSame(
                  moment(selectionInfo.end).subtract(1, "second"),
                  "day"
                ) &&
                moment(selectionInfo.start).diff(
                  moment(new Date()).subtract(1, "day").endOf("D"),
                  "milliseconds"
                ) > 0 &&
                // moment(selectionInfo.start).diff(
                //   moment(selectionInfo.end),
                //   "minutes"
                // ) <= 15 &&
                // selectedEvents.length < 1 &&
                (displayMode === "month" ||
                  (displayMode === "week" && selectedDoctor))
              ) {
                return true;
              } else {
                return false;
              }
            }}
            customButtons={{
              today: {
                text: displayMode === "month" ? "今月" : "今週",
                click: function () {
                  returnToday();
                },
              },
              dayGridMonth: {
                text: "月",
                click: function (e: any) {
                  handleChangeViewMonth(e);
                },
              },
              timeGridWeek: {
                text: "週",
                click: function (e: any) {
                  handleChangeViewWeek(e);
                },
              },
              titleCustom: {
                text: "保存",
                click: () => {
                  handlePostDoctorSchedules();
                },
              },
            }}
            // eventMouseEnter={(arg) => {
            //   <Tooltip overlay>
            //     <div>{arg.event.title}</div>
            //   </Tooltip>;
            //   // alert(arg.event.title);
            // }}
            eventMouseEnter={handleMouseEnter}
            eventMouseLeave={handleMouseLeave}
            eventOverlap={true}
            headerToolbar={{
              left: "prev,next today",
              center: "title,titleCustom",
              right: "dayGridMonth,timeGridWeek",
              // right: "",
            }}
            initialView="dayGridMonth"
            selectable
            editable
            locales={allLocales}
            locale="ja"
            eventsSet={handleEvents}
            select={handleDateSelect}
            eventClick={handleEventClick}
            dayCellDidMount={handleDayCell}
            slotDuration={"00:15:00"}
            slotLabelInterval={"00:30"}
            slotLabelFormat={{
              hour: "numeric",
              minute: "2-digit",
              omitZeroMinute: false,
            }}
            scrollTime="00:00"
            eventContent={(arg) => {
              if (displayMode === "month") {
                if (!arg.event._def.title) {
                  return;
                }
                return (
                  <ScheduleTooltipWrapper className="customized-event">
                    {/* {arg.event.id} */}
                    {arg.event._def.title}医師
                    {/* {moment(arg.event.startStr).format("HH:mm")}〜
                    {moment(arg.event.endStr).format("HH:mm")} */}
                    {!arg.event._def.title &&
                      findDateArray(
                        findDoctorId(arg.event.id),
                        moment(arg.event.startStr)
                          .format("YYYY-MM-DD")
                          .replaceAll("-", "")
                      ) && (
                        <div className="tool-tip">
                          {findDateArray(
                            findDoctorId(arg.event.id),
                            moment(arg.event.startStr)
                              .format("YYYY-MM-DD")
                              .replaceAll("-", "")
                          ).map((item: any, index: number) => (
                            <div key={index}>{item}</div>
                          ))}
                        </div>
                      )}
                  </ScheduleTooltipWrapper>
                );
              }
              return (
                arg.event._def.title && <div>{arg.event._def.title}様</div>
              );
            }}
            titleFormat={{
              month: "long",
              year: "numeric",
              day: displayMode === "month" ? undefined : "numeric",
              // weekday: "long",
            }}
            titleRangeSeparator="〜"
            allDayText="全日"
            firstDay={0}
            // validRange={{
            //   start:
            //     displayMode === "month"
            //       ? moment(new Date()).startOf("month").toDate()
            //       : moment(new Date()).startOf("week").toDate(),
            // }}
          />
        </WrapperFullCalendar>
        <Modal
          title="患者情報"
          visible={isVisible4UserInfo}
          footer={<></>}
          onCancel={() => {
            setIsVisible4UserInfo(false);
            setSelectedAppointment(undefined);
          }}
        >
          <WrapperInfo style={{ lineHeight: "22px" }}>
            保険者番号：
            <span>
              {appointmentDetails?.insurance_number &&
                appointmentDetails.insurance_number}
              <Divider />
            </span>
            氏名：
            <span>
              {appointmentDetails?.patient_name &&
                appointmentDetails.patient_name}
            </span>
            <span>
              {appointmentDetails?.patient_kana_name &&
                appointmentDetails.patient_kana_name !== " " &&
                `（${appointmentDetails.patient_kana_name}）`}
              様
              <Divider />
            </span>
            年齢：
            <span>
              {appointmentDetails?.patient_age &&
                appointmentDetails.patient_age}
              歳<Divider />
            </span>
            性別：
            <span>
              {appointmentDetails?.patient_gender &&
                returnGender(appointmentDetails.patient_gender)}
              <Divider />
            </span>
            生年月日：
            <span>
              {appointmentDetails?.date_of_birth &&
                moment(appointmentDetails.date_of_birth).format(
                  "YYYY年MM月DD日"
                )}
            </span>
          </WrapperInfo>
          <div style={{ display: "flex", gap: 8 }}>
            <WrapperInfo style={{ flex: 2 }}>
              <div>
                郵便番号：
                {appointmentDetails?.patient_postal_code &&
                  returnPostalCode(appointmentDetails.patient_postal_code)}
              </div>
              <div>
                住所：
                {appointmentDetails?.patient_address &&
                  appointmentDetails.patient_address}
              </div>
              <div>
                電話番号：
                {appointmentDetails?.patient_mobile_number &&
                  returnPhone(appointmentDetails.patient_mobile_number)}
              </div>
              <div>
                メールアドレス：
                {appointmentDetails?.patient_email && (
                  <Link style={{ fontWeight: 400 }}>
                    {appointmentDetails.patient_email}
                  </Link>
                )}
              </div>
            </WrapperInfo>
            <GroupCards>
              <WrapperCard>
                <Card>
                  <div>保険証登録</div>
                  {appointmentDetails?.insurance_image_url ? (
                    <Image
                      visible={visibleImages.insurance}
                      src={appointmentDetails.insurance_image_url}
                      onLoad={() =>
                        setVisibleImages({
                          ...visibleImages,
                          insurance: true,
                        })
                      }
                      onError={() =>
                        setAppointmentDetails({
                          ...appointmentDetails,
                          insurance_image_url: "",
                        })
                      }
                    />
                  ) : (
                    <div></div>
                  )}
                </Card>
                <Card>
                  <div>身分証登録</div>
                  {appointmentDetails?.id_card_image_url ? (
                    <Image
                      visible={visibleImages.id}
                      src={appointmentDetails.id_card_image_url}
                      onLoad={() =>
                        setVisibleImages({
                          ...visibleImages,
                          id: true,
                        })
                      }
                      onError={() =>
                        setAppointmentDetails({
                          ...appointmentDetails,
                          id_card_image_url: "",
                        })
                      }
                    />
                  ) : (
                    <div></div>
                  )}
                </Card>
              </WrapperCard>
              <div
                style={{
                  fontSize: 12,
                  lineHeight: "17px",
                  marginTop: 8,
                }}
              >
                公的助成受給書等
              </div>
              <WrapperCard>
                <Card>
                  <div></div>
                  {appointmentDetails?.subsidy_receipt1_image_url ? (
                    <Image
                      visible={visibleImages.subsidy1}
                      src={appointmentDetails.subsidy_receipt1_image_url}
                      onLoad={() =>
                        setVisibleImages({
                          ...visibleImages,
                          subsidy1: true,
                        })
                      }
                      onError={() =>
                        setAppointmentDetails({
                          ...appointmentDetails,
                          subsidy_receipt1_image_url: "",
                        })
                      }
                    />
                  ) : (
                    <div></div>
                  )}
                </Card>
                <Card>
                  <div></div>
                  {appointmentDetails?.subsidy_receipt2_image_url ? (
                    <Image
                      visible={visibleImages.subsidy2}
                      src={appointmentDetails.subsidy_receipt2_image_url}
                      onLoad={() =>
                        setVisibleImages({
                          ...visibleImages,
                          subsidy2: true,
                        })
                      }
                      onError={() =>
                        setAppointmentDetails({
                          ...appointmentDetails,
                          subsidy_receipt2_image_url: "",
                        })
                      }
                    />
                  ) : (
                    <div></div>
                  )}
                </Card>
              </WrapperCard>
            </GroupCards>
          </div>
          <WrapperInfo>
            <Bold>病歴</Bold>
            {appointmentDetails?.illness_history &&
              appointmentDetails.illness_history.map(
                (item: any, index: number) => <div key={index}>{item}</div>
              )}
            {/* <div>
              病名：かぜ / 罹患した時期：2020年12月 / ステータス：治っていない
            </div>
            <div>
              病名：カウザルギー / 罹患した時期：2020年12月 / ステータス：治療中
            </div> */}
          </WrapperInfo>
          <WrapperInfo>
            <Bold>病院から処方されている薬</Bold>
            {appointmentDetails?.prescribed_medication &&
              appointmentDetails.prescribed_medication.map(
                (item: any, index: number) => <div key={index}>・{item}</div>
              )}
            {/* <div>・トウキ</div>
            <div>・トコン</div> */}
          </WrapperInfo>
          <WrapperInfo>
            <Bold>アレルギー</Bold>
            {appointmentDetails?.allergy_info &&
              appointmentDetails.allergy_info.map(
                (item: any, index: number) => <div key={index}>・{item}</div>
              )}
            {/* <div>・オオブタクサ</div>
            <div>・豚肉</div> */}
          </WrapperInfo>
          <WrapperInfo>
            <Bold>身体情報</Bold>
            <div>
              身長（cm）：
              {appointmentDetails?.patient_height &&
              appointmentDetails.patient_height > 0
                ? appointmentDetails.patient_height
                : ""}
              <Divider />
              体重（kg）：
              {appointmentDetails?.patient_weight &&
              appointmentDetails.patient_weight > 0
                ? appointmentDetails.patient_weight
                : ""}
            </div>
          </WrapperInfo>
          <WrapperInfo>
            <Bold>飲酒歴</Bold>
            {appointmentDetails?.drink_history &&
              appointmentDetails.drink_history.map(
                (item: any, index: number) => <div key={index}>{item}</div>
              )}
            {/* <div>頻度：週に1回程度 / 種類：ビール / 量：750ml</div>
            <div>頻度：月に1回程度 / 種類：その他 / 量：125ml（1杯）</div> */}
          </WrapperInfo>
          <WrapperInfo>
            <Bold>喫煙歴</Bold>
            <div>
              {appointmentDetails?.smoking_history &&
                appointmentDetails.smoking_history}
            </div>
            {/* <div>
              頻度：毎日 / 喫煙歴（年数）：20年以上 /
              1日に吸う本数：20本から30本
            </div> */}
          </WrapperInfo>
        </Modal>
        <Modal
          title={
            selectedDate
              ? `${moment(selectedDate).format("YYYY年MM月DD日")}（
            ${selectedDate.toLocaleString("ja", { weekday: "short" })}）`
              : ""
          }
          visible={isVisible4Schedule}
          footer={<></>}
          onCancel={cancelBulkSchedulesModal}
        >
          <WrapperModal>
            {doctorList?.result &&
              doctorList.result.length &&
              Object(schedulesTime) &&
              Object.keys(schedulesTime).length &&
              doctorList.result.map((doctor: IDoctor) => (
                <ScheduleInfo key={doctor.id}>
                  <ScheduleInfoTop>
                    <DoctorName>{doctor.doctor_name}</DoctorName>
                    {schedulesTime[doctor.id] && (
                      <Time>
                        <div>開始時間</div>
                        <Select
                          value={
                            schedulesTime[doctor.id].startHour || undefined
                          }
                          placeholder={"00"}
                          options={hourOptions}
                          onChange={(e: any) => {
                            setSchedulesTime({
                              ...schedulesTime,
                              [doctor.id]: {
                                ...schedulesTime[doctor.id],
                                startHour: e,
                                startMinute: "00",
                              },
                            });
                            resetError();
                          }}
                        />
                        <div>時</div>
                        <Select
                          value={
                            schedulesTime[doctor.id].startMinute || undefined
                          }
                          placeholder={"00"}
                          options={minutesOptions}
                          onChange={(e: any) => {
                            setSchedulesTime({
                              ...schedulesTime,
                              [doctor.id]: {
                                ...schedulesTime[doctor.id],
                                startMinute: e,
                              },
                            });
                            resetError();
                          }}
                        />
                        <div>分</div>
                        <div style={{ marginLeft: 16 }}>終了時間</div>
                        <Select
                          value={schedulesTime[doctor.id].endHour || undefined}
                          placeholder={"00"}
                          options={hourOptions}
                          onChange={(e: any) => {
                            setSchedulesTime({
                              ...schedulesTime,
                              [doctor.id]: {
                                ...schedulesTime[doctor.id],
                                endHour: e,
                                endMinute: "00",
                              },
                            });
                            resetError();
                          }}
                        />
                        <div>時</div>
                        <Select
                          value={
                            schedulesTime[doctor.id].endMinute || undefined
                          }
                          placeholder={"00"}
                          options={minutesOptions}
                          onChange={(e: any) => {
                            setSchedulesTime({
                              ...schedulesTime,
                              [doctor.id]: {
                                ...schedulesTime[doctor.id],
                                endMinute: e,
                              },
                            });
                            resetError();
                          }}
                        />
                        <div>分</div>
                        <Button
                          onClick={() => handleAddDoctorSchedule(doctor.id)}
                          background="#2AC769"
                          color="#fff"
                          name="追加"
                          border="none"
                          bdr="6px"
                          fontSize={16}
                          fontWeight={700}
                          disabled={
                            !schedulesTime[doctor.id].startHour ||
                            !schedulesTime[doctor.id].startMinute ||
                            !schedulesTime[doctor.id].endHour ||
                            !schedulesTime[doctor.id].endMinute
                          }
                        />
                      </Time>
                    )}
                    {Object(errorTemplate) &&
                      Object.keys(errorTemplate).length &&
                      errorTemplate[doctor.id] && (
                        <ErrorMessage>{errorTemplate[doctor.id]}</ErrorMessage>
                      )}
                  </ScheduleInfoTop>
                  <Line style={{ width: "100%" }} />
                  <ScheduleInfoBottom style={{ padding: 16 }}>
                    {doctorSchedules.length ? (
                      doctorSchedules
                        .filter((item: IDoctorSchedule, indexItem: number) => {
                          return item.doctor_id === doctor.id;
                        })
                        .map((schedule: IDoctorSchedule, index: number) => (
                          <div className="item" key={index}>
                            <span>
                              {schedule.schedule.start_time}〜
                              {schedule.schedule.end_time}
                            </span>
                            <DeleteCircle
                              onClick={() =>
                                handleDeleteDoctorSchedule(schedule)
                              }
                            />
                          </div>
                        ))
                    ) : (
                      <></>
                    )}
                  </ScheduleInfoBottom>
                </ScheduleInfo>
              ))}
          </WrapperModal>
          <WrapperBtnFooter>
            <Button
              onClick={cancelBulkSchedulesModal}
              background="#E0E0E0"
              color="#fff"
              name="キャンセル"
              border="none"
              bdr="6px"
              fontSize={16}
              fontWeight={700}
            />

            <Button
              onClick={handleCreateBulkDoctorSchedules}
              background="#2F8CAE"
              color="#fff"
              name="登録"
              border="none"
              bdr="6px"
              fontSize={16}
              fontWeight={700}
              disabled={!doctorSchedules.length}
            />
          </WrapperBtnFooter>
        </Modal>
      </Box>
      {doctorAppointmentsData?.result?.length && displayMode === "week" && (
        <Box padding="14px 16px 16px" style={{ marginTop: 24 }}>
          <ScheduleTitle>カレンダー上の予約一覧</ScheduleTitle>
          {/* {doctorAppointmentsData.result[0].doctor_name && (
            <ScheduleTitle>
              {doctorAppointmentsData.result[0].doctor_name}先生の予約一覧
            </ScheduleTitle>
          )} */}
          <ScheduleTableStyledAdmin
            // dataSource={doctorAppointmentsData.result[0].appointments || []}
            dataSource={doctorAppointmentsData.result || []}
            columns={columns}
            scroll={{ x: "max-content" }}
            loading={isLoadingDoctorAppointments}
          />
        </Box>
      )}
      {appointmentListByMonth &&
        appointmentListByMonth.length > 0 &&
        displayMode === "month" && (
          // appointmentListByMonth.map(
          //   (appointmentItem: IMonthAppointmentListItem, index: number) => (
          //     <Box
          //       key={index}
          //       padding="18px 12px 18px 16px"
          //       style={{ marginTop: 24 }}
          //     >
          //       <ScheduleTitle>
          //         {appointmentItem.doctor_name}医師の予約一覧
          //       </ScheduleTitle>
          //       <ScheduleTableStyledAdmin
          //         dataSource={appointmentItem.appointments}
          //         columns={columns}
          //         scroll={{ x: "max-content" }}
          //         loading={isLoadingMonthAppointmentList}
          //       />
          //     </Box>
          //   )
          // )
          <Box padding="14px 16px 16px" style={{ marginTop: 24 }}>
            <ScheduleTitle>カレンダー上の予約一覧</ScheduleTitle>
            <ScheduleTableStyledAdmin
              dataSource={appointmentListByMonth}
              columns={columns}
              scroll={{ x: "max-content" }}
              loading={isLoadingMonthAppointmentList}
            />
          </Box>
        )}
      <ModalStyledAdmin
        title={"送信済みのビデオ通話リンクアドレス"}
        visible={createVideoVisible.visible}
        onCancel={toggleVisibleVideoCreate}
      >
        {/* <VideoModalContentWrapper>
          <div className="video-input">
            <Button
              onClick={() => console.log("clicked")}
              background="#2AC769"
              color="#fff"
              name="ビデオ通話リンク作成"
              border="none"
              bdr="6px"
              fontSize={16}
              lineHeight="16px"
              padding="12px 16px"
              fontWeight={700}
            />
            <Input
              fs={16}
              value="https://meet.google.com/jja-ticu-fkn"
              padding="6px 16px 4px"
            />
          </div>
          <Button
            onClick={() => console.log("clicked")}
            background="#2AC769"
            color="#fff"
            name={
              createVideoVisible.sent
                ? "作成したリンクをユーザーに送信済み"
                : "作成したリンクをユーザーに送信"
            }
            border="none"
            bdr="6px"
            fontSize={16}
            lineHeight="16px"
            padding="13px 16px 11px"
            fontWeight={700}
            width="100%"
            disabled={sent}
          />
          {createVideoVisible.sent && (
            <div className="sent-block">
              <div className="sent-label">
                送信済みのビデオ通話リンクアドレス
              </div>
              <a
                href="https://meet.google.com/jja-ticu-fkn"
                target="_blank"
                rel="noreferrer"
              >
                https://meet.google.com/jja-ticu-fkn
              </a>
            </div>
          )}
        </VideoModalContentWrapper> */}
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <StyledLink
            href={createVideoVisible.link}
            target="_blank"
            rel="noreferrer"
          >
            {createVideoVisible.link}
          </StyledLink>
        </div>
      </ModalStyledAdmin>
      {cancelAppointment && (
        <CancelModalStyled
          visible={showCancelModal}
          type="confirm"
          footer={null}
          onCancel={() => {
            setShowCancelModal(false);
            setCancelAppointment(undefined);
          }}
        >
          <WrapperContentModal>
            <div>
              {cancelAppointment.patient_name}の{cancelAppointment.start_time}〜
              {cancelAppointment.end_time}の予約を取消します。
            </div>
            <div>よろしいですか？</div>
            <CancelModalWrapperFooter>
              <Button
                onClick={() => {
                  setShowCancelModal(false);
                  setCancelAppointment(undefined);
                }}
                background="#E0E0E0"
                color="#fff"
                name="キャンセル"
                border="none"
                bdr="6px"
                fontSize={16}
                fontWeight={700}
              />

              <Button
                onClick={cancelDoctorAppointment}
                background="#FB4E4E"
                color="#fff"
                name="はい"
                border="none"
                bdr="6px"
                fontSize={16}
                fontWeight={700}
              />
            </CancelModalWrapperFooter>
          </WrapperContentModal>
        </CancelModalStyled>
      )}
    </WrapperSchedule>
  );
};

export default ScheduleManagementAdmin;
