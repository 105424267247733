import {
  ActionType,
  CancelAppointmentPayload,
  CancelAppointmentResponse,
} from "models/appointments/cancelAppointment";

export const cancelAppointmentAction = (payload: CancelAppointmentPayload) => {
  return {
    type: ActionType.CANCEL_APPOINTMENT,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.CANCEL_APPOINTMENT_ERROR,
    payload,
  };
};

export const handleSuccessAction = (payload: CancelAppointmentResponse) => {
  return {
    type: ActionType.CANCEL_APPOINTMENT_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.CANCEL_APPOINTMENT_RESET,
  };
};
