import { put, takeLatest, fork, call } from "redux-saga/effects";

import {
  ActionType,
  AgreeHospitalsPayload,
  AgreeHospitalsListResponse,
} from "models/hospitals/agreeHospitalsList";
import { httpStatus } from "configs/httpStatus";
import { getAgreeHospitals } from "services/hospitals";

import {
  handleSuccessAction,
  handleErrorAction,
} from "./agreeHospitalsActions";

function* getAgreeHospitalsSaga({
  payload,
}: {
  payload: AgreeHospitalsPayload;
}) {
  try {
    const response: AgreeHospitalsListResponse = yield call(
      getAgreeHospitals,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccessAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetAgreeHospitalsWatcher() {
  yield takeLatest(
    ActionType.AGREE_HOSPITALS_LIST as any,
    getAgreeHospitalsSaga
  );
}

export default [fork(onGetAgreeHospitalsWatcher)];
