import { IResponse } from "models";

export enum ActionType {
  DOCTORS_LIST = "action/DOCTORS_LIST",
  DOCTORS_SUCCESS = "action/DOCTORS_SUCCESS",
  DOCTORS_ERROR = "action/DOCTORS_ERROR",
  DOCTORS_RESET = "action/DOCTORS_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IDoctorsListResponse extends IResponse {
  //   result: {
  //     total: number;
  //     doctors: IDoctor[];
  //   };
  result: IDoctor[];
}

export interface ILinkHospital {
  hospital_id: number;
  hospital_name: string;
  name: string;
}

export interface IDoctor {
  id: number;
  email: string;
  doctor_name: string;
  kana_doctor_name: string;
  link_hospitals: ILinkHospital[];
}
