import { put, takeLatest, fork, call } from "redux-saga/effects";

import {
  ActionType,
  CancelAppointmentPayload,
  CancelAppointmentResponse,
} from "models/appointments/cancelAppointment";
import { httpStatus } from "configs/httpStatus";
import { cancelAppointment } from "services/appointments";

import {
  handleSuccessAction,
  handleErrorAction,
} from "./cancelAppointmentActions";

function* cancelAppointmentSaga({
  payload,
}: {
  payload: CancelAppointmentPayload;
}) {
  try {
    const response: CancelAppointmentResponse = yield call(
      cancelAppointment,
      payload
    );
    if (response.status === httpStatus.StatusNoContent) {
      yield put(handleSuccessAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onCancelAppointmentSagaWatcher() {
  yield takeLatest(ActionType.CANCEL_APPOINTMENT as any, cancelAppointmentSaga);
}

export default [fork(onCancelAppointmentSagaWatcher)];
